.clear{float:none;clear:both;width:0;height:0;}
.clear-float::after {display: block;content: "";clear: both;}
header ul, footer ul{margin:0;padding:0;}
	header ul li, footer ul li{list-style:none;margin:0;padding:0;}
	.page-instit-img ul li{list-style: inherit;}
.swiper-container-vertical > .swiper-wrapper {    -webkit-box-orient: vertical;
    -moz-box-orient: vertical;
    -ms-flex-direction: column;
    -webkit-flex-direction: column;
    flex-direction: column;}
.swiper-container-vertical > .swiper-wrapper .swiper-slide{height: auto!important;}
.art-descrp-tab a {
    text-decoration: underline;
    color: #0782C1;
} 
.badge{line-height: normal;}
a{border:none;outline:none;text-decoration:none;color:inherit;}
a:focus, a:hover{text-decoration:none!important;outline:none;color:#2375D8;box-shadow: none!important;-webkit-box-shadow: none!important;-ms-box-shadow: none!important;-o-box-shadow: none!important;-spec-box-shadow: none!important;}
button:focus, select:focus, input[type="text"]:focus,input:focus {outline:none;box-shadow: none!important;-webkit-box-shadow: none!important;-ms-box-shadow: none!important;-o-box-shadow: none!important;-spec-box-shadow: none!important;}
img{border:none;max-width:100%;}
.swiper-container {overflow: hidden;}
body{font-family:"Calibri", Arial;color:#050F3B;font-size:0.875rem;line-height: 1.2;overflow-x: hidden;font-weight: 400;}
	.big-wrapper{background:#ffffff;    overflow-x: hidden;max-width: 4000px;margin-left: auto;margin-right: auto;}
strong, .font-weight-bold{font-family: Calibri-Bold;font-weight: normal;} 
select{-webkit-appearance:none; -moz-appearance:none;appearance:none;-ms-appearance:none;-o-appearance:none;cursor:pointer}
.input-control{border: 1px solid #6D8AB5;height: 40px;width: 100%;padding: 0 15px;}
.form-group .daterangepicker{top: 100%!important;}
.font-sofia{font-family: Sofia-pro;font-weight: 400;}
/**remove select apparence IE****/
select::-ms-expand {display: none;}
.cursor-pointer {cursor: pointer;}
.opacity-hover:hover{
	filter:brightness(110%);
	-moz-filter:brightness(110%);
	-webkit-filter:brightness(110%);
	-ms-filter:brightness(110%);
	-o-filter:brightness(110%);
	}
.color-white{color:#ffffff!important}
.color-orange{color: #F7931E!important}
.color-orange2{color: #FF9739!important}
.color-blue-s{color: #2375D8!important}
.color-blue2-s{color: #115BC9!important}
.color-green-s,.color-success-s{color: #39B54A!important}
.color-green2-s{color: #96C136!important}
.color-primary-site{color: #6D9B71!important}
.color-black2{color: #1F1B1C!important}
.color-red{color: #D3163A!important}
.bg-white{background:#ffffff!important}
.bg-blue-card{background:#115BC9!important}
.bg-primary-site{background:#115BC9!important}
button.bg-blue-card:hover,button.bg-blue-card:focus {background: #6FAED8!important;}
.bg-orange{background:#FF9739!important}
.bg-new{background:#FF9739!important}
.bg-green-btn{background:#6D9B71!important}
.bg-green-opac {background: rgba(109,155,113,0.15);}
.dropdown-perso .dropdown-item.active,.dropdown-perso  .dropdown-item:active{background:#115BC9!important}
.bg-orange-label{background-color: #F48211!important;} 
.empty-hide:empty{display: none;}
.menu-mob-overlay{background:rgba(28,28,27,0.6);height:100%;left:0;position:fixed;top:0;width:100%;z-index:99; display:none}
.menu-overlay{background:rgba(51,51,51,0.6);height:100%;left:0;position:fixed;top:0;width:100%;z-index:99;}
.line-through{text-decoration:line-through}
	.visible-980{display:none}
.clear-float::after{content:""; display:block; clear:both}
.pi-uppercase{text-transform:uppercase}
.font-11{font-size:0.8rem!important}
.font-12{font-size:0.813rem!important}
.font-14{font-size: 0.875rem!important}
.font-15{font-size: 0.938rem!important}
.font-16{font-size: 1rem!important}
.font-18{font-size: 1.125rem!important} 
.font-19{font-size:1.188rem!important}
.font-17{font-size: 1.063rem!important}
.font-20{font-size: 1.25rem!important}
.font-22{font-size: 1.375rem!important}
.font-24{font-size: 1.5rem!important}
.font-25{font-size: 1.525rem!important}
.font-26{font-size: 1.625rem!important}
.font-30{font-size: 1.875rem!important}
.swiper-button-disabled {cursor: not-allowed!important; opacity: 1;pointer-events: none;display: block}
.btn2-slide-prev.swiper-button-disabled , .btn2-slide-next.swiper-button-disabled {opacity: 0;}
.font-regular{font-weight: 400!important;}
.font-medium{font-weight: 500!important;}
.font-aller-bold{font-family: Aller-Bold;}
.text-grey {color: #666666!important;}

.transition-bgcolor{ -webkit-transition: background-color 0.5s ease-out;
	-moz-transition: background-color 0.5s ease-out;
	-o-transition: background-color 0.5s ease-out;
	transition: background-color 0.5s ease-out; -spec-transition: background-color 0.5s ease-out;}
   /* Firefox */
.contenu-panier-qty input[type=number], input[type=number].form-control {
    -moz-appearance: textfield;appearance: textfield;
}

/* Chrome */
.contenu-panier-qty input::-webkit-inner-spin-button,
.contenu-panier-qty input::-webkit-outer-spin-button, input[type=number].form-control::-webkit-inner-spin-button,
input[type=number].form-control::-webkit-outer-spin-button  {
	-webkit-appearance: none;appearance: none;
	margin:0;
}

/* Opï¿½ra*/
.contenu-panier-qty input::-o-inner-spin-button,
.contenu-panier-qty input::-o-outer-spin-button ,input[type=number].form-control::-o-inner-spin-button,
input[type=number].form-control::-o-outer-spin-button {
	-o-appearance: none;appearance: none;
	margin:0
}

/********inout design********/
		.input-abs {position: absolute;z-index: 1;width: 100%;left: 0;top: 0;height: 100%;cursor: pointer;opacity: 0;}
		.input-rel {position:relative; z-index:1; margin:0 5px 0.625rem 0}
@media (min-width: 1320px){
.container, .container-lg, .container-md, .container-sm, .container-xl {max-width: 1500px;}
}
.stat, .stat img, .opti, .opti img {position: absolute;left: 0;height: 0;top: -90px;}
.just-one .swiper-pagination span, .just-one .swiper-pagination{display:none!important}
.just-one  .swiper-pagination, .just-one-page  .swiper-pagination{opacity: 0;}
 .just-one .swiper-wrapper {transform: none!important;-webkit-transform: none!important;-ms-transform: none!important;-o-transform: none!important;-spec-transform: none!important;}
 .just-one .swiper-button-disabled {display: none;}
.justify-center .swiper-wrapper {-webkit-box-pack: center!important;-ms-flex-pack: center!important;justify-content: center!important;}
input::-webkit-input-placeholder { /* Chrome/Opera/Safari */color: #050F3B;opacity:1;}
input::-moz-placeholder { /* Firefox 19+ */color: #050F3B;opacity:1;}
input:-ms-input-placeholder { /* IE 10+ */color:  #050F3B;opacity:1;}
input:-moz-placeholder { /* Firefox 18- */color:  #050F3B;opacity:1;}
.font-italic-pro{font-family: "Calibri";font-style: italic;font-weight: 300;}
.font-pro-bold{font-family: "Calibri-Bold";font-weight: normal;}
.font-Aller-bold{font-family: "Aller-Bold";}

.btn2-slide-prev {position:absolute; top:50%;width:40px; height:40px; font-size:0; z-index:9; border:none;background: #050F3B url('../images/prev2_arrow.png') no-repeat center top;left:-20px; cursor:pointer ; transform:translate(0, -50%);-webkit-transform:translate(0, -50%);-ms-transform:translate(0, -50%);-o-transform:translate(0, -50%);-spec-transform: translate(0, -50%);border-radius: 100%;-webkit-border-radius: 100%;-ms-border-radius: 100%;-o-border-radius: 100%;z-index: 9;}
.btn2-slide-next {position:absolute; top:50%; width:40px;  height:40px; font-size:0; z-index:9; border:none;background:#050F3B url('../images/next2_arrow.png') no-repeat center top;right:-20px; cursor:pointer;transform:translate(0, -50%); -webkit-transform:translate(0, -50%);-ms-transform:translate(0, -50%);-o-transform:translate(0, -50%);-spec-transform: translate(0, -50%);border-radius: 100%;-webkit-border-radius: 100%;-ms-border-radius: 100%;-o-border-radius: 100%;z-index: 9;}
.btn2-slide-prev:hover,.btn2-slide-next:hover {background-color:#115BC9}
.top-index-eco .btn2-slide-prev:hover,.top-index-eco .btn2-slide-next:hover {background-color:#6D9B71}
 .bg-color-blue-slide{background-color: #115BC9;}
 .bg-color-blue-slide:hover{background-color: #36A3E0;}

.btn-slide-prev {width:21px; height:24px;; font-size:0; z-index:9; border:none;background: url('../images/prev_btnmob2.svg') no-repeat center top;left:-20px; cursor:pointer ; }

.btn-slide-next { width:21px;  height:24px;; font-size:0; z-index:9; border:none;background:url('../images/next_btnmob2.svg') no-repeat center top;right:-20px; cursor:pointer;}
.btn-slide-prev:hover,.btn-slide-next:hover {background-position:center bottom}

hr { border-top: 4px solid rgba(0, 0, 0, 0.1); }
.py-btn{padding-top: 12px!important;padding-bottom: 12px!important;}
.max-width-100{max-width: 100%!important;} 
.bloc-help {
    position: fixed;
    width: 10.5rem;
    height: 10.5rem;
    right: -1.5rem;
    top: 30%;
    background: #050F3B url(../images/ico_help.svg) no-repeat center top 20px;
    border-radius: 100%;
    padding-right: 2.2rem;
    text-align: right;
    font-size: 0.625rem;padding-top:4rem;z-index: 99;}
	
	.ctc-help {font-size: 12px;font-weight: 500;background: url(../images/ico_mail2.svg) no-repeat center left;padding: 5px 0 0px 25px;}
	.ctc-help:hover{text-decoration: underline!important;color: #3192F4;}
	.p-nomargin p{margin-bottom: 0;}
	.pointer-events-none{pointer-events: none;}
/*=====================================
 				COMMUN
 =======================================*/
 .header-sticky {padding:0 }
 .header-body {position:relative;z-index:100;background-color: #ffffff;}
 .header-sticky>.container {position: relative;z-index: 2;}
	 .top-header { font:400 0.875rem "Calibri"; padding:8px 0.625rem; color: #fff;background-color:#002C4C; position: relative;}
 .phrase-admin p {margin:  0;} 
 .phrase-admin * {font-size: 0.875rem}
	 .absolute-md {position: absolute;right: 0.938rem;top: 50%;transform: translate(0, -50%);-webkit-transform: translate(0, -50%);-ms-transform: translate(0, -50%);-o-transform: translate(0, -50%);-spec-transform: translate(0, -50%);z-index: 9;}
		 .phrase-admin p {margin:  0;}
	 .link-top-ul >li {padding: 0 12px;border-right: 1px solid #747D88}
	 .link-top-ul >li:last-child {border-right: none;}
		 .link-top-ul >li>a {display: block;position: relative;font-size:0.875rem;color:#ffffff; line-height: 0.625rem;}
		 .link-top-ul >li>a:hover{color: #115BC9; text-decoration: none!important;}
 #header{padding:15px 20px;transition:all 0.5s;-webkit-transition:all 0.5s;-moz-transition:all 0.5s;-o-transition:all 0.5s;-ms-transition:all 0.5s;position:relative;z-index: 10;background-color: #000000;}
	 .visible-mobile{display:none}
	 .visible-mobile-767{display:none}
		 .logo-site img{width: 100%;max-height: 70px;}
			 .logo-site a {position: relative;display: inline-block;}

	 .text-perso-header{font:400 0.875rem "Calibri"; height:14px}
	 .search-top{position:relative;max-width:760px; margin-left:auto; }

	.link-topsite a{color: #fff;}
	.link-topsite a:hover{color: #2DB8E8;}
	 .other-link-top {max-width:max-content;}
		 .li-m980{display:none}
		 .other-link-top>ul> li{float: left; padding: 0 12px;text-align:center;font:400 16px "Calibri"; border-left: 1px solid #44595E;}
		 .other-link-top >ul>li> a, #contenuPanierCommunWidget a{padding:  0; display: block; color: #fff; }
		 .other-link-top >ul>li> a span, #contenuPanierCommunWidget >a >span {padding-top: 3px;display: block;}

			 .other-link-top >ul>li >a:hover {color: #115BC9}

			 .icon-mandat{display: block;background: url("../images/img_administratif.png") no-repeat center left; padding-left: 60px;font-size: 1.2rem;  line-height: 1;}
			 .ico-contact-link{background: url("../images/ico_mail.svg") no-repeat  50% 0;height: 30px; width: 35px;display: block; margin-left: auto;margin-right: auto;filter: brightness(3000%);-ms-filter: brightness(3000%);-webkit-filter: brightness(3000%);}
			 .contact-link:hover .ico-contact-link {filter: brightness(100%);-ms-filter: brightness(100%);-webkit-filter: brightness(100%);}

			 .search-link:hover .ico-search-link {filter: brightness(100%);-ms-filter: brightness(100%);-webkit-filter: brightness(100%);}
			.service-header{font-size: 18px }
				.service-header span {color: #FF9739;display: block;font-size: 1rem;font-weight: normal;font-weight: bold;}
				.ico-service-link{background: url("../images/ico_sce.svg") no-repeat center top;;height: 30px; width: 35px;display: block;margin-left: auto;margin-right: auto; filter: brightness(3000%);-ms-filter: brightness(3000%);-webkit-filter: brightness(3000%);}
				.service-header:hover .ico-service-link{ filter: brightness(100%);-ms-filter: brightness(100%);-webkit-filter: brightness(100%);}
				
				.service-header span *{margin-bottom: 0;}
			 .ico-compte-link{background: url("../images/ico_compte.svg") no-repeat  50% 0;height: 30px; width: 35px;display: block;margin-left: auto;margin-right: auto; filter: brightness(3000%);-ms-filter: brightness(3000%);-webkit-filter: brightness(3000%);}

				.compte-link:hover .ico-compte-link { filter: brightness(100%);-ms-filter: brightness(100%);-webkit-filter: brightness(100%);}

				 .compte-link.link-other span {max-width:85px;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;text-transform: capitalize;}
			 .ico-panier-link{background: url("../images/ico_panier.svg") no-repeat  50% 0;width: 35px;height: 30px; display: block; margin-left: auto;margin-right: auto; filter: brightness(1000%);-ms-filter: brightness(3000%);-webkit-filter: brightness(3000%);}
				 .panier-link:hover .ico-panier-link { filter: brightness(100%);-ms-filter: brightness(100%);-webkit-filter: brightness(100%);}
				 .panier-link:hover>span{color: #115BC9;}
				 .nbre-panier{background: url("../images/bull_cart.svg") no-repeat center;position: absolute;right:-8px; top: -12px;  color: #fff; font :400 14px "sofia-pro"; padding-top: 1px; display: block;width: 27px; height: 28px}

				
				 .parent-search{max-width: 750px;margin: auto;}
			 .s-text, .search-top form>.form-group .form-control {width: 100%;padding: 2px 4.063rem 2px 20px;color: #676766;font: 400 18px "Calibri";height:45px;background: #F5F4F5;border:none;border-radius: 30px;-webkit-border-radius: 30px;-ms-border-radius: 30px;-o-border-radius: 30px;}

			 .s-text::-webkit-input-placeholder, .search-top form>.form-group .form-control::-webkit-input-placeholder { /* Chrome/Opera/Safari */
			   color: #676766;
			   opacity:1;
			 }
			 .s-text::-moz-placeholder, .search-top form>.form-group .form-control::-moz-placeholder { /* Firefox 19+ */
			   color: #676766;
				opacity:1;
			 }
			 .s-text:-ms-input-placeholder, .search-top form>.form-group .form-control:-ms-input-placeholder { /* IE 10+ */
			   color:  #676766;
				opacity:1;
			 }
			 .s-text:-moz-placeholder , .search-top form>.form-group .form-control:-moz-placeholder{ /* Firefox 18- */
			   color:  #676766;
				opacity:1;
			 }
			 .s-submit,.search-top .button { background:#115BC9 url('../images/ico_recherche.svg') no-repeat center;border: 3px solid #fff;;height: 45px;position: absolute;right: 0;top: 50%;padding: 5px 0.625rem;transform: translate(0, -50%);-webkit-transform: translate(0, -50%);-ms-transform: translate(0, -50%);-o-transform: translate(0, -50%);-spec-transform: translate(0, -50%);font-size: 0;border-radius: 25px;-webkit-border-radius: 25px;-ms-border-radius: 25px;min-width: 70px}
			 .s-submit:hover, .search-top .button:hover{background-color: #3687E8;}

	 /***********panier deroulant *********/
 .panier-deroulant {transition:all 0.3s;-webkit-transition:all 0.3s;-moz-transition:all 0.3s;-o-transition:all 0.3s;-ms-transition:all 0.3s;position: absolute;right: 0;width: 490px; opacity: 0; visibility: hidden;transform: translateY(0.625rem);-webkit-transform: translateY(0.625rem);-ms-transform: translateY(0.625rem);-o-transform: translateY(0.625rem);padding-top: 15px;z-index: 99;color:#000;font-family: Calibri;}
 .content-panier-deroulant::after {content: "";width: 27px;height: 30px;position: absolute;background: url('../images/after_deroulant.png');right: 26px;top: -2px;}

 .content-panier-deroulant{background-color: #ffffff;padding:10px 20px 25px 20px;top: 100%;box-shadow: 0px 2px 6px 0px rgba(155,155,155,0.71);-webkit-box-shadow: 0px 2px 6px 0px rgba(155,155,155,0.71);-moz-box-shadow: 0px 2px 6px; border-radius: 8px;-webkit-border-radius: 8px;-ms-border-radius: 8px;-o-border-radius: 8px;border:1px solid #E6E6E6}
 .deroulant-panierBit {border-bottom:1px solid #ccc;padding: 0.625rem 0;position: relative;  margin-right: 5px;}
 .deroulant-panierBit:last-child{border-bottom: none;}
 .image-panier-der {display: inline-block;width: 84px;padding-right:8px;vertical-align: middle}
 .image-panier-der img{width: 100%!important;}
 span.image-panier-der .image-slide {background: #f7f6f5;padding: 5px;}
	 .detail-panierBit {display: inline-block;vertical-align: middle;width:calc(100% - 88px);text-align: left;}
	 .detail-panierBit h3{font:500 0.875rem "Calibri";color:#000000}

		 .detail_bottom {margin-top: 0.938rem;}
		 .image-bienvenu >img{border:1px solid #e5dcd3; }
		 .detail-bottom > span {display: inline-block;padding-top: 3px;}
		 strong.total-panier-der {font-weight:700 ; font-size: 17px;}
		 .px-deroulant-panier {display: block;float: right;font-size:0.938rem; font-weight:normal }
		 .delete_derbit {background: none  ;border: none;color: #115BC9 ;display: block;padding: 2px 0;position: absolute;right: 0;text-align: center;top: 0;width: 20px;z-index: 99;font-size: 21px;}
 .panier-bottom {padding: 0.625rem 0;}

	 body.no-scrolling-popup{overflow:hidden!important}

		 .menu-mobile-bloc a {display: inline-block;position: relative;max-width:60px; text-align:center; color: #F7931E;}


		 .menu-mobile-bloc {position: absolute;top: 50%;left: 1rem;transform: translate(0, -50%);-webkit-transform: translate(0, -50%);-ms-transform: translate(0, -50%);-o-transform: translate(0, -50%);-spec-transform: translate(0, -50%);padding-top: 0;z-index: 99;display: none;margin-top: -18px;}
	 .close-menu-mob {display: none;position: absolute;color: #fff;right:0;top:0.5rem;z-index: 1;width:38px;height: 38px;text-align: center;padding: 8px;}
	 .span-menu-mob{display: none;color: #ffffff;font: 1.65rem "Aller-Bold";background-color: #2483D1;border: 1px solid #C0C0CC;padding: 7px 10px;}
	 
	 .menu-top {position: relative;z-index: 99;}
	 .max-200{max-height:15rem; overflow-x:hidden!important; overflow-y:hidden}
	 .max-100{max-height:100px; overflow-x:hidden!important; overflow-y:hidden}
	 .scrollPerso-s.max-200, .scrollPerso.max-200 {overflow-y: auto;}
 /*****IIE STYLE*******/
 .phrase-admin{padding:10px 15px;background-color: #115BC9;color:#fff; display: none;}
 .visible-fixed, .logo-site a>img + img{display: none}
 @media only screen and (min-width:1251px) {
 .hidden-desk{display: none;}
 .left-logo.order-md-1 {min-width: 27%;}
 }

 @media only screen and (min-width:1201px) {
	.body-monsieurbiz_sylius_search_taxon .big-wrapper {overflow-x: initial;}
	 .logo-site a>img + img{opacity: 0; position: absolute;width: 100%;left: 0; top: 50%; transform: translate(0,-50%);-webkit-transform: translate(0, -50%);-ms-transform: translate(0, -50%);-o-transform: translate(0, -50%);-spec-transform: translate(0, -50%);margin-top: -3px; display: block;}
	 .li-panier:hover .panier-deroulant {opacity: 1;visibility:visible;transform: translateY(0);-webkit-transform: translateY(0);-ms-transform: translateY(0);-o-transform: translateY(0);}
	 .headerFixed .panier-deroulant {padding-top: 1rem}
	 .absolute-md {margin-top: -2px;}

	.phrase-admin {margin-top: -15px;position: relative;z-index: 3;display: block;}
.abs-ancre{position: absolute;top: -100px;}
 }

/* clears the ‘X’ from Internet Explorer */
input[type=search]::-ms-clear { display: none; width : 0; height: 0; }
input[type=search]::-ms-reveal { display: none; width : 0; height: 0; }
/* clears the ‘X’ from Chrome */
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration { display: none; }
.search-top  [type="search"]::-webkit-search-cancel-button {filter: invert(100%) sepia(100%) saturate(200%) hue-rotate(1deg);}
/*=================================
			NAVIGATION
==================================*/
.fixed-devis {position: fixed;right: -0.625rem;top: 30%;z-index: 992;width: 107px;font: 400 20px "Calibri"}
.no-scrolling .fixed-devis{display: none!important}
	.fixed-devis a {background: #050F3B;display: block;color: #fff;text-transform: uppercase;padding: 25px 0.625rem 0.938rem 0.625rem;text-align: center;border-radius: 0.625rem 0 0 0.625rem;-webkit-border-radius: 0.625rem 0 0 0.625rem;-ms-border-radius: 0.625rem 0 0 0.625rem;-o-border-radius: 0.625rem 0 0 0.625rem;}

		.has-bg-devis {display: block;font: 400 24px "Calibri";padding-top: 3px;} 
		.icon-devis{display: block;text-align: center;}
		.icon-devis img{transform: scale(0.95);-webkit-transform: scale(0.95);-ms-transform: scale(0.95);-o-transform: scale(0.95);-spec-transform: scale(0.95);transition:all 0.5s;-webkit-transition:all 0.5s;-moz-transition:all 0.5s;-o-transition:all 0.5s;-ms-transition:all 0.5s}
		.fixed-devis a:hover  img{transform: scale(1.05);-webkit-transform: scale(1.05);-ms-transform: scale(1.05);-o-transform: scale(1.05);-spec-transform: scale(1.05);}

			.fixed-devis a em {display: block;font: 300 italic 1rem "Calibri";text-transform: none;margin: 5px 0}
.fixed-devis a:hover{background-color: #050F3B ; }
.navigation-site {position:relative; z-index:9;background-color: #fff;}

.back_menu{padding: 15px 0;font: 400 1rem "Calibri";color: #115BC9;}
	.navigation-site .onglets {text-align: center;margin: auto;}

		.onglets>.navItem >.navLink{display:block;color:#050F3B;font:1rem "Calibri-Bold";padding:1rem;position:relative; z-index:99999;transition:all 0.3s;-webkit-transition:all 0.3s;-moz-transition:all 0.3s;-o-transition:all 0.3s;-ms-transition:all 0.3s;border:none;position: relative;border:2px solid rgba(0,0,0,0); text-transform: uppercase; }

	
		.onglets>.navItem:not(.has-sub-item) >.navLink i{display: none;}
		.onglets>.navItem.has-sub-item:hover >.navLink i::before{content: '\e8c5';}
		.onglets>.navItem >.navLink strong{display: block;}
		.onglets>.navItem:hover >.navLink{background-color: #115BC9;color: #fff!important;}


.navItem .autocomplete-search .form-control {outline: 0;padding-right: 35px;}

.arianeBox{padding:0.938rem 0;color:#6D8AB5;}
		.subMenu{position:absolute;width:100%;top:100%;left:0;z-index:9999;display:none; text-align: left;border-top: 7px solid #115BC9;background-color:#fff;}
		.sub-menu-top{padding:25px 10px; max-width: 1430px;margin: auto}
				.sub-product{ padding-top: 20px}
				.sub-product li{padding-bottom: 5px}
				.max-img-menu img{width: 100px;height: 100px;border: 2px solid #3192F4;border-radius: 100%;}
				.sub-product a {color: #0E1C33;font: 0.875rem "Calibri-Bold";text-transform: uppercase;}
				.sub-product a:first-letter{text-transform: uppercase;}
				.sub-product a:hover {color: #F7931E!important;}
				.title-menu-categ {font: 3rem "Calibri-Bold";color: #F7931E;}
				.sub-product a:hover img{border-color: #F7931E;}

body:not(.modal-open) .page-content{z-index: 3} 
@media only screen and (min-width:992px) {
	.navigation-site{display:block!important;}
	.back_menu{display: none;}

	.navigation-site .mCustomScrollBox, .navigation-site .mCSB_container {margin-right: 10px!important;}
	/*.navigation-site  .mCSB_scrollTools{display: none!important;}*/
		.onglets>.navItem:hover >.navLink{background-color: #115BC9;color: #fff!important;transition:all 0.3s;-webkit-transition:all 0.3s;-moz-transition:all 0.3s;-o-transition:all 0.3s;-ms-transition:all 0.3s;}
		.onglets>.navItem:hover>.navLink img{filter: brightness(200%);}
		.onglets>.navItem:hover >.subMenu{display: block!important;}
		.onglets>.navItem.has-sub-item >.navLink::before {content: "";width: 100%;height: 5px;background: #fff;position: absolute;right: 0;bottom:-5px;display: none;}
		.onglets>.navItem >.navLink::after {content: "";width: 1px;height: 11px;background: #BFD3DD;position: absolute;right: -3px;top: 50%; margin-top: -6px;}
		.onglets>.navItem:first-child >.navLink span::before {content: "";width: 1px;height: 11px;background: #BFD3DD;position: absolute;left: -3px;top: 50%; margin-top: -6px;display: block}
		.onglets>.navItem:first-child:hover >.navLink span::before {opacity: 0;}
		.onglets>.navItem.has-sub-item:hover >.navLink::before{display: none;}
		.onglets>.navItem:hover>.navLink::after{opacity: 0;}
		/*.onglets>.navItem.has-sub-item >.navLink{pointer-events: none;} */
		.col-sscateg-li{max-width: 14.28%;flex: 0 0 14.28%}
		.navigation-site  .col-sscateg-li{max-width: 10%;flex: 0 0 10%}
		.subBloc{max-height:65vh; overflow-y: auto;overflow-x: hidden;}
		.onglets>.navItem >.navLink{    display: flex;align-items: center;height: 100%;}
		.categorie-top .sscategory-item-lib{font-size: 0.925rem!important;}
}
@media only screen and (min-width:1201px) {
	  .col-sscateg-li{max-width: 10%;flex: 0 0 10%}
	  .categorie-top .sscategory-item-lib{max-width: 118px;}
	#gotoDescription{position: absolute;top: -160px;}
	.navigation-site{transition:all 0.3s;-webkit-transition:all 0.3s;-moz-transition:all 0.3s;-o-transition:all 0.3s;-ms-transition:all 0.3s;}
	.header-body.headerFixed{position:fixed;width:100%;left:0;top:0;z-index:999;border-bottom: 3px solid #115BC9;}
	.logo-site{display: flex;}
.headerFixed .visible-fixed{display: block;}
.headerFixed #header{padding: 7px 0;position: static;}
	.padding-body{padding-top:100px}
		.headerFixed  .logo-site img{max-height: 46px;}
	.headerFixed .hidden-fixed{display: none!important;}
		
		.headerFixed  .link-topsite >div{float: left;padding-right: 20px;}
		.headerFixed  .service-header strong {font-size: 0;}
		.headerFixed  .other-link-top >ul>li.li-quote{margin-left: 10px;}
		.headerFixed  .other-link-top >ul>li> a span,.headerFixed #contenuPanierCommunWidget a span {display: none;}
.headerFixed .nbre-panier{right: -9px} 
				.headerFixed .search-top{margin-right: auto;max-width: 85%;} 
				.headerFixed  .hidden-link-fixed{display: none;}
			.headerFixed .s-text,  .headerFixed .search-top form>.form-group .form-control{height: 38px;padding-right: 40px;font-size: 16px;}
			.headerFixed  .s-submit,  .headerFixed  .search-top .button{height: 38px;min-width: 60px;}
			.headerFixed  .onglets>.navItem >.navLink{padding-top: 6px;padding-bottom: 6px;}
			 .right-bloc-head{min-width: 27%;}
			 .headerFixed .mandat-fixed{padding-right: 5px!important;}
			  .headerFixed .icon-mandat{background-size: 42px;padding-left: 45px;font-size: 0.875rem;}
			  .headerFixed .mandat-fixed span{font-size: 11px;}
			  .col-sscateg-li strong{    display: -webkit-box!important;-webkit-line-clamp: 2;line-clamp: 2;overflow: hidden;-webkit-box-orient: vertical;}	
	}
.logo-site h1 {position: absolute;font-size: 0;height: 0;width: 0;left: 0;color: #fff;}

.errorForm, .etoile,.surligne{color: #115BC9;}
.max-container-2{max-width: 1200px; margin:auto; padding: 0 0.625rem } 
.max-container-3{max-width: 1100px; margin:auto; padding: 0 0.625rem } 
.max-container-4{max-width: 1000px; margin:auto; padding: 0 0.625rem }
.max-container-5{max-width: 1300px; margin:auto; padding: 0 0.625rem }
.max-container-6{max-width: 1450px; margin:auto; padding: 0 0.625rem }
.max-container-7{max-width: 1370px; margin:auto }
.big-container{max-width: 1500px; margin:auto; padding: 0 0.625rem }

@media only screen and (min-width:1025px) {

	.px-20{padding-left: 20px; padding-right: 20px}
	.h1-content{margin-top: -15px;position: relative;z-index: 9;}
	
}
@media only screen and (min-width:1201px) {
	.max-container-7.px-20{padding-left: 20px!important; padding-right: 20px!important}
	.col-brand-desk{max-width: 20%;flex:0 0  20%;}
	#sylius-carouselnew.just-one .swiper-wrapper {justify-content: center;}
}
	.center-search .form-group {margin: 0;} 
	.bann-header{padding-top: 5px;padding-bottom: 5px;}
	.tooltip {box-shadow: 0 7px 4px 1px #fff;}
/*==================================== 
			SLIDER
======================================*/
.abs-pagination {position: absolute;left: 50%;width: max-content;bottom: 15px;z-index: 99;transform: translate(-50%,0);}

.abs-pagination .swiper-pagination {position: relative;bottom: 0;}
.conteneur-slider{position:relative; z-index:1;    max-height: 460px;overflow: hidden;}
.home-prev {position:absolute; top:50%;width:28px; height:52px; font-size:0; z-index:9; border:none;background:  url('../images/prev_home.png') no-repeat center top;left:2%; cursor:pointer ; transform:translate(0, -50%);-webkit-transform:translate(0, -50%);-ms-transform:translate(0, -50%);-o-transform:translate(0, -50%);-spec-transform: translate(0, -50%);}
.home-next {position:absolute; top:50%; width:28px;  height:52px; font-size:0; z-index:9; border:none;background: url('../images/next_home.png') no-repeat center top;right:2%; cursor:pointer;transform:translate(0, -50%); -webkit-transform:translate(0, -50%);-ms-transform:translate(0, -50%);-o-transform:translate(0, -50%);-spec-transform: translate(0, -50%);}

.slider-item.swiper-slide img {width: 100%;/*max-height: 500px;*/}

.slider-item a{background-position: center; background-size: cover;    display: block;pointer-events: none;}
.slider-absolute-content{position: absolute;top: 35%;left: 50%;transform: translate(-50%, -50%);-webkit-transform: translate(-50%, -50%);-ms-transform: translate(-50%, -50%);-o-transform: translate(-50%, -50%);-spec-transform: translate(-50%, -50%);color: #050F3B;width: 100%;padding: 0 0.938rem;max-width: 1200px; font: 500 22px  "sofia pro";}
.content-slider{max-width: 60%;}
	.title-slider {font: 52px "Aller-Bold";color: #115BC9;display: block;}

.slider-item >.bg-mobile-bann{background-repeat: no-repeat;display: block;background-position: 33% center; background-size: cover}
.text-btn-slider .btn-link{min-width: 140px}
.conteneur-slider .swiper-container-fade .swiper-slide-active, .conteneur-slider .swiper-container-fade .swiper-slide-active .swiper-slide-active {pointer-events: auto;z-index: 9;}

.swiper-pagination-clickable .swiper-pagination-bullet {margin: 0 4px;}
@media only screen and (min-width:1025px) {
	.slider-item a{background: none!important;}
	.slider-home .swiper-pagination-bullet-active  {border-color: #1C45AD;}
}
/*===============================
	 BLOC ABOUT  INDEX
================================*/
.btn-after-style {display: inline-block;width: 60px;height: 40px;border-radius: 0.625rem;-webkit-border-radius: 0.625rem;-ms-border-radius: 0.625rem;-o-border-radius: 0.625rem;text-align: center;padding-top: 5px;padding-left: 6px;margin-right: 0.625rem;}

.play-btn-about {color: #050F3B;font: 1rem "Calibri-Bold";text-decoration: underline;transition:all 0.3s;-webkit-transition:all 0.3s;-moz-transition:all 0.3s;-o-transition:all 0.3s;-ms-transition:all 0.3s;}
.play-btn-about:hover{color: #115BC9;filter:brightness(110%);-moz-filter:brightness(110%);-webkit-filter:brightness(110%);-ms-filter:brightness(110%);-o-filter:brightness(110%);}
.has-border-bottom{border-bottom: 1px solid #9EA6AD;}
.border-accordion{border-bottom: 1px solid #E6E6E6}
.accordion-bit:nth-child(2) .border-accordion, .accordion-bit:first-child .border-accordion{border-top: 1px solid #E6E6E6;}
.zindex{z-index: 99;} 
/*=============================== 
	CATEGORY INDEX
================================*/
.bloc-category-index {background: #000000 url("../images/bg_categ_index.jpg") no-repeat center right;background-size: cover;}
.swiper-category-style {padding: 15px 0;}
.category-item-index {max-width: 250px;margin: auto;cursor:auto}
.categore-item-desc{    display: -webkit-box;-webkit-line-clamp: 2;line-clamp: 2;overflow: hidden;-webkit-box-orient: vertical;color: #6D8AB5;}

.category-item-img img {border: 2px solid #3192F4;box-shadow: 0 0 0 2px rgba(0, 0, 0, 0)}
.category-item-img a::after{content: "";width: 30px;height: 30px;border-radius: 100%;background: #3192F4 url("../images/arrow_categ.png") no-repeat center;position: absolute;bottom: -12px;left: 50%;transform: translate(-50%,0);}
.category-item-index:hover a::after{background-color: #F7931E;}
.category-item-lib {margin-bottom: 0;padding: 0.25rem 0 0.1rem 0;font: 1.25rem "Calibri-Bold";color: #fff;text-transform: uppercase;}
.category-item-index:hover  img {border-color: #F7931E;box-shadow: 0 0 0 2px  #F7931E}
.category-item-index:hover .category-item-lib,.category-item-lib a:hover {color:  #F7931E;}

@media only screen and (min-width:575px) {
	.swiper-category-style   .swiper-slide{max-width:50%;}
}

@media only screen and (min-width:992px) {
	.categ-height-min{min-height:125px}
	
	.category-item-index {max-width: 262px;}
	.categore-item-desc{max-width: 250px;margin-left: auto;margin-right: auto;}
}

/*.categ-height-min ul>li:nth-child(n+6) {display: none;}*/
.opacity-img-categ {opacity: 0;}

.absolute-img-categ {position: absolute;left: 0;top: 0;width: 100%;height: 100%;object-fit: cover;-ms-object-fit: cover;}
.sscategory-item-lib{color: #fff;}
.sscategory-item-lib:hover ,.sscategory-item-lib a:hover {color: #F7931E}
.category-item-img {position: relative;}
.no-bg{background: none!important;}
/*===============================
		PACK INDEX
================================*/
.bg-collection{background: #000 url(../images/bg_collection1.png) repeat-x left center;color: #fff;}
@media only screen and (min-width:992px) {
.grid-pack-desktop{display: grid!important;grid-template-columns: repeat(5, 1fr);/*grid-template-rows: repeat(8, 1fr);*/grid-column-gap: 0px;grid-row-gap: 25px;transform: none !important}
	.grid-pack-desktop  .swiper-slide{max-width: 100%!important;padding: 0!important}
		.grid-pack-desktop  .swiper-slide:nth-child(1){ grid-area:1 / 1 / 3 / 3; }
		.grid-pack-desktop  .swiper-slide:nth-child(2) { grid-area: 1 / 3 / 1 / 3; }
		.grid-pack-desktop  .swiper-slide:nth-child(3) { grid-area: 1 / 4 / 1 / 4; }
		.grid-pack-desktop  .swiper-slide:nth-child(4) { grid-area: 1 / 5 / 2 / 5; }
		.grid-pack-desktop  .swiper-slide:nth-child(5){ grid-area: 2 / 3 / 2 / 4; }
		.grid-pack-desktop  .swiper-slide:nth-child(6) { grid-area:2 / 4 / 2 / 4; }
		.grid-pack-desktop  .swiper-slide:nth-child(7) { grid-area: 2 / 5 / 2 / 5; }
		.grid-pack-desktop  .swiper-slide:nth-child(1) .max-art.flex-grow-l{flex: 0;  }
		.grid-pack-desktop  .swiper-slide:nth-child(1) .flex-grow-l.position-relative_ {display: flex;flex-direction: column;justify-content: end;}
		.grid-pack-desktop  .swiper-slide:nth-child(2) .articleBit .bg-art-w::before,.grid-pack-desktop  .swiper-slide:nth-child(5) .articleBit .bg-art-w::before{background: none;}
		.grid-pack-desktop .swiper-slide{width: 100%!important;}
.grid-pack-desktop	  .swiper-slide:nth-child(n+13){display: none!important;}
}

/*===============================
		top INDEX
================================*/
@media only screen and (min-width:575px) {
	.top-index .swiper-slide{max-width: 50%;}
	.top-index-eco  .swiper-slide{max-width: 50%;}
}
@media only screen and (min-width:768px) {
	.top-index .swiper-slide{max-width: 33.3333%;}
	.top-index-eco  .swiper-slide{max-width: 33.3333%;}
}
@media only screen and (min-width:980px) {
	.top-index .swiper-slide{max-width: 25%;}
	.top-index-eco  .swiper-slide{max-width: 50%;}

}
@media only screen and (min-width:1480px) {
	.top-index .swiper-slide{max-width: 20%;}
}
@media only screen and (min-width:1610px) {
	.top-index-eco  .swiper-slide{max-width: 33.3333%;}

}
/*===============================
	 PROMO
================================*/
.max-container-1 {max-width: 1030px;margin:0 auto;}
	.title-medium,.page-categorie-content h1.header{font: 2.5rem "Calibri-Bold";color: #050F3B;} 
	.title-medium.has-after-bottom{padding-bottom: 15px;background: url(../images/border_title.png) no-repeat center bottom;}
#ModalSimilaryProduct .title-medium{display:none;}
	.title-instit-h2med h2{font:  2rem "Aller-Bold";color: #050F3B}
	.title-instit-medium h3 {font: 1.8rem "Aller-Bold";color: #050F3B}
	.title-Aller-19{font: 1.188rem "Aller-Bold";color: #050F3B}

	.title-ss-regular {font :400 2.125rem "Aller-Bold";}
	.title-big-regular {font :400  3.125rem "Calibri";}
	.title-ssm-med {font : 18px "Calibri-Bold";}

.wrapper-article-h .swiper-slide{height: auto!important;}
	.title-light-art:first-letter{text-transform: uppercase;}
	.sofiaregular-16 {font: 400 1rem "Calibri";}
	.sofiaregular-14 {font: 400 0.875rem "Calibri";}
	.sofiaregular-18 {font: 400 18px "Calibri";}
	.sofiaMedium-font {font-family: "Calibri";font-weight: 500;}
	.titleregular-small {font:400 28px "Calibri";}
	.btn-link {background:#115BC9;display: inline-block;text-align: center;color: #fff!important;padding: 0.938rem;font: 1.063rem "Calibri-Bold";text-transform: uppercase;border: none; border-radius: 0;-webkit-border-radius: 0;-ms-border-radius: 0;-o-border-radius: 0;position: relative;transition:all 0.3s;-webkit-transition:all 0.3s;-moz-transition:all 0.3s;-o-transition:all 0.3s;-ms-transition:all 0.3s;text-align: center;}
		.btn-link:hover span{position: relative;z-index: 2;}
	.btn-link.minw-sm{min-width: 12.5rem;}
	.btn-link.maxw-sm{max-width: 11.875rem;} 
	.btn-link.minw-vsm{min-width: 140px;}
	.btn-link.minw-vmd{min-width: 210px;} 
	.btn-link.minw-vlg{min-width:160px;}
	.btn-link.mw-auto{min-width: 50px;}
	.btn-link.minw-verybig{min-width: 14.375rem;}

	.btn-link:hover, .btn-link:focus {background-color: #468cf7; color:#fff; text-decoration: none;}
	.btn-link.bg-orange{background-color: #F7931E!important;}
	.btn-link.bg-green{background-color: #6D9B71;}
	.btn-link.bg-orange:hover, .btn-link.bg-orange:focus {background-color: #E58A38!important; color:#fff; text-decoration: none;}
	.btn-link.bg-green:hover, .btn-link.bg-green:focus {background-color: #93BA95; color:#fff; text-decoration: none;}

	.btn-link.bg-grey-btn{background-color: #62636A;}
	.btn-link.bg-grey-btn:hover,.btn-link.bg-grey-btn:focus{background-color: #7A7B84;}

	.btn-link.bg-white-link{background: none;border: 1px solid #fff;}
	.btn-link.bg-white-link:hover{background: #115BC9;border: 1px solid #115BC9;}
 
	.btn-link.bg-white2-link{background-color: #fff;color:  #6D9B71!important;}
	.btn-link.bg-white2-link:hover{background-color: #6D9B71;color:  #fff!important;}

.btn-link.other-radius-btn {padding-top: 0.75rem;padding-bottom: 0.75rem;font-size: 1rem;border-radius: 1.563rem;-webkit-border-radius: 1.563rem;-ms-border-radius: 1.563rem;-o-border-radius: 1.563rem;}
.btn-link.font-mini-btn {font-size: 0.75rem;padding-top: 0.75rem;padding-bottom: 0.75rem;border-radius: 10px;-webkit-border-radius: 10px;-ms-border-radius: 10px;-o-border-radius: 10px;}
	.hover-img-scale  {overflow:hidden;}
	.hover-img-scale img{transition:all 0.5s;-webkit-transition:all 0.5s;-moz-transition:all 0.5s;-o-transition:all 0.5s;-ms-transition:all 0.5s}
	.hover-img-scale:hover  img, .box-style:hover .hover-img-scale  img{transform: scale(1.05);-webkit-transform: scale(1.05);-ms-transform: scale(1.05);-o-transform: scale(1.05);-spec-transform: scale(1.05);}
	.btn-link.has-icon span {display: inline-block;}
	.py-perso-btn {padding-top: 1.05rem;padding-bottom: 1.05rem;}
@media only screen and (min-width:576px) {
	.produit-promo-item {max-width: 50%}
	.br-mobile br{display: none;}
}
@media only screen and (min-width:768px) {
.expand-parasm-mobile .summary {display: none!important;}
.expand-parasm-mobile .details {display: block!important;height: auto!important;}
.expand-parasm-mobile  .read-less{display: none!important;}
.produit-promo-item {max-width: 33.3333%}
.btn-account-grid .btn-link.maxw-sm{min-width: 11rem;}
}
@media only screen and (min-width:1025px) {
.expand-para-mobile .summary {display: none!important;}
.expand-para-mobile .details {display: block!important;height: auto!important;}
.expand-para-mobile  .read-less{display: none!important;}
.swiper-slide.produit-nouv-item {max-width: 25% ;margin-right: 0.625rem;}
.top-nouveaute.justify-center .swiper-wrapper{padding-left: 25px;padding-right: 25px;}
.produit-promo-item {max-width: 25%}
.title-small-size-lg{font-size: 2.25rem!important;}
.btn-link.minw-sm-d{min-width: 11.875rem;}
}
.expand-desc.text-grey p {margin-bottom: 0;}
/*===============================
		bloc-mandat-index INDEX
================================*/
.bloc-mandat{background-color: #050F3B;}
.bg-mandat{background: url(../images/bg_mandat.svg) no-repeat center right 15%;}
@media only screen and (min-width:768px) {
	.bottom-0{bottom: 0!important;}
	.bloc-mandat{min-height: 350px;}
}
/*===============================
		ARTICLE COMMUN
================================*/
.fontsize-normal{font-size: 0.875rem}
.font-small{font-size: 0.725rem!important}
.titlelarge-regular {font:400 55px "Calibri";}
.text-regular{font-family:"Calibri"; font-weight: 400; }
.text-sofiaMedium{font-family:"Calibri"; font-weight: 500; }
.max-container-txt{max-width: 885px} 
.max-container-txt2{max-width: 585px} 
.object-fit-img img,.object-fit-img picture img {height: 100%; object-fit: cover;-ms-object-fit: cover;-webkit-object-fit: cover; width: 100%}
.object-fit-video iframe{height: 100%; object-fit: cover;-ms-object-fit: cover;-webkit-object-fit: cover; width: 100%}
.object-fit-img.position-perso img{object-position: left center}
.articleBit-hover-action-top div#sylius-cart-validation-error {position: absolute;bottom: 0;margin-bottom: 0!important;font-size: 12px;padding: 6px;right: 0;}
 
/**********ARTICLLEBIT**************/
.bg-art-w {background: #fff;transition:all 0.5s;-webkit-transition:all 0.5s;-moz-transition:all 0.5s;-o-transition:all 0.5s;-ms-transition:all 0.5s;}
	.articleBit{padding:0 0 20px 0 ;background-color: #fff;font:400 0.938rem "Calibri";position: relative;border: 2px solid transparent;box-shadow: 0px 0px 14px 0px rgba(0,0,0,0);-webkit-transition: .3s ease-in-out;
		transition: .3s ease-in-out;-ms-transition: .3s ease-in-out;}
	.bg-art-w {background: #fff;padding: 0 20px;position: relative;}
.bg-art-w::before{content: "";width: 0.033rem;height: 100%;position: absolute;left: -2px;top: 0;background-color: #6D8AB5;}
.bg-art-w::after{content: "";width: 0.033rem;height: 100%;position: absolute;right: -2px;top: 0;background-color: #6D8AB5;}
	.articleBit:hover{border-color: #F7931E;}
	.articleBit:hover{background-color: rgba(243,147,34,0.05);}
	.articleBit:hover .bg-art-w{background-color:transparent}


	.articleBit:hover .bg-art-w::before,.articleBit:hover .bg-art-w::after,.zindex + div .articleBit .bg-art-w::before,.zindex + div .articleBit .bg-art-w::after,.zindex_before .articleBit .bg-art-w::after {display: none!important;}
	.articleBit-lib{text-align:left;padding-top:5px;  margin:0;    line-height: 0;}
		.articleBit-lib a{font:700 1rem "Calibri-Bold";color:#050F3B;}
		.articleBit-lib a:hover{color: #115BC9;}
		.articleBit-desc {max-height: 80px;display: -webkit-box;-webkit-line-clamp: 3;line-clamp: 3;overflow: hidden;-webkit-box-orient: vertical;font-size: 1rem;letter-spacing: -0.1px;}
				.table-lib {width:100%} 
		.articleBit-price {text-align: left;font:400 1.25rem "Calibri"; position:relative;color: #050F3B;}
				.price-promo{font:1.25rem "Calibri-Bold";white-space: nowrap;}
				.price-old{text-decoration: line-through;color:#B9C0C6;line-height: 12px;}
				.price-fois{color: #115BC9;font-weight:700}
				.price-old:empty{display: none;}
				.hide-td-mobile .price-promo-ht small{font-size: 0.625rem;}
		.articleBit-caract span {display: inline-block;border: 1px solid #CCCCCC;height: 20px; width: 20px;margin-right: 4px;border-radius: 100%;-webkit-border-radius: 100%;-ms-border-radius: 100%}
		.articleBit-btn >.btn-link{position: relative;}
		.articleBit-btn >.btn-link::after{content: "";width: 0px;height: 0px;border-style: solid;border-width: 7px 7px 0 7px;border-color: #fff transparent transparent transparent;transform: translate(-50%,0);position: absolute;left: 50%;top: 0;display: none;transition:all 0.5s;-webkit-transition:all 0.5s;-moz-transition:all 0.5s;-o-transition:all 0.5s;-ms-transition:all 0.5s; }
	
		.articleBit:hover .articleBit-btn >.btn-link::after{display: block;}
		.hover-btn-content{position: absolute;left: 0;top: 0;height: 100%; width: 100%;display: none;transition:all 0.5s;-webkit-transition:all 0.5s;-moz-transition:all 0.5s;-o-transition:all 0.5s;-ms-transition:all 0.5s;}
		.articleBit:hover  .hover-btn-content{display: block;}
		.td-nowrap{white-space: nowrap}
.articleBit-ima {text-align: center;position: relative;mix-blend-mode: multiply;}
.articleBit-ima-marque {position: absolute;left: 5px;bottom: 5px;}
	.opacity-img-0{opacity: 0;}
	.img-opacity-0 {opacity: 0;position: absolute;left: 0;top: 0;height: 100%;width: 100%;z-index: 1; -webkit-transition: .3s ease-in-out;
	transition: .3s ease-in-out;-ms-transition: .3s ease-in-out;}
	.img-opacity-0 img,.img-opacity-1 img {width: 100% ;height :100%; object-fit: cover; -ms-object-fit: cover;}

	.img-opacity-1 {-webkit-transition: .3s ease-in-out;
	transition: .3s ease-in-out;-ms-transition: .3s ease-in-out;position: absolute;left: 0;top: 0;height: 100%;width: 100%;z-index: 2;}
	.articleBit:hover .img-opacity-0{opacity: 1;z-index: 3;}
	.img-opacity-0 img[src*="spacer"]{opacity: 0; display: none;}
		.flex-grow-l{-webkit-box-flex: 1;-ms-flex: 1 1 auto;flex: 1 1 auto;}
		.articleBit .flex-grow-l{flex-basis: 0;-ms-flex-basis: 0;}
	.etiquette {position: absolute;left:-15px;top:0;background-color: #EF6F18;color: #fff;padding: 3px 0.625rem;text-align: center;z-index: 10; font: 15px "Calibri-Bold";min-width: 75% }
	.maxw-marque {max-width: 60px;}

	.articleBit-ima .badge {position: absolute;right: 0;bottom: 15px;top: auto;left:auto}

.etiquette.etiquette-1{background-color: #115BC9}
.etiquette-texte{left: 0;top: 0;border-radius:12px 0 12px 0;-webkit-border-radius:12px 0 12px 0;-ms-border-radius:12px 0 12px 0;color: #fff;font: 10px "Aller-Bold";min-width: 55px;padding: 7px 15px;text-transform: uppercase;}
.etiquette-texte.etiquette-top_left{top: 0;left: 0;}
.etiquette-texte.etiquette-top_right{top: 0;right: 0;left:auto}
.etiquette-texte.etiquette-bottom_right{bottom: 0;right: 0;left:auto; top: auto;}
.etiquette-texte.etiquette-bottom_left{bottom: 0;left: 0;top: auto;}




		.etiquette:empty {display: none;}
.picto-promo {position: absolute;    top: 0;min-width: 45px;padding: 7px 1px;text-align: center;background-color: #FF4343;z-index: 10;border-radius:12px 0 12px 0;-webkit-border-radius:12px 0 12px 0;-ms-border-radius:12px 0 12px 0;color: #fff;font: 10px "Aller-Bold";}

.articleBit-brand{position: absolute;left: 0;z-index: 1;bottom: 0;}
	.articleBit-brand img{max-width: 35px;width: 100%;}
.swiper-padding-hover {padding-bottom: 25px;}
.txt-speed-cart {font:italic   19px "Calibri-Bold";line-height: 1;}
.txt-speed-cart span {font-size: 17px;padding-top: 2px;}
.max-code{max-width:100px;    word-break: break-all;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;}


.border-black{border:1px solid #5B646D}
	.qte-plus-bit {position: absolute;right:5px;top: 50%;transform: translate(0,-50%);}
	.qte-moins-bit {position: absolute;left:5px;top: 50%;transform: translate(0,-50%);}


#add-to-cart-info-msg {color:#115BC9; font-weight: 700;}
#add-to-quotation-btn:disabled, #add-to-cart-btn:disabled {cursor: not-allowed;opacity: 0.5;}
/******accessoire conseil******/

.product-associations-bloc {background: #fff;padding: 25px 0.75rem 0  0.75rem;margin-top: -15px;z-index: 1;position: relative;box-shadow:0px 0 9px 0px rgba(155,155,155,0.71);-webkit-box-shadow:0px 0 9px 0px rgba(155,155,155,0.71);-ms-box-shadow: 0px 0 9px 0px rgba(155,155,155,0.71);border-radius: 0 0 12px 12px;-webkit-border-radius: 0 0 12px 12px;-ms-border-radius: 0 0 12px 12px;}
.association-bit-art{padding: 10px 0;border-bottom: 1px solid #C0C0CC;}
.product-associations-bloc ul {padding-left: 0;list-style: none;}
.max-img-association {max-width: 80px;padding-right: 10px!important;}
.association-bit-art:last-child {border: none;}
.association-bit-art  .articleBit-stock {color: #96C136;    background-size: 20px;padding-top: 17px;}
.association-bit-art .articleBit-add-cart {width: 36px;background-size: 15px;height: 32px;}
.association-bit-art .qte-panier.sylius-quantity-bit {width: 68px;border: 1px solid #5B646D;}
.association-bit-art .qte-panier.sylius-quantity-bit a img {max-width: 12px;}
.association-bit-art .articleBit-price{font-size: 13px;}
.association-bit-art .price-promo {font-size: 16px;}
.association-bit-art .articleBit-price small{padding-left: 0px;font-size: 9px;}

.association-bit-art .qte-panier.sylius-quantity-bit .form-control {height: 30px;padding: 0;text-align: center;font-size: 0.9rem;}
/*********categ page*******/

.border-table-perso-art .articleBit-stock,.article-conseil-bit .articleBit-stock {color: #96C136;    background-size: auto 19px;padding-top: 16px;    margin-bottom: -2px;}
.border-table-perso-art .qte-panier.sylius-quantity-bit .form-control, .article-conseil-bit .qte-panier.sylius-quantity-bit .form-control{height: 30px;font-size: 16px;text-align: center;}
.border-table-perso-art .qte-panier.sylius-quantity-bit, .article-conseil-bit .qte-panier.sylius-quantity-bit{border: 1px solid #5B646D;width: 88px;}
.border-table-perso-art  .articleBit-add-cart,.article-conseil-bit  .articleBit-add-cart {height: 32px; width: 46px;background-size: 15px;}


.px-promo-liste-art, .article-conseil-bit .price-promo {font:  0.938rem "Calibri-Bold";color: #F48211;white-space: nowrap;padding-left: 10px;}
.px-promo-liste-art small, .article-conseil-bit .price-promo  small{font-size: 10px;font-weight: 700 ;}

.articleBit-caract-list {padding-top: 5px;}

		span.no-stock {color: #808080;}
	.articleBit-caract-color {min-height: 21px;}

		.color-caract-bg {display: block;width: 17px;border-radius: 100%;-webkit-border-radius: 100%;-ms-border-radius: 100%;-o-border-radius: 100%;border:1px solid #fff;}
			.color-caract-bg img{display: block;border-radius: 100%;-webkit-border-radius: 100%;-ms-border-radius: 100%;-o-border-radius: 100%;}
			.color-caract-bg img[src*="blanc"]{border:1px solid #ccc;}
.color-caract-bg:hover {border-color: #115BC9}
	.btn-link-hover {position: absolute;left: 0;bottom: 0;height: 100%;width: 100%;background-color: #fff; z-index: 9; opacity: 0;visibility: hidden;transition:all 0.3s;-webkit-transition:all 0.3s;-moz-transition:all 0.3s;-o-transition:all 0.3s;-ms-transition:all 0.3s;}
	.articleBit:hover .btn-link-hover{opacity: 1; visibility: visible;}

@media only screen and (min-width: 992px){
	.max-w-site {max-width: 1700px;margin: auto;}
	.top-news{position: relative;z-index: 9;}
	.top-product-phare{position: relative;z-index: 8;background: rgba(234,234,234,0.3);padding-bottom: 30px;}
	.top-promo {position: relative;z-index: 7;}

}
@media only screen and (min-width: 1201px){

	.mb-lg-5p{margin-bottom:30px!important}

}

		.subMenu .articleBit{box-shadow: none;-webkit-box-shadow: none;-ms-box-shadow: none;border: 1px solid #C0C0CC;}
		.subMenu .articleBit-lib a{font-size: 13px;}
		.subMenu .articleBit-price{font-size: 13px;}
		.subMenu .price-promo{font-size: 1rem}
.subMenu .articleBit-hover-action{display: none!important;}


/*===============================
		reinsurance INDEX
================================*/
.reinsurance-index {position: relative;z-index: 2;margin-top: -45px;}
/*.body-sylius_shop_homepage .reinsurance-footer{display: none;}*/
.text-grey p , .max-container-txt .text-grey{line-height: normal;}

img.w-100.img-absolute-art {display: none;}

.bg-perso-w {background-color: rgba(0,0,0,0);color: #fff;border-color: #fff;}
.bg-perso-dore{background-color: rgba(0,0,0,0);color: #115BC9;border-color: #115BC9;}


.bg-perso-black{background-color: #050F3B;color: #fff;border: none!important;}
.btn-link.bg-perso-black:hover,.btn-link.bg-perso-black:focus {background-color: #333!important;border-color: #333!important;}
.btn-lg.big-txt {font-size: 1rem;}
.container-min-max{max-width: 1020px}
.overflow-hover{overflow:hidden;}
.big-link-hover{position: absolute;display: block;width: 100%; height: 100%; left: 0; top: 0}
.overflow-hover >img, .overflow-hover picture>img{transition:all 0.5s;-webkit-transition:all 0.5s;-moz-transition:all 0.5s;-o-transition:all 0.5s;-ms-transition:all 0.5s; height: 100%; width: 100%; object-fit: cover;-ms-object-fit: cover;-webkit-object-fit: cover;}
.overflow-hover:hover >img,.overflow-hover:hover picture>img {transform: scale(1.05);-webkit-transform: scale(1.05);-ms-transform: scale(1.05);-o-transform: scale(1.05);-spec-transform: scale(1.05);}
.overflow-hover:hover .absolute-box-index .btn-link{background: rgba(255,255,255,0.5)!important; color:#050F3B;}
.img-opacity-0 img {height: 100%;object-fit: cover;-ms-object-fit: cover;-o-object-fit: cover;}

.overflow-hover a >img{transition:all 0.5s;-webkit-transition:all 0.5s;-moz-transition:all 0.5s;-o-transition:all 0.5s;-ms-transition:all 0.5s; height: 100%; width: 100%; object-fit: cover;-ms-object-fit: cover;-webkit-object-fit: cover;}
.overflow-hover a:hover >img{transform: scale(1.05);-webkit-transform: scale(1.05);-ms-transform: scale(1.05);-o-transform: scale(1.05);-spec-transform: scale(1.05);}
.radius-fit-img img {border-radius: 100%;-webkit-border-radius: 100%;-ms-border-radius: 100%;-o-border-radius: 100%;-spec-border-radius: 100%;}
/*===============================
		ABOUT
================================*/
.accordion-bit i{background-color: #115BC9;color: #fff;border-radius: 100%;padding:0 3px 1px 3px;}
.accordion-bit .open i::before{content: '\e91b';}

div#accordion .card-header:hover,.accordion-bit .open  {color: #36A3E0;}
div#accordion  .accordion-bit .card-header:hover i,.accordion-bit .open i {background-color: #36A3E0;}
@media only screen and (min-width: 991px){
	.bloc-about {background-color: #ffffff;}
.bloc-about-index .row.mx-lg-n4{margin-left: -20px!important;margin-right: -20px!important;}
.bloc-about-index .row.mx-lg-n4 > div[class^="col-"]{padding-left: 20px!important;padding-right: 20px!important;}
}
@media only screen and (min-width: 768px){
	.bloc-about {background-color: #ffffff;}

.expand-me-mobile .summary,.expand-me-mobile .read-less {display: none;}
 
.expand-me-mobile .details {display: block!important;}
}

/*===============================
		FOOTEr
================================*/
.footer-bottom {padding-top: 100px;}
footer {background: #000000 url(../images/bg_footer.jpg) no-repeat top left;color: #ffffff;padding-bottom: 10px;font: 400 1rem "Calibri";background-size: cover;}
.goToTop {bottom: 3.125rem;cursor: pointer;display: none;position: fixed;right: 1.063rem;text-align: center;width:50px;z-index: 9999;padding: 0;background: #0E1C33;}
.goToTop:hover{background-color: #050F3B;}

/********REASSURANCE*******/
.max-reinsurance{max-width: 1020px; margin:auto;}
.box-av{font: 1.25rem "Calibri-Bold"; cursor: pointer;color: #115BC9;}
.box-av strong{transition:all 0.2s;-webkit-transition:all 0.2s;-moz-transition:all 0.2s;-o-transition:all 0.2s;-ms-transition:all 0.2s;display: block;}
.box-av span{line-height: normal;padding-top: 0rem!important;transition:all 0.2s;-webkit-transition:all 0.2s;-moz-transition:all 0.2s;-o-transition:all 0.2s;-ms-transition:all 0.2s;line-height: 1;}
.box-av em{font-weight: 400;font-style: normal; text-transform: none;color: #050F3B;display: block;font-family: Calibri;}
@media only screen and (min-width: 1201px){
	.minw-menu-content{min-width: 210px}
}
@media only screen and (min-width: 576px){
.reinsurance-bloc .box-av:hover strong{margin-bottom: -5px;padding-bottom: 5px!important; }
.reinsurance-bloc .box-av:hover span{color: #115BC9; margin-top: -8px!important;padding-bottom: 2px!important; }
.reinsurance-bloc .box-av:hover   img{  filter: brightness(120%);-ms-filter: brightness(120%);-o-filter: brightness(120%);}
.reinsurance-bloc .box-av:hover em{color: #115BC9;}
}
.padding-reinsurance{padding:0.725rem 0 0.625rem 0;}
.mp-0 p{margin-bottom: 0}
/*****RESEAU SOCIO**********/


.relative-99{z-index: 10; position: relative;}
.bg-fixed-reseau::after{position: absolute;width: 100%; height: 100%; content: ""; background-color: rgba(0,0,0,0.1); z-index: 9; top: 0; left: 0}
.reseau-footer a{display: inline-block; margin: 0 0.625rem; border: 1px solid #fff;background-color: transparent;max-width: 40px;}
.reseau-footer a:hover{background-color: #115BC9;border-color: #115BC9;}

/* Pulse */
@-webkit-keyframes hvr-pulse {
  25% {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }
  75% {
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
  }
}
@keyframes hvr-pulse {
  25% {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }
  75% {
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
  }
}
.hvr-pulse img{
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  -webkit-box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  -ms-box-shadow: 0 0 1px rgba(0, 0, 0, 0);
}
.hvr-pulse:hover img, .hvr-pulse:focus img, .hvr-pulse:active  img{
  -webkit-animation-name: hvr-pulse;
  animation-name: hvr-pulse;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
}
.paymethod-hover {position: relative;border-color: #747D88!important;}
.visible-hover{position: absolute;left: 50%; top: 0;z-index:9 ; opacity: 0;-webkit-opacity: 0;-ms-opacity: 0;-o-opacity: 0;visibility: hidden;transition: all 0.2s;-webkit-transition: all 0.2s;-moz-transition: all 0.2s;-o-transition: all 0.2s;-ms-transition: all 0.2s;transform: translate(-50%,0); -webkit-transform: translate(-50%,0);-ms-transform: translate(-50%,0);-o-transform: translate(-50%,0);width: 100%;}
.ico-reseau{position: relative;}
		.paymethod-hover:hover .visible-hover, .ico-reseau:hover .visible-hover{opacity: 1;-webkit-opacity: 1;-ms-opacity: 1;-o-opacity: 1;visibility: visible;}
		.paymethod-hover:hover{border-color: #2DB8E8!important;}
		.paymethod-hover:hover .hidden-hover,.ico-reseau:hover .hidden-hover{opacity: 0.2;-webkit-opacity: 0.2;-ms-opacity: 0.2;-o-opacity: 0.2;}
.font-ctc-footer{font:  28px "Calibri-Bold";}
.ctc-footer span{display: inline-block;background: url("../images/ico_mailw.svg") no-repeat center left; padding: 7px 0 7px 28px;}
/********MENU**********/
.text-underline {text-decoration: underline;}
.title-menu-t {font :1.5rem "Calibri-Bold";  padding-bottom: 10px; margin-bottom: 0; color: #ffffff;}
	ul.toggle-m-c li {margin-bottom: 1px ;padding-top: 1px; font-size: 1rem;}
	.border-left-w-menu{border-left: 1px solid #115BC9;}
	.border-left-w-menu .menu-content,.border-left-w-menu .bloc-other-m {padding-left:5px;}
	.copy-text a {text-decoration: underline;}
.logo-footer {margin-bottom: 5px;}
	.logo-footer img {max-width: 170px;}
.bloc-paiement-footer img{max-width: 100px;}
.menu-content .col-6.py-2.hidden-other-categ {display: none;}
.ariane-box {padding: 20px 0;font: italic 400 1rem "Calibri";color: #6D8AB5!important;}
 .ariane-box *{font:  italic 400 1rem "Calibri";color: #6D8AB5!important}
 .ariane-box h1, .ariane-box .active {font-family: 'Calibri-Bold'; font-weight: normal;}
  .ariane-box .breadcrumb-item+.breadcrumb-item::before{color: #6D8AB5!important}
 .ariane-box a:hover {color: #115BC9!important;}

.ico-reseau-c{display: inline-block; margin: 0 0.625rem;}
	/*****MODAL DIALOGUE*********/
	body .modal-dialog{max-width: 550px}
	body .modal-dialog .close{background: url("../images/close_popup.png") no-repeat center;font-size: 0;position: absolute;   right: 10px;top: 50%;transform: translate(0, -50%);-webkit-transform: translate(0, -50%);-ms-transform: translate(0, -50%);margin: 0;}
	.bloc-other-m p {margin: 0;}
	.page-article .modal-title {display: none;}
.page-article .modal-header {border: none;margin-top: 8px;}
#ModalSimilaryProduct  .modal-dialog{max-width: 840px}
@media only screen and (min-width: 992px) {
.max-w-paiement{max-width: 212px}
}
div#ModalSimilaryProduct:not(.show) {display: block!important;visibility: hidden;}

/******modal product image******/
.body-sylius_shop_cart_summary.modal-open .main-content.page-content {z-index: initial;}
.body-sylius_shop_product_show .ekko-lightbox .modal-header.hide {display: flex!important;justify-content: end;}
.body-sylius_shop_product_show .ekko-lightbox .modal-header.hide h4.modal-title {display: none;}
.body-sylius_shop_product_show  .ekko-lightbox .modal-header.hide .modal-dialog .close {position: relative;}
.body-sylius_shop_product_show .ekko-lightbox .modal-header.hide .close {position: relative;top: 0;transform: none;}
/*.body-sylius_shop_product_show .ekko-lightbox-item {opacity: 1!important;}
.body-sylius_shop_product_show .ekko-lightbox {opacity: 1!important;transition: none!important;padding-right: 0!important;}
.body-sylius_shop_product_show .modal-dialog {display: block!important;max-width: 50vw!important;}
.body-sylius_shop_product_show  .ekko-lightbox-container>div.ekko-lightbox-item {position: absolute;top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
}*/
.body-sylius_shop_product_show  .ekko-lightbox  .modal-footer{display: none!important;}
.ekko-lightbox {display: flex !important;align-items: center;justify-content: center;padding-right: 0px!important;}
  .ekko-lightbox-container {position: relative;}
  .ekko-lightbox-container > div.ekko-lightbox-item {position: absolute;top: 0;left: 0;bottom: 0;right: 0;width: 100%;}
  .ekko-lightbox iframe {width: 100%;height: 100%;}
  .ekko-lightbox-nav-overlay {z-index: 100;position: absolute;top: 0;left: 0;width: 100%;height: 100%;display: flex; }
  .ekko-lightbox-nav-overlay a {flex: 1;display: flex;align-items: center;opacity: 0;transition: opacity 0.5s;color: #fff;font-size: 30px;z-index: 100;}
  .ekko-lightbox-nav-overlay a > * {flex-grow: 1;}
  .ekko-lightbox-nav-overlay a > *:focus {outline: none;}
  .ekko-lightbox-nav-overlay a span {padding: 0 30px;}
  .ekko-lightbox-nav-overlay a:last-child span {text-align: right;}
  .ekko-lightbox-nav-overlay a:hover {text-decoration: none;}
  .ekko-lightbox-nav-overlay a:focus {outline: none;}
  .ekko-lightbox-nav-overlay a.disabled {cursor: default;visibility: hidden;}
  .ekko-lightbox a:hover {opacity: 1;text-decoration: none;}
  .ekko-lightbox .modal-dialog {display: none;}
  .ekko-lightbox .modal-footer {text-align: left;}
  .ekko-lightbox-loader {position: absolute;top: 0;left: 0;bottom: 0;right: 0;width: 100%;display: flex;flex-direction: column;justify-content: center;align-items: center;}
  .ekko-lightbox-loader > div {width: 40px;height: 40px;position: relative;text-align: center;}
  .ekko-lightbox-loader > div > div {width: 100%;height: 100%;border-radius: 50%;background-color: #115BC9;opacity: 0.6;position: absolute;top: 0;left: 0;animation: sk-bounce 2s infinite ease-in-out;}
  .ekko-lightbox-loader > div > div:last-child {
	animation-delay: -1s;
  }
  .modal-dialog .ekko-lightbox-loader > div > div {
	background-color: #333;
  }
  @-webkit-keyframes sk-bounce {
	0%,
	100% {
	  -webkit-transform: scale(0);
	}
	50% {
	  -webkit-transform: scale(1);
	}
  }
  @keyframes sk-bounce {
	0%,
	100% {
	  transform: scale(0);
	  -webkit-transform: scale(0);
	}
	50% {
	  transform: scale(1);
	  -webkit-transform: scale(1);
	}
  }

/*=============================
			CATEGORIE
===============================*/
.bg-categorie-top{color: #fff;background: #000 url(../images/bg_sscateg.png) no-repeat center right;background-size: cover;}
.title-categ{font:  2.5rem "Calibri-Bold"; text-transform: none;color: #fff; margin-bottom: 0; padding-bottom: 0;position: relative;}
.btn2-slide-prev.category-prev.swiper-button-disabled, .btn2-slide-next.category-next.swiper-button-disabled {opacity: 0;}
.absolute-categ{z-index: 2;}
#descr-desktop{display: none;}
.max-container-categ{max-width: 1248px; margin: auto;}
	.zindex-2{z-index: 2}
	.zindex-1{z-index: 1}
	.zindex-3{z-index: 3}
	.show-filtre {font: 400 20px "Calibri";}
	.filtre-cat {padding:15px 0 ; }
		.categorie-filtre {display: table;width: 100%; font-size:0.938rem; }
		.max-line-3 {max-height: 100px;display: -webkit-box;-webkit-line-clamp: 3;line-clamp: 3;overflow: hidden;-webkit-box-orient: vertical;}
		.max-line-2 {max-height: 100px;display: -webkit-box;-webkit-line-clamp: 2;line-clamp: 2;overflow: hidden;-webkit-box-orient: vertical;}
.close-filtre-categ {position: absolute;border-radius: 10px;-webkit-border-radius: 10px;-ms-border-radius: 10px;-o-border-radius:10px;-spec-border-radius: 10px;padding: 0.625rem;right: 35px;top: 35px;z-index: 99; max-width: 2.5rem;background-color: #115BC9;}
.content-fitre-categ form {display: flex;justify-content: center;flex-wrap: wrap;}
				.lib-f-c {margin-bottom: 0.625rem;font:400 0.938rem "Calibri";color: #ffffff;}
				.mbiz-sylius-search-filters .header.item{width:100px}
				.content-fitre-categ {margin: 0 -0.75rem;}
				.content-fitre-categ::after{display:block; content:""; clear:both}
				.filtre-c-bit {width:100%;max-width: 20%; padding: 0 0.875rem;position:relative; z-index:30; margin-bottom: 2px}
					.filtre-c-bit:nth-child(2){ z-index:29}
					.filtre-c-bit:nth-child(3){ z-index:28}
					.filtre-c-bit:nth-child(4){ z-index:27}
					.filtre-c-bit:nth-child(5){ z-index:26}
					.filtre-c-bit:nth-child(6){ z-index:25}
					.filtre-c-bit:nth-child(7){ z-index:24}
					.filtre-c-bit:nth-child(8){ z-index:23}
					.filtre-c-bit:nth-child(9){ z-index:22}
					.filtre-c-bit:nth-child(10){ z-index:21}
					.filtre-c-bit:nth-child(11){ z-index:20}
					.filtre-c-bit:nth-child(12){ z-index:19}
					.filtre-c-bit:nth-child(13){ z-index:18}
					.filtre-c-bit:nth-child(14){ z-index:17}
					.filtre-c-bit:nth-child(15){ z-index:16}
					.filtre-c-bit:nth-child(16){ z-index:15}
					.filtre-c-bit:nth-child(17){ z-index:14}
					.cat-30,.cat-27,.cat-54,.cat-60 {display:none!important}
				.c-f{position:relative; z-index:1;  margin:auto}
					.filtre-c-bit select, .select-f  {width: 100%;height: 2rem;background: #fff url('../images/f_select.svg') no-repeat center right 0.938rem;padding:5px 30px 5px 15px; margin:auto; cursor:pointer; position:relative; z-index:1;border:none;border-bottom: 1px solid #6D8AB5;  font:  1.125rem "Calibri-Bold";    text-overflow: ellipsis;white-space: nowrap;overflow: hidden;color: #050F3B;border-radius: 0;}
					.filtre-c-bit .select-f.active {background-image:url('../images/f_select2.svg');background-color: #ffffff;}
					.select-f.has-selection.active{background-image:url('../images/f_select.svg')}
					.filter-values.filtreContenu.price-filter {font-size: 11px;}
					.item.filtre-c-bit button.btn-link.bg-grey-btn {height: 2.813rem;background: #96C136;}
						/********taille choix********/
						.choix-container-ul .checkbox {padding-left: 0;}
						.checkbox-filter {position: relative;z-index: 1;display: block;}
							.checkbox-filter  input.hidden {position: absolute;opacity: 0;-webkit-opacity: 0;-ms-opacity: 0;-o-opacity: 0;width: 100%;margin-left: 0;height: 100%;margin: 0;}
							.checkbox-filter  input.hidden + label {display: block;background: url('../images/check_t.png') no-repeat center left;padding: 8px 0 8px 1.75rem;background-size:22px; margin-bottom:0; color: #666666;}
							.checkbox-filter  input.hidden:checked  + label {background-image: url('../images/check_tok.png');color: #050F3B;}

							.checkbox-filter  a.selectFacetteCaractval  {background-image: url('../images/check_tok.png');color: #115BC9}
							.filtreContenu .mCSB_inside>.mCSB_container {margin-right: 5px}

						/********couleur choix********/
						.filtreContenu ul.couleur {max-width: 190px;margin: auto;padding-top: 2.188rem; padding-bottom: 2.188rem}
						.facette_couleur.couleur a {display: block;margin: auto;border-radius: 100%;-webkit-border-radius: 100%;-ms-border-radius: 100%;-o-border-radius: 100%; border:2px solid rgba(0,0,0,0);max-width: 32px; font-size: 0}
						.facette_couleur.couleur a img{width: 100%; height: 100%;border-radius: 100%;-webkit-border-radius: 100%;-ms-border-radius: 100%;-o-border-radius: 100%;display: block;}
							.facette_couleur.couleur a img[src*="blanc"]{border:1px solid #ccc;}
						.facette_couleur.couleur {max-width: 30%;flex: 0 0 30%;-ms-flex: 0 0 30%;text-align: center;padding:0 2px;margin-bottom: 0.625rem}
						.facette_couleur.couleur a.selectFacetteCaractval{border-color: #115BC9}

.empty-selection{display: none!important;}

.no-sscateg-list .hide-no-ss-categ{display: none!important;}
		.res-filtre >strong {font: 400 14px "Calibri";}
				.nbre-article {padding-right: 20px;font: 400 14px "Calibri";}
.res-filtre  .filtre{display: inline-block;margin: 5px;padding:3px 0.625rem ;background-color: #F5F4F5;border-radius: 20px;}
.res-filtre  .filtre a.delete-filter{  color:#0E1C33; position: relative;text-align: left;display: inline-block; }

	.res-filtre  .filtre a i {color: #115BC9;}
	.filtreContenu {width: 100%;background: #ffffff;padding:0.625rem 5px;border:none;display:none;}
	.filter-values.filtreContenu::after {content: "";display: block;clear: both;}
	.price-filter .filter-value {float: left;width: 50%;padding: 0 3px;}
	.filter-value {padding: 0 0.625rem;margin-top:8px;text-align: left;}
	.price-range .ui.labeled.input {position: relative;padding-right: 0.938rem;}
		.price-range .ui.label {position:absolute;right: 0;top: 50%;transform: translate(0,-50%);-webkit-transform: translate(0,-50%);-ms-transform:translate(0,-50%);-o-transform:translate(0,-50%);-spec-transform: translate(0, -50%);font: 400 1rem "Calibri";}
		.price-range .ui.labeled.input input {width: 100%;height: 2.188rem;border: 1px solid #e6e6e6;padding: 2px 0.625rem;}
		.row.mt-2.ss-categ li {display: none;}
		.btn-display {background-color: #5B646C;border-radius: 5px;cursor: pointer;}
		.btn-display.active{background-color: #115BC9;}

		.menu-categorie-left ul {padding-left: 0;list-style: none;}
		.nav-ss-catalogue {font-size: 0.938rem;color: #606060;font-weight: 400;}
		.catalogue-categorie{border-top:1px solid #C0C0CC;}
		.catalogue-categorie >li {padding: 4px 0;border-bottom: 1px solid #C0C0CC;}
			.sous-catalogue-categorie{padding-bottom: 8px;}

.box-shadow-perso{box-shadow: 0px 0px 19px 0px rgba(155,155,155,0.71);-webkit-box-shadow: 0px 0px 19px 0px rgba(155,155,155,0.71);-moz-box-shadow: 0px 0px 19px 0px rgba(155,155,155,0.71);  border-radius: 12px;background-color: #ffffff;}
.mini-box-shadow{    box-shadow: 0px 3px 10px 3px rgba(155,155,155,0.31);
-webkit-box-shadow: 0px 3px 10px 3px rgba(155,155,155,0.31);
-moz-box-shadow: 0px 3px 10px 3px rgba(155,155,155,0.31);}
.padding-12{padding:30px  12px 15px 12px;  }
.border-bottom-left-radius{border-bottom-left-radius: 15px;border-bottom-right-radius: 15px;}
.radius-tr{background-color: #f2f2f2!important;}
.page-cms.mini-box-shadow{border-radius: 8px;-webkit-border-radius: 8px;-ms-border-radius: 8px;-o-border-radius: 8px;}
.perso-txt{position: absolute;width: 100%;height: 100%;left: 0;top: 0;font-size: 1.375rem;z-index: 9;}

.after-bann::after {
    content: "";
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background: rgba(14, 28, 51, 0.3);
    height: 100%;z-index: 8;
}
/**************categ-coneseil*****/
.article-conseil-bit {border-bottom: 1px solid #C0C0CC;padding-bottom: 10px;}

.tfooter-bit-details td{background-color: #ffffff!important;line-height: normal!important;border-bottom-color: #C0C0CC!important;}
.tfooter-bit-details:last-child td{border-bottom-color: #ffffff!important;}

.border-top-left-radius {
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
}
.topBit_zoom {background: #ffffff;box-shadow: 1px 1px 8px rgba(155,155,155,0.71);-webkit-box-shadow: 1px 1px 8px rgba(155,155,155,0.71);-ms-box-shadow: 1px 1px 8px rgba(155,155,155,0.71);border-radius: 5px;-webkit-border-radius: 5px;-ms-border-radius: 5px;width: 200px;position: absolute;     z-index: 99;}
.max-img-product img{max-width: 100px;margin: auto;}

@media only screen and (min-width:575px) {
	.swiper-sscategory-style   .swiper-slide{max-width:50%;}

}


@media only screen and (min-width:992px) {

	.row-grid-desk {display: grid!important;grid-template-columns: repeat(5, 1fr);/*grid-template-rows: repeat(8, 1fr);*/grid-column-gap: 0px;grid-row-gap: 33px;}
	.row-grid-desk  .col-cat{max-width: 100%;padding: 0!important}
		.col-cat:nth-child(1){ grid-area: 1 / 1 / 2 / 2; }
		.col-cat:nth-child(2) { grid-area: 1 / 2 / 2 / 3; }
		.col-cat:nth-child(3) { grid-area: 1 / 3 / 2 / 4; }
		.col-cat:nth-child(4) { grid-area: 1 / 4 / 2 / 5; }
		.col-cat:nth-child(5) { grid-area: 1 / 5 / 2 / 6; }
		.col-cat:nth-child(6){ grid-area: 2 / 1 / 3 / 2; }
		.col-cat:nth-child(7) { grid-area: 2 / 2 / 3 / 3; }
		.col-cat:nth-child(8) { grid-area: 2 / 3 / 3 / 4; }
		.col-cat:nth-child(9) { grid-area: 2 / 4 / 3 / 5; }
		.col-cat:nth-child(10) { grid-area: 2 / 5 / 3 / 6; }
		
		.col-cat:nth-child(11) { grid-area: 3 / 1 / 5 / 3; }

		.col-cat:nth-child(12) { grid-area: 3 / 3 / 4 / 4; }
		.col-cat:nth-child(13) { grid-area: 3 / 4 / 4 / 5; }
		.col-cat:nth-child(14) { grid-area: 3 / 5 / 4 / 6; }

		.col-cat:nth-child(15){ grid-area: 4 / 3 / 5 / 4; }
		.col-cat:nth-child(16) { grid-area:4 / 4 / 5 / 5; }
		.col-cat:nth-child(17) { grid-area: 4 / 5 / 5 / 6; }
		.col-cat:nth-child(18){ grid-area: 5 / 1 / 6 / 2; }
		.col-cat:nth-child(19) { grid-area:5 / 2 / 6 / 3; }
		.col-cat:nth-child(20) { grid-area: 5 / 3 / 6 / 4; }
		.col-cat:nth-child(21) { grid-area: 5 / 4 / 6 / 5; }
		.col-cat:nth-child(22) { grid-area: 5 / 5 / 6 / 6; }

.col-cat:nth-child(12)  .bg-art-w::before, .col-cat:nth-child(15)  .bg-art-w::before{opacity: 0;}
.col-cat:nth-child(11) .max-art.flex-grow-l{flex: 0;  }
	.swiper-sscategory-style   .swiper-slide{max-width: 33.333%;width: 33.333%!important;padding: 0 10px;margin-bottom: 25px;}

	.swiper-sscategory-style  .swiper-wrapper{transform: none!important;flex-wrap: wrap;justify-content: center;}
	.swiper-sscategory-style .category-item-index{max-width: 152px;margin-left: auto;margin-right: auto;}
	.categorie-top.flex-min-height{min-height: 250px;}
}
@media only screen and (min-width:1024px) {
	.swiper-sscategory-style   .swiper-slide{max-width: 20%;width: 20%!important;}
}
.col-cat:nth-child(11) .flex-grow-l.position-relative_ {display: flex;flex-direction: column;justify-content: end;}


.pagination.menu {justify-content: center;-webkit-box-pack: center;-ms-flex-pack: center;flex-wrap: wrap;-ms-flex-wrap: wrap;padding-left: 5px;padding-right: 5px;align-items: center;}
.pagination .item {background: none;color: #050F3B;padding: 0 5px;margin-right: 5px;font:  1.938rem "Calibri-Bold";margin-bottom: 5px;border-radius: 0;-webkit-border-radius: 0;-ms-border-radius: 0;}
.pagination .item:hover, .pagination .item:focus{color:#115BC9}


.pagination .item.prev.disabled , .pagination .item.next.disabled{opacity: 0.5;}

.pagination .item.prev::before {content: '\e8c3';font-size: 20px;font-family: fontello;font-weight: 700;margin-right: 5px;border-radius: 100%;padding: 5px 12px 4px 11px;background: #0E1C33;color: #3192F4;}
.pagination .item.next::after {content: '\e8c4';font-size: 20px;font-family: fontello;font-weight: 700;margin-left: 5px;border-radius: 100%;padding: 5px 11px 4px 12px;background: #0E1C33;color: #3192F4;}

.pagination  .item.prev:hover ::before, .pagination .item.next:hover ::after{background-color:#115BC9;color: #fff;}
.pagination .item.active {color: #115BC9;}
.cell-facette .item.hide-taxon-page {margin-top: 9px;color: #fff;} 

@media only screen and (min-width:576px) {
	.filtreContenu {position: absolute;width: 100%;left: 0;z-index: 99;top: 100%; }

}
.articleBit-desc >p{margin: 0!important;}
.popup-click-categ{position: absolute;z-index: 99;cursor: pointer;width: 100%;left: 0;top: 0;display: block;height: 100%;}
@media only screen and (min-width: 768px) {
	.popup-click-categ{display: none;}
}
@media only screen and (min-width: 992px) {

	.lib-f-c {padding-top: 10px;} 
	.filtreContenu {border: #115BC9 1px solid;border-radius:0;margin-top: -5px;padding-top: 10px;border-top-color: transparent;}
	.filtre-c-bit .select-f.active {border: #115BC9 1px solid;}
		.top-index .swiper-container {overflow: inherit;overflow-x: clip;padding-left: 1px;padding-right: 1px;}
		.swiper-conseil{padding-left: 1px;padding-right: 1px;}
		.no-sscateg-list {position: relative;z-index: 9;margin-top: -40px;}
.expand-desc-categ .details{padding-bottom:25px;}
	}
@media only screen and (min-width:1024px) {
	.col-perso-left{    -ms-flex: 0 180px;flex: 0 0 180px;max-width: 180px;}
	.categorie-top .object-img-categ  img {clip-path: polygon(0 0, 100% 0, 79% 100%, 0% 100%);  -ms-clip-path: polygon(0 0, 100% 0, 79% 100%, 0% 100%);   position: absolute;left: 0;top: 0;z-index: 1;    height: 100%;    min-width: 445px; max-width: 445px;object-fit: cover;max-height: 270px;}
	.absolute-categ{/*position: absolute;left: 50%; top: 50%; transform: translate(-50%,-50%);-webkit-transform: translate(-50%, -50%);-ms-transform: translate(-50%, -50%);-o-transform: translate(-50%, -50%);-spec-transform: translate(-50%, -50%);    max-width: 50%;*/width: 100%;margin-left: auto;margin-right: auto;}
	.mt-perso-vh{position: relative; margin-top: -50px;z-index: 2;}
	.lib-f-c {width:95px;   }

}
.expand-desc-categ table{margin-left: auto; margin-right: auto;}
.ancreCategMarque {display: block;position: relative;top: -300px;visibility: hidden;}
@media only screen and (min-width:1200px) {

.px-txt-2{padding-left: 100px; padding-right: 100px}
.col-perso-left{    -ms-flex: 0 20rem;flex: 0 0 20rem;max-width: 20rem;}
.menu-categorie-left {position: sticky;top: 130px;}
.col-lg-perso-otherlist{    -ms-flex: 0 14rem;flex: 0 0 14rem;max-width: 14rem;}
.col-right-perso-otherlist{    -ms-flex: 0 calc(100% - 14rem);flex: 0 0 calc(100% - 14rem);max-width: calc(100% - 14rem);}
.listes-articles.display-article-list >.row .col-perso-categ {max-width: 50%;flex: 0 0 50%;-ms-flex: 0 0 50%;}


}
@media only screen and (min-width:1280px) {

	.absolute-categ{  max-width:800px;width: 100%;}
	}
@media only screen and (min-width:1600px) {
	.col-perso-left{    -ms-flex: 0 21.25rem;flex: 0 0 21.25rem;max-width: 21.25rem;}
	.col-lg-perso-otherlist{    -ms-flex: 0 15.625rem;flex: 0 0 15.625rem;max-width: 15.625rem;}
.col-right-perso-otherlist{    -ms-flex: 0 calc(100% - 15.625rem);flex: 0 0 calc(100% - 15.625rem);max-width: calc(100% - 15.625rem);}
}

@media only screen and (min-device-width : 1201px) and (max-device-width : 1600px){
	.col-sscateg-perso {-ms-flex: 0 0 20%;flex: 0 0 20%;max-width: 20%;}
}

.col-img{  -ms-flex: 0 6.25rem;flex: 0 0 6.25rem;max-width: 6.25rem;}
.outof-stock-block .qte-panier label{ display: none!important;}
/*=============================
			ARTICLE
===============================*/
	.title-article-art {margin: 0;font: 1.5rem "Aller-Bold";line-height: 100%;}
	.title-article-art::first-letter{text-transform: uppercase;}
		.ref-petit {font-size: 20px;}
		.article-top {font-size: 1.125rem;}
.art-retour {font: 400 0.813rem "Calibri";color: #050F3B;border: 1px solid #050F3B;padding: 0.625rem 24px;}
.art-retour:hover{background-color: #050F3B;color: #fff;}
.btn-perso-nav {display: block;max-width: 170px;text-align: center;color: #fff;background: rgba(98,98,104,0.5);border-radius: 20px;padding: 8px 0.5rem;}
.btn-perso-nav.mx-width-big{max-width: 200px;}
.btn-perso-nav:hover,.btn-perso-nav:focus {background-color: #ccc;color:#fff}
.art-detail{position:relative; z-index:1; /*margin:0;*/ }
.sstitle-article-art {text-transform: lowercase;}
.sstitle-article-art:first-letter {text-transform: uppercase;}
	.art-top-desc {padding-bottom: 10px;margin-bottom: 10px;border-bottom: 1px solid #6D8AB5;}
		.gallery-bit-article .cursor-pointer , .thumbs-img-art .img-bit-article a{ cursor: pointer;display: flex;align-items: center;
			justify-content: center;}
			.gallery-bit-article .cursor-pointer img, .thumbs-img-art .img-bit-article a img {height: 100%;object-fit: cover;-webkit-object-fit: cover;-ms-object-fit: cover;object-position: top center;-webkit-object-position: top center;}
		.content-image-supp {position:relative;z-index:1;margin: 0 ;}
		.thumbs-img-art {max-width: 165px; padding-right: 30px;width: 100%;max-height: 650px;}
		.big-img {position: relative;width: calc(100% - 165px);background-color: #fff;}
		.big-img-ar{position:relative}
			.big-img .picto-promo {bottom: 0.938rem; left: 0.938rem}
			.suppdesk-prev {position: absolute;left: 50%;transform: translate(-50%, 0);-webkit-transform: translate(-50%, 0);-ms-transform: translate(-50%, 0);-o-transform: translate(-50%, 0);-spec-transform: translate(-50%, 0);background: url('../images/arrow_up.png') no-repeat center ;width: 21px;height: 15px;top: 0;opacity: 1!important;z-index: 9;cursor: pointer;z-index: 9;}
			.suppdesk-next {position: absolute;left: 50%;transform: translate(-50%, 0);-webkit-transform: translate(-50%, 0);-ms-transform: translate(-50%, 0);-o-transform: translate(-50%, 0);-spec-transform: translate(-50%, 0);background: url('../images/arrow_down.png') no-repeat center ;width: 21px;height: 15px;bottom: 0;opacity: 1!important;z-index: 9;cursor: pointer;z-index: 9;}
			.big-img a {display: block;}
				.select-container {position: relative;z-index: 1;text-align: center;width: auto;display: inline-block;margin-top: 0.625rem;padding: 0 0.825rem;margin-bottom: 8px}

				img.zoomImg {width: 200%!important;height: 200%!important;    cursor: zoom-in;}color-b
			.supp-img-swiper {height: 100%;}

		.content-out-stock {margin-top: 0.625rem;}
		.preview-btn {position: absolute;display: inline-block;background: #fff;padding: 5px 15px 5px 0;bottom: -16px;left: 0;}

	
	.max-art-desc{background-color: #fff;padding: 1.875rem 1.875rem 1.25rem 1.875rem;}
		.art-qte .qte-panier.sylius-quantity-bit{width: 100px;border:1px solid #6D8AB5; margin-right: 24px;position: relative;}
	
			.art-qte  .qte-panier.sylius-quantity-bit .form-control{height:70px ;width: 100%;font-size: 28px;}
			.art-qte  .qte-moins-bit{left: 10px;}
			.art-qte  .qte-plus-bit{right: 10px;}
		input#qte{width: 100%;border: 0;padding: 0 0.938rem 0 5px;box-shadow: none;-webkit-box-shadow: none;-ms-box-shadow: none;-o-box-shadow: none;-spec-box-shadow: none;height:34px;font-size: 14px;
		    line-height: normal;}
			.bull-stock {width: 11px;height: 11px;display: inline-block;background: #39B54A;border-radius: 100%;-webkit-border-radius: 100%;-ms-border-radius: 100%;-o-border-radius: 100%;margin-right: 6px;vertical-align: unset;}
.caractistique-article:empty{display: none;}
.tailleBit{min-height: 75px}
	 .selectfitre-bit .tailleBit .bootstrap-select>.btn.dropdown-toggle{padding:0!important;height: 0px; width: 0px; font-size: 0!important; background:none;display: none!important;}
	 .tailleBit .dropdown-menu {display: block!important;position: relative!important;min-height: 30px!important;transform: none!important;max-height: 100%!important;border: none!important;}

		.tailleBit .dropdown-menu .inner {max-height: 100%!important;min-height: 2.188rem!important;overflow: hidden!important;}

		.tailleBit .bootstrap-select .dropdown-menu li {display: inline-block;vertical-align: middle;margin-bottom: 5px; margin-right: 5px}
	.selectfitre-bit .tailleBit .dropdown-item  {display: inline-block;vertical-align: middle;min-width: 30px;min-height: 30px;border: 1px solid #9EA6AD;text-align: center;font : 400 14px "Calibri";line-height: 29px;padding: 0 3px}

	.select-perso {width: 100%;background: url("../images/f_select.svg") no-repeat center right 0.625rem;border: 0;padding: 0 0.938rem;box-shadow: none;-webkit-box-shadow: none;-ms-box-shadow: none;-o-box-shadow: none;-spec-box-shadow: none;height:50px;font-size: 14px;line-height: normal;border:1px  solid #747D88 ;}
 
 .qte-cart  input.form-control ,.qte-panier select  {width: 100%;background: url("../images/f_select.svg") no-repeat center right 0.625rem;border: 0;padding: 0 0.938rem 0 5px;box-shadow: none;-webkit-box-shadow: none;-ms-box-shadow: none;-o-box-shadow: none;-spec-box-shadow: none;height:21px;font-size: 14px;line-height: normal;}
.article-brand{width: 60px;}
.qte-panier label{display: none;}
.qte-panier>.form-group{margin-bottom: 0;}
				.input-qte,.art-qte input.form-control   {background-color: #ffffff;border:none;font:400 1rem "Calibri"; height: 68px;padding:0 20px ;width:100%;text-align:center; border-radius: 0;-webkit-border-radius: 0;-ms-border-radius: 0;-o-border-radius: 0;-spec-border-radius: 0;}
				.qte-plus {position: absolute;right:5px;top: 50%;transform: translate(0,-50%);}
				.qte-moins {position: absolute;left:5px;top: 50%;transform: translate(0,-50%);}
			.sylius-quantity a img {display: block;}
			.hover-promo-tier .form-check-label label {color: #115BC9;font-size: 16px;    margin-bottom: 0;}


		.selectfitre-bit .bootstrap-select:not([class*=col-]):not([class*=form-control]):not(.input-group-btn) {width: 100%;}
		.selectfitre-bit .bootstrap-select>.btn.dropdown-toggle {background: #fff;height: 50px;border: 1px solid #050F3B;border-radius: 0;-webkit-border-radius: 0;-ms-border-radius: 0;-o-border-radius: 0;-spec-border-radius: 0;line-height: 30px;font-family: "Calibri";font-weight: 400; font-size: 14px;outline: none!important ;box-shadow: none!important;-webkit-box-shadow: none!important;-ms-box-shadow: none!important;-o-box-shadow: none!important;padding-top: 9px; }
		.selectfitre-bit select.selectpicker.show-tick, #simpleProductOptions select{background: #fff url('../images/f_select.svg') no-repeat center right 5px;height: 50px;border: 1px solid #9EA6AD;border-radius: 0;-webkit-border-radius: 0;-ms-border-radius: 0;-o-border-radius: 0;-spec-border-radius: 0;line-height: 30px;font-family: "Calibri";font-weight: 400; font-size: 14px;outline: none!important ;box-shadow: none!important;-webkit-box-shadow: none!important;-ms-box-shadow: none!important;-o-box-shadow: none!important; width: 100%;padding: .375rem 25px .375rem 15px; color: #050F3B; max-width: 200px;}
		.selectfitre-bit .dropdown-menu{background: #fff;border: 1px solid #9EA6AD;margin-top:-1px;border-radius: 0;-webkit-border-radius: 0;-ms-border-radius: 0;-o-border-radius: 0;-spec-border-radius: 0; padding-top: 0; padding-bottom: 0}
		.selectfitre-bit .dropdown-toggle::after{content:url('../images/f_select.svg');border:none!important;}
		.selectfitre-bit .show .dropdown-toggle::after{transform: rotate(-180deg);-webkit-transform: rotate(-180deg);-ms-transform: rotate(-180deg);-o-transform: rotate(-180deg);-spec-transform: rotate(-180deg);}
		.selectfitre-bit .dropdown-item{padding-left: .75rem; font-size:14px }
		.selectfitre-bit .dropdown-item.active, .selectfitre-bit .dropdown-item:active{background: #050F3B; color:#fff;}

.hasno-supp .content-pagination{opacity: 0!important}
		/*******caract refonte*****/
		.caract-select-input label span {height: 100%;border: 2px solid #050F3B;text-align: center;border-radius: 5px;font:  13px "Calibri-Bold";min-height: 39px;background-color: #fff;padding: 2px 8px;}
		.caract-select-input label input:checked +  span{background-color: #050F3B;color: #fff;}

		.bull-info {background: #115BC9;color: #fff;display: inline-block;border-radius: 100%;padding: 2px 8px;}
		#payment_method_list_popup {position: absolute;left: 0;top: 100%;z-index: 99;}
		#payment_method_list_popup ul {padding: 15px 15px 15px 30px;border: 3px solid #115BC9;box-shadow: 0px 3px 10px 3px rgba(155,155,155,0.31);-webkit-box-shadow: 0px 3px 10px 3px rgba(155,155,155,0.31);-moz-box-shadow: 0px 3px 10px 3px rgba(155,155,155,0.31);border-radius: 5px;background: #115BC9;color: #fff;}
.quantite-art.Quantite{display:none}
	.lien-panier-art {padding: 0.625rem 0;margin-top:0.625rem; }
		.px-article {margin:0.625rem 0 0.625rem 0;}
		.price-art-panier {font: 1.25rem "Calibri-Bold";line-height: 1.2;}
				.article-promo {font: 1.75rem "Calibri-Bold";display:inline-block;color:#115BC9; line-height: 90%}
				.article-promo small{font-size: 1rem;font-weight: 700;}
				.article-normal {margin-left: 10px;color: #050F3B; }
				.price-art-cart small{font-size: 1.063rem;}
				.paiement-fois{color: #FA5022;font-size: 1.5rem;font-weight: 700;}
				.paiement-fois span {color: #FA5022;font-size: 0.925rem;font-weight: 700;}
				.paiement-fois small{font-size: 0.875rem;font-weight: 700;}
		.btn-panier-content { position: relative;}
			.btn-panier {  display: block;width: 100%;padding:18px 0.625rem 17px 0.625rem;font: 1.75rem "Calibri-Bold";color: #FFFFFF;border: none;text-align: center;position: relative;text-transform: uppercase;background-color: #115BC9;height: 70px;}

			.btn-panier:hover, .btn-panier:focus {color:#ffffff; background-color: #629df5;}
			.py-btn-2{padding-top: 13px; padding-bottom: 13px;}
			.btn-panier.btn-ctc-panier {background-color: #444;text-align: center;}
			.btn-panier.btn-ctc-panier:hover{background-color:#050F3B;}
			.link-after-add {display: none;padding: 0.938rem;border: #050F3B 1px solid;text-align: center;margin-top: -18px;position: absolute;width: 100%;max-width: 450px;left: 0;z-index: 1;background: #fff;top: 0;}
				.link-after-add-text {color: #115BC9;font: 400 0.938rem "Calibri";margin-bottom: 0.625rem;}
				.after-continu {padding: 5px;display: block;background: #050F3B;color: #fff;text-transform: uppercase;}
				.after-go-to-cart {display: block;padding: 5px;text-transform: uppercase;background: #115BC9;color: #fff;}
				.btn-wishlist {background-color: #115BC9;border: none;padding: 11px 10px;font-size: 30px;border-radius: 10px;-webkit-border-radius: 10px;-ms-border-radius: 10px;-o-border-radius: 10px;color: #fff;}
				.btn-wishlist a{color: #fff!important;}
				.btn-wishlist:hover,.btn-wishlist:focus {background-color: #2DB8E8;}
	.img-absolute{position: absolute;left: 2px;top: 2px;height:calc(100% - 4px)!important;width:calc(100% - 4px)!important;object-fit: cover; -ms-object-fit: cover;cursor: pointer;display: block;}
		.tab-content{padding:20px 0 40px 0;}

.tab-article-content .nav-tabs .nav-link {border: none;border-bottom: 1px solid #1c1c1b;padding-top: 0.938rem;padding-bottom: 0.938rem;font:300 25px "Calibri";position: relative}
.tab-article-content .nav-tabs .nav-link::after {content: "";width: 0;height: 1px;background: #115BC9;position: absolute;left: 0;bottom: -1px;}
		.toggle-tab{font:  17px "Aller-Bold";color: #050F3B;padding:1.25rem 0;border-radius: 0;-webkit-border-radius: 0;-ms-border-radius: 0;-o-border-radius: 0;-spec-border-radius: 0;text-align: center;position: relative;transition: all ease 0.5s;-webkit-transition: all ease 0.5s;-ms-transition: all ease 0.5s;-o-transition: all ease 0.5s; border-bottom: 1px solid #6D8AB5; text-transform: uppercase;cursor: pointer;}
		.tab-article-content .nav-tabs .nav-link.active{color: #115BC9}
	.tab-article-content .nav-tabs .nav-link.active::after	{width: 100%;}
	.toggle-tab::after{content:'\e8ca'; font-family: "fontello";font-style: normal;display:block; position:absolute; right:1rem; top:50%; transform:translate(0,-50%);-webkit-transform:translate(0,-50%);-ms-transform:translate(0,-50%);-o-transform:translate(0,-50%);-spec-transform: translate(0, -50%); color:#2483D1;font-size: 1.5rem; }
.toggle-tab.active{color: #2483D1;}
.toggle-tab.active::after{content:'\e8cd';}
.toggle-tab.active + .toggle-content-tab{ border-bottom: 1px solid #C0C0CC;}
.tab-content > .tab-pane {float: none!important;}

.toggle-content-tab {display: none;padding: 1.125rem 0;}
.tab-descr-art .nav-tabs .nav-item {padding: 0 5px;}
.tab-descr-art .nav-tabs .nav-item:first-child {padding-left: 0;}
.tab-descr-art .nav-tabs .nav-item:last-child {padding-right: 0;}  
.tab-descr-art .nav-tabs .nav-link {border-radius:0;border: none;border-bottom: 1px solid #E6E6E6; background-color:#fff;font:  17px "Aller-Bold";text-align: center;color: #0B1826;padding-top: 12px;padding-bottom: 12px;text-decoration:none!important;}
 
.tab-descr-art .nav-tabs .nav-item.show .nav-link,.tab-descr-art .nav-tabs .nav-link.active {color: #115BC9;border-color: #115BC9;}

.tab-descr-art a { text-decoration: underline; color: #0782C1; }

.toggle-tabmob{background:none ;font: 1.125rem "Aller-Bold";text-align: center;padding:1rem 12px;position: relative;border-radius: 0;color: #050F3B;border-bottom: 1px solid #e6e6e6;}
.toggle-tabmob.open {color: #115BC9;}
.toggle-tabmob::after{content:'\e8ca'; font-family: "fontello";font-style: normal;display:block; position:absolute; right:1.5rem; top:50%; transform:translate(0,-50%);-webkit-transform:translate(0,-50%);-ms-transform:translate(0,-50%);-o-transform:translate(0,-50%);-spec-transform: translate(0, -50%); color: #fff;font-size: 1.4rem;color: #115BC9;font-weight: 600;}
 .toggle-tabmob.open::after{color: #115BC9;content:'\e8cd';}

.swiper-vu .articleBit .row.no-gutters.align-items-end, .swiper-vu .articleBit .articleBit-hover-action ,  .swiper-vu .articleBit .etiquette{display: none!important;}
 .swiper-vu .articleBit {-webkit-box-shadow: 0px 0px 10px 0px rgba(155,155,155,0.71);box-shadow: 0px 0px 10px 0px rgba(155,155,155,0.71);-ms-box-shadow: 0px 0px 10px 0px rgba(155,155,155,0.71);}
 .video-content iframe {width: 100%;height: auto;}
.p-etat {padding-left: 40px;padding-top: 1px;padding-bottom: 1px;background: url(../images/ico_shipping.svg) no-repeat center left;}
.txt-livraison {background-image: url(../images/ico_livraison.svg);}
.txt-check {background-image: url(../images/ico_check.svg);}
.article-recap .list-group-item{min-height: 37px;}

#article-summary-quantity label {display: none;}

#article-summary-quantity input#sylius_add_to_cart_cartItem_quantity {background: none;padding: 0;font-size: 1rem;border: 0;font-weight: 700;color: #000;text-align: right;width: 100px;height: 20px;}
.msg-no-strong strong{display: none!important;}
#article-summary-quantity .form-group {margin-bottom: 0;}

.expand-desc .details{margin-bottom: 0.625rem}
.expand-desc .details >p:nth-last-child(2){margin-bottom: 5px}

/******POPUP MAGASIN*********/
.show-popup-store:hover, .show-popup-store:focus {background-position: left bottom;}
.modal-store{display: none;position: relative;z-index: 99999;}
.bg-modal-store{position: fixed;top: 0;width: 100%; height: 100%;left: 0;background-color: rgba(31,27,28,0.4);z-index: 999;}
.z_index_m{z-index: -1!important;}

.modal-store-content{position: fixed;top: 0;width: 92%; height: 100%;right:-100%;max-width:590px ;z-index: 9991;background-color: #fff;transition:all 0.3s;-webkit-transition:all 0.3s;-moz-transition:all 0.3s;-o-transition:all 0.3s;-ms-transition:all 0.3s;}
.open-popup  .modal-store-content{right: 0;}
.modal-header-p {background: #F2F2F2;padding: 34px 30px 34px 50px;position: relative;}
.modal-header-p h5 {font: 700 30px "Calibri";margin-bottom: 0;padding-right: 0.625rem;}
	.close-modal-store {position: absolute;right: 20px;border: none;padding: 5px;background: none;top: 50%;transform: translate(0, -50%);-webkit-transform: translate(0, -50%);-ms-transform: translate(0, -50%);-o-transform: translate(0, -50%);}
.modal-body-p {overflow-y: auto;overflow-x: hidden;padding: 20px 60px 0.938rem 50px;}
		.store-contact{padding: 8px 0;}
	.storeBit {padding: 22px 0;border-bottom: 1px solid #9EA6AD;font-family: "Calibri";font-weight: 400;}
	.storeBit.store_inactif {opacity:0.8}
	.storeBit:last-child{border-bottom: none;}
	.column-count-2-desk .row>span:nth-child(2) {min-width: 86px;}
	.collapse-me{display: none;}
	.link-toggle-store {background: url("../images/f_select.png") no-repeat center right;padding-right: 1rem;background-size: 0.625rem;display: inline-block;cursor: pointer;text-decoration: underline!important;}
	.link-toggle-store.open{background-image:url("../images/f_select2.png") ;}

	.review-create .articleBit-hover-action, .review-create .row-price {display: none!important;}
.short-descr *{font-size: 1.625rem!important;font-family: Calibri!important;font-weight: 400!important;color: #0E1C33;}
/******TIERS PLUGIN*********/

.bloc-tiers div>span, .bloc-tiers  br{display: none;}
.article-right {z-index: 9;}
.caracteristique-art{position: absolute;width: 0;height: 0;overflow: hidden;}  

.articleBit #tier_prices_tables table{margin-bottom: 0;font-size: 12px;margin-top: 5px;}
.articleBit #tier_prices_tables table thead th {font: 300 12px "Calibri";padding-top: 3px;padding-bottom: 3px;background-color: #115BC9;border-bottom: 1px solid #FFFFFF;color:#fff}

.articleBit  #tier_prices_tables table td{background-color: #fff; padding-top:4px;padding-bottom: 4px;color: #050F3B;}
.articleBit #tier_prices_tables >div> span {display: block;}
.articleBit #tier_prices_tables table + br {display: none;}
.border-table-perso-art #tier_prices_tables table{margin-bottom: 0;font-size: 12px;margin-top: 5px;}
.border-table-perso-art  #tier_prices_tables table thead th {font: 300 13px "Calibri";padding-top: 3px;padding-bottom: 3px;background-color: #115BC9;border-bottom: 1px solid #FFFFFF;color:#fff}
.border-table-perso-art   #tier_prices_tables table td{background-color: #fff; padding-top:4px;padding-bottom: 4px;color: #050F3B;border-bottom-color: #c0c0c0;}
.border-table-perso-art  #tier_prices_tables >div> span {display: block;color: #115BC9;text-align: left;}
.border-table-perso-art  #tier_prices_tables table + br {display: none;}
#tier_prices_tables {font-size: 12px;} 

.padding-art-index {padding-left: 60px;padding-right: 60px;margin-left: -60px;margin-right: -60px;}

@media only screen and (min-width: 576px){
	.produit-collection-item{max-width: 50%;}
}
@media only screen and (min-width: 640px){
	.produit-collection-item{max-width: 50%;}
}
@media only screen and (min-width: 768px){
	.step-bit-config.last::after,.step-bit-config:last-child::after {display: none;}
 #body-sylius_shop_request_password_reset_token  .big-wrapper{background-color: #F7F8F9}
 .body-table-perso{font:400 0.938rem "Calibri";}
		.border-table-perso {border: 1px solid #ccc;padding: 0.625rem;}
	 .column-count-2-desk {column-count: 2; -webkit-column-count: 2;column-gap: 50px;}
	 #TabDescription{border-bottom: 1px solid #E6E6E6;}
	 .gallery-art-image .swiper-wrapper {display: block;transform: none !important;}
	  .gallery-art-image .swiper-wrapper .swiper-slide:not(.swiper-slide-active){display: none;}

	  .supp-img-desk{height: calc(100% - 45px);width: 100% ;padding-top: 20px;padding-bottom: 20px;overflow: hidden; }
	  .height-calc-video.supp-img-desk{height: calc(100% - 170px);}
.supp-img-desk .swiper-container{ height: 100%;}
.toggle-content-tab{display: block!important;transition: none!important;height: auto!important;}
.toggle-tab::after{display: none;}
.article-top {background: rgba(232, 232, 232, 0.7);padding-bottom:50px;font-size: 1.125rem;}
.h-md-10{height: 100%;}
}
@media only screen and (min-width: 992px){
	.produit-collection-item{max-width: 34%;}
	.column-2-desk{column-count: 2; -webkit-column-count: 2;column-gap: 40px}
  .column-2-desk li{-moz-column-break-inside:avoid;-webkit-column-break-inside:avoid;-ms-column-break-inside:avoid;}
	.little-imag-art.swiper-wrapper {transform: none!important;-webkit-transform: none!important;-ms-transform: none!important;-o-transform: none!important;-spec-transform: none!important;}

.visited-prev,.visited-next{display: none;}
.swiper-visited .swiper-wrapper {transform: none!important;-webkit-transform: none!important;-ms-transform: none!important;-o-transform: none!important;-spec-transform: none!important;}
.swiper-visited .swiper-slide{width: 14.28%!important;}
.swiper-visited .swiper-slide:nth-child(n+8){display: none!important;}
.caract-qty-content .form-group{min-width: 268px}

.max-art-desc{width: 450px;}
}

@media only screen and (min-width: 1100px){
	.max-art-desc{width: 550px;}
	.produit-collection-item{max-width: 25%;margin-right: 25px;}
		.av-article .box-av span {font-size: 16px}
	.article-right_ form#sylius-product-adding-to-cart {-webkit-box-flex: 1;flex: 1 1 auto;}
	.w-recap-desk{width: 350px;}

}

@media only screen and (min-width: 1250px){
	.w-recap-desk{width: 390px;}
}
@media only screen and (max-width: 1250px){
	.tab-content iframe {max-width: 100%;width: 100%;}
}
.suppdesk-next.swiper-button-disabled, .suppdesk-prev.swiper-button-disabled {opacity: 0.5;cursor: not-allowed;}
 #body-sylius_shop_order_thank_you .big-wrapper, .body-sylius_shop_cart_summary  .big-wrapper,  .body-sylius_shop_checkout_address  .big-wrapper,.body-sylius_shop_checkout_select_shipping  .big-wrapper, .body-sylius_shop_checkout_select_payment .big-wrapper,  .body-app_shop_quotation_summary .big-wrapper,.body-app_shop_quotation_checkout_address .big-wrapper, .body-app_cart_management\.account\.saved_cart_show .big-wrapper, .body-app_shop_quotation_show .big-wrapper ,.body-sylius_shop_order_thank_you .big-wrapper{background-color:  rgba(232, 232, 232, 0.7)}
.p-nomarg p{margin-bottom: 0;}
#priceQty {display: block;}
.tab-content {height: auto!important;}
.swiper-visited .articleBit-desc ,.swiper-visited .articleBit .flex-grow-l + div  {display: none!important;}
/******avis verif******/
.skeepers_product__reviews{margin: 0!important;}
.reviews.show .review {background: #fff!important;padding: 15px!important;margin: 5px 0!important;box-shadow: 0px 3px 10px 3px rgba(155,155,155,0.31)!important;-webkit-box-shadow: 0px 3px 10px 3px rgba(155,155,155,0.31)!important;-moz-box-shadow: 0px 3px 10px 3px rgba(155,155,155,0.31)!important;border-radius: 8px!important;display: block!important;}
.border-bottom-blue{border-bottom: 1px solid #115BC9;}
@media (min-width: 992px){
	body #skeepers_product_widget.widget--vertical .rating__sorting{    -ms-flex-preferred-size: 30%;flex-basis: 30%;padding-left: 15px!important;}
		body  #skeepers_product_widget.widget--vertical .rating__value, body  #skeepers_product_widget.widget--vertical .rating__filters {-ms-flex-preferred-size: 30%;flex-basis: 30%;}
		.reviews.show .review {flex: 0 0 calc(50% - 50px)!important;width: calc(50% - 50px)!important;margin: 5px 10px!important;}

#skeepers_product_widget .reviews.show {flex-direction: row;margin: 0 -10px;flex-wrap: wrap;}
}
body #skeepers_product_widget{font-family: Calibri;}
body #skeepers_product_widget .review__text{font-family: "Calibri-Bold";font-weight: normal;}
body #skeepers_product_widget .no-reviews.show {display: block;width: 100%;height: auto;background: #fff;box-shadow: 0px 3px 10px 3px rgba(155,155,155,0.31)!important;-webkit-box-shadow: 0px 3px 10px 3px rgba(155,155,155,0.31)!important;-moz-box-shadow: 0px 3px 10px 3px rgba(155,155,155,0.31)!important;border:none}

div#description.tab-pane table {background: #fff;}

div#description.tab-pane table td {padding: 5px;border: 1px solid #ccc;}
.categorie-article-content{position: relative;z-index: 1;}


/*****video modal*******/
.video-btn{position: absolute;width: 100%;height: 100%;left: 0;top: 0;background: none;z-index: 9;border: 0;margin: 0;}

/*=================================
		PANIER
==================================*/
.d-none-checkout{display: none;}
.body-sylius_shop_checkout_complete .d-none-checkout{display: block;}
.body-sylius_shop_checkout_complete .d-none-complete{display: none!important;}
.media.align-items-center img {max-width: 100px;width: 100%;}
.table-perso{    border-collapse: separate;border-spacing: 0;}
.table-perso .card-header {color: #fff;border-bottom: 1px solid #115BC9; padding: 8px 0.75rem; font: 300 0.813rem "Calibri"; background-color: #115BC9;border-radius: 0;-ms-border-radius: 0;-webkit-border-radius: 0;}
	.table-perso .card-header th{font: 300 0.813rem "Calibri";padding-top:6px;padding-bottom:6px;height: 30px;vertical-align: middle;    border-left: 1px solid #FFFFFF;background-color: #115BC9; border-bottom: 1px solid #FFFFFF;}
	.th-white th , .page-complete .table-perso .card-header th{color: #ffffff;}
	.content-item-panier .card-header {background: #fff;color: #0E1C33;font: 400 1.125rem "Calibri";border-radius: 0;-ms-border-radius: 0;-webkit-border-radius: 0;}
	.content-item-panier .card-header .th-form{border-bottom: 1px solid #E6E6E6;padding: 7px 0;}
	.step-panier {font: 1.25rem "Aller-Bold";color: #0B1826;}
	.stepBox-bit{padding-bottom: 10px;}
	.stepBox-bit i{position: absolute;left: 0; top: 50%; transform: translate(0,-50%); color: #0B1826;    margin-top: 1px;}
	.stepBox-bit .icon-2{left:auto;right: 0}
	.stepBox-bit{padding:0 0 0 25px;}
	.stepBox-bit img {border-radius: 100%;background: #0E1C33;}
	.stepBox-bit.active-step img{background-color: #2375D8;}
	.stepBox-bit.active-step i{color: #2375D8;}
	.flex-nowrap-md{flex-wrap: nowrap;-ms-flex-wrap: nowrap;}
	.item-bit-panier {border-bottom:1px solid #E6E6E6;flex-wrap: nowrap;-ms-flex-wrap: nowrap;}
	.item-bit-panier:last-child{border-bottom: none; margin-bottom: 0!important;}
	.item-bit-panier .qte-panier.sylius-quantity-bit .form-control{padding:0 15px; border: 1px solid #E6E6E6;background-color: #F5F4F5;border-radius: 20px;height: 40px;max-width: 88px; font:  20px "Calibri-Bold";color: #115BC9;text-align: center;margin: auto;}
	.panier-lib {font: 0.935rem "Calibri-Bold";}
	.bloc-grey-cart{background-color: #E6E6E6;}
	.bloc-grey-cart::after{content: "";width: 0px;height: 0px;border-style: solid;border-width: 18px 17px 0 17px;border-color: #E6E6E6 transparent transparent transparent;transform: translate(-50%,0);position: absolute;left: 50%;bottom: -15px;}
	.bloc-grey-cart ::marker {font-size: 5px;}

.etape-panier-bit {padding: 0 30px;position: relative;text-align: center;}
.sylius-product-name{font:  1.125rem "Aller-Bold";color:#050F3B}
.etape-panier-bit::after {content: "";width: 6px;height: 6px;border-radius: 100%;background: #115BC9;position: absolute;right: 0;top: 50%;transform: translate(0, -50%);-webkit-transform: translate(0, -50%);-ms-transform: translate(0, -50%);-o-transform: translate(0, -50%);-spec-transform: translate(0, -50%);}
.etape-panier-bit.last-etape::after {display: none;}
.etape-panier-bit.active-step, .etape-panier-bit.active-step h1 {color: #115BC9;}
.suppr-panier {height: 22px;width: 22px;text-align: center;padding: 0;background:none}
.table-perso td {background: #F2F2F2;vertical-align: middle;border-left: 1px solid #fff;border-bottom: 1px solid #fff; padding: 5px;}
.table-white-perso.table-perso td {background-color: #F2F2F2;border-color: #fff;font-size: 0.938rem;line-height: 1;/*width: 8%;*/}
.tfooter-bit-details  .row.no-gutters.flex-nowrap>.col>.col {padding: 0;}
.table-white-perso.table-perso tr:nth-child(even) td{background-color: #E6E6E6;}

	.table-panier-perso td {background: #fff;vertical-align: middle;border-left: 1px solid #fff;border-bottom: 1px solid rgba(0,0,0,.125); padding: 5px;}
		.table-panier-perso.table-perso td {background-color: #fff;border-color: rgba(0,0,0,.125);font-size: 0.938rem;line-height: 1;/*width: 10%;*/border-left:none; border-right:none;    border-top: none;}
		.table-panier-perso.table-perso tr:nth-child(even) td{background-color: #ffffff;}
	.table-perso span.sylius-quantity input.form-control {background-color: #ffffff;border: 1px solid #050F3B;	font: 400 1rem "Calibri";height: 40px;padding: 0 2px 0 0.625rem;text-align: left;border-radius: 0;-webkit-border-radius: 0;-ms-border-radius: 0;-o-border-radius: 0;max-width: 75px;margin-left: auto;margin-right: auto;}
	.table-perso .sylius-total{font:400 20px "Calibri";color: #050F3B;}
	.title-h2 {font: 400 25px "Calibri";}
	.btn-perso-c{background-color: #115BC9!important;font: 400 14px "Calibri";color: #fff;padding:0.5rem 0.938rem;}
	.btn-font {font-size: 1rem;padding: 0.938rem 0.625rem;color: #fff;}
	.btn-perso-c:hover{background-color:#2DB8E8!important; color: #fff;}
	.badge-loading-perso{background-color:#2DB8E8; color: #ffffff;}
	.sylius-table-column-state span.ui.label, .order-label-perso  span.ui.label{    display: inline-block;padding: 0.25em 0.4em;font-size: 75%;font-weight: 700;line-height: 1;text-align: center;white-space: nowrap;vertical-align: baseline;border-radius: 0.25rem;background-color:#2DB8E8; color: #ffffff;}
	.badge-success-perso{background-color:#96C136; color: #ffffff;}
	.badge-success{background-color:#96C136;}
	#sylius-cart-items span.sylius-quantity {max-width: 70px;display: block;margin: auto;min-width: 50px;}
	.table-perso span.sylius-quantity input.form-control {background-color: #ffffff;border: 1px solid #747D88;	font: 400 16px "Calibri";height: 40px;padding: 0 2px 0 10px;text-align: left;border-radius: 0;-webkit-border-radius: 0;-ms-border-radius: 0;-o-border-radius: 0;max-width: 75px;margin-left: auto;margin-right: auto;}
	.contenu-panier-qty .qte-moins {right: 5px; bottom:3px}
	.contenu-panier-qty .qte-plus {right: 5px; top:3px}
	.code-promo{background-color: #050F3B ;padding: 8px 0.625rem;color:#ffffff}
	.input-code-promo{border:1px solid #C0C0CC; padding: 5px;border-radius: 10px;-webkit-border-radius: 10px;-ms-border-radius: 10px;-o-border-radius: 10px;}
	.code-promo .btn {height:35px; padding: 8px 0.625rem;}
	.code-promo .btn:hover,.code-promo button#sylius-save:focus {background-color:#115BC9 ;}
	#sylius_cart_promotionCoupon {height: 40px;border:none; padding-left: 15px;background-color: #F5F4F5;border-radius: 20px;color: #050F3B;font-size: 1rem;}
	.btn-ok-code {position: absolute;	top: 50%;transform: translateY(-50%);-webkit-transform: translateY(-50%);-ms-transform: translateY(-50%);-o-transform: translateY(-50%);-spec-transform: translateY(-50%);border: none;background: none;font: 700 15px  "Calibri";text-transform: uppercase;right: 0;padding: 0;color: #fff;height: 40px; background-color: #115BC9;padding: 5px 15px;border-radius: 20px;z-index: 9;}
	.btn-ok-code:active,.btn-ok-code:focus,.btn-ok-code:hover {color: #fff!important; background-color: #2E80C3;}
	#sylius_cart_promotionCoupon::-webkit-input-placeholder { /* Chrome/Opera/Safari */color: #0D1825;opacity:1;}
	#sylius_cart_promotionCoupon::-moz-placeholder { /* Firefox 19+ */color: #0D1825;opacity:1;}
	#sylius_cart_promotionCoupon:-ms-input-placeholder { /* IE 10+ */color:  #0D1825;opacity:1;}
	#sylius_cart_promotionCoupon:-moz-placeholder { /* Firefox 18- */color:  #0D1825;opacity:1;}

	.cart-totals .list-group-item{padding-top: 4px; padding-bottom: 5px;}
.width-auto-img {width: 90px;}
	.select-site select {height: 2.188rem;width: 100%;min-width: 100px;padding: 0 26px 0 0.625rem;background: url("../images/f_select.svg") no-repeat center right 0.625rem;max-width: 320px}
.product-options >div>label {min-width: 60px;}
	#sylius-cart-items .sylius-quantity {max-width: 70px;display: block;margin: auto;min-width: 50px;}
	.input-perso-content input.form-control {border: 1px solid #C0C0CC;border-radius: 5px;-webkit-border-radius: 5px;-ms-border-radius: 5px;-o-border-radius: 5px;-spec-border-radius: 5px;color: #050F3B}
	.total-label{font: 16px "Calibri-Bold"}
	.btn-paie{font: 500 26px "Calibri";padding: 0.938rem 0.625rem;background-color: #6D9B71;color:#fff; box-shadow: none!important;text-transform: uppercase;}

	.btn-paie:hover, .btn-paie:focus{background-color: #93BA95!important;color: #fff!important;}
	.btn-dark-perso{font: 400 14px "Calibri";padding:1rem 0.938rem;background-color: #050F3B;color:#fff!important}
	.content-adress  .steps .steps-icon{color:#fff!important; font-size: 1.3rem;}
	.content-adress  .steps .steps-item.completed {background: #333;}
	.qte-cart input.form-control {background-color: #ffffff;border: 1px solid #050F3B;font: 400 1rem "Calibri";height: 36px;padding: 0 2px;width: 60px;text-align: center;border-radius: 0;-webkit-border-radius: 0;-ms-border-radius: 0;-o-border-radius: 0;-spec-border-radius: 0;}
	.cartw-uniprice {max-width: 15%;flex: 0 0 15%;}
	.cartw-qty {max-width: 13%;flex: 0 0 13%}
	.cartw-element {flex: 0 0 55%;max-width: 55%;}
	.cartw-suppr{max-width: 50px;flex: 0 0 50px}
	.cartw-stock{ -ms-flex-preferred-size: 0;flex-basis: 0;-ms-flex-positive: 1;flex-grow: 1;max-width: 100%;}
	.cartw-total{    -ms-flex-preferred-size: 0;flex-basis: 0;-ms-flex-positive: 1;flex-grow: 1;max-width: 100%;}
	.px-perso-cart{padding-left:30px!important;padding-right:30px!important;}
	.btn-panier-cde{    padding: 15px 0.625rem ;font:400 1.563rem "Calibri";color: #FFFFFF;border: none;text-align: center;position: relative;
		border-radius:10px!important;-webkit-border-radius: 10px!important;-ms-border-radius: 10px!important;-o-border-radius: 10px!important;text-transform: uppercase;background-color: #96C136;width: 100%;}
		.cart-grand-total {font-size: 26px;color: #F48211;}
		.panier-rech-rapide{position: relative;background-color: #050F3B;color: #fff;font: 500 0.938rem "Calibri";margin-left: -30px;margin-right: -30px;padding-top: 12px;padding-bottom: 12px;border-radius: 0 0 15px 15px;-webkit-border-radius: 0 0 15px 15px;-ms-border-radius: 0 0 15px 15px;}
		.maxw-btn-panier{max-width: 320px;margin-left: auto;margin-right: auto;}

		.max-width-form-search {position: relative;max-width: 80%;}
		.max-width-form-search .form-group{margin-bottom: 0;}
		.line-height-1{line-height: 1.1;}
		.btn-panier-action .btn-link{padding-left: 10px;padding-right: 10px;}

		.bit-details-tr .td-nowrap.pl-sm-3.pl-2 {white-space: nowrap;}
	/*********ETAPE 2********/ 
	.bg-perso-point {padding:20px 0;}
	h1.card-header{font:300 14px "Calibri"}
	.content-adress .steps-item {background: #050F3B;color: #fff;}
		.content-adress .steps-item.active {	background: #115BC9;}
		.content-adress  .steps .steps-item .steps-title {font:300 0.938rem "Calibri"}
 
	.form-perso-content .form-control{border-radius: 20px;-webkit-border-radius: 20px;-ms-border-radius: 20px;-o-border-radius:20px;-spec-border-radius: 20px;font: italic 400 13px "Calibri";color:#050F3B;    border: 1px solid #E6E6E6;background-color: #F5F4F5; height:40px;}
	.form-perso-content .form-control:focus{box-shadow:none;-webkit-box-shadow:none;-ms-box-shadow:none;-o-box-shadow:none;border-color: #6D8AB5;}
	.form-perso-content label.required:after{color: #dc3545;}
	 .form-perso-content  .champform input{height: 40px;border:1px solid #9EA6AD;font: 400 14px "Calibri";width: 100%;padding: 5px 0.938rem;}

	.form-perso-content textarea.form-control {min-height: 180px;border-radius: 20px;-webkit-border-radius: 20px;-ms-border-radius: 20px;-o-border-radius:20px;-spec-border-radius: 20px;color:#050F3B;    border: 1px solid #E6E6E6;font:italic 400 13px "Calibri";width: 100%;padding:0.625rem 0.938rem;outline: 0;background-color: #F5F4F5;}
	.form-perso-content .champform textarea {min-height: 180px;border:1px solid #E6E6E6;font:italic 400 13px "Calibri";width: 100%;padding:0.625rem 0.938rem;outline: 0;background-color: #F5F4F5;} 
	#recapitulatif-total-commande .item-bit-panier .media img {width: 65px;}
form input:-webkit-autofill {box-shadow: 0 0 0px 1px #fafafa inset;-webkit-box-shadow: 0 0 0px 1px #fafafa inset;	-webkit-text-fill-color: #050F3B !important;}
form .adresse-newsletter:-webkit-autofill {box-shadow: 0 0 0px 1px #faf0ee inset;-webkit-box-shadow: 0 0 0px 1px #faf0ee inset;-webkit-text-fill-color: #050F3B !important;}
.ico-livraison-panier {display: inline-block;padding: 8px 0 8px 40px;background: url("../images/ico_livraison.svg")no-repeat center left;min-width: 280px;text-align: left;background-size: 30px;}
.bg-grey{background-color: #EAEAEA;}
.bg-light-site{background-color: rgba(232,232,232,0.7);}
table#sylius-checkout-subtotal td.py-3.pl-0.border-color-dore.color-dore {border-bottom: 2px solid #115BC9!important;border-top: 2px solid #115BC9!important;  text-align: center;}
.dropdown.address-book-select {border-radius: 20px;-webkit-border-radius: 20px;-ms-border-radius: 20px;-o-border-radius:20px;-spec-border-radius: 20px;font: italic 400 13px "Calibri";color:#050F3B;    border: 1px solid #E6E6E6; height:40px;background: #F5F4F5 url("../images/f_select.svg") no-repeat center right 0.625rem;padding:10px 0.938rem 8px 0.938rem;outline: none;}
.dropdown.address-book-select .dropdown-item.active, .dropdown.address-book-select .dropdown-item:active{background:#E6E6E6; color: #050F3B;font-weight: 700;}
.dropdown.address-book-select .dropdown-item{white-space: normal!important}
/*input:-internal-autofill-previewed, input:-internal-autofill-selected, textarea:-internal-autofill-previewed, textarea:-internal-autofill-selected, select:-internal-autofill-previewed, select:-internal-autofill-selected{background-color: #fafafa!important;}*/
.border-bottom-resume {border-bottom: 20px solid rgba(232, 232, 232, 0.7);}
.btn-chekout-rel i{position: absolute;right: 20px;top: 50%;transform: translate(0,-50%);}
/** CDE 3 **/
	.nosrayons-navigation-site >* {display: inline-block;font: 300 12px "Calibri";}

	.max-content>div {max-width: auto;margin-left: auto;margin-right: auto;white-space:nowrap}
	.hasp-m0>p{margin:0}
	.title-payment label.form-check-label {font: 400 17px "Calibri";}
	.opacity-0-abs{position:absolute; width:0;height: 0;color:#fff}

.shipping-item small {font-size: 0.813rem;font-family: "Calibri";font-weight: 400;}
div#map:empty {display: none;}
.descr-shipp {padding-left: 25px;} 
 
div#sylius-shipping-methods h5 {font: 500 0.938rem  "Calibri";}
.list-group-flush .border-top-1{border-top-width: 1px!important;}
.simple-border{border-right: 1px solid #C0C0CC;}
.flex-grow-height{min-height: 70px;display: flex;flex-direction: column;align-items: center;justify-content: center;}
.form-PR .form-control{height: 38px;border: none!important;padding-left: 15px; color: #000;font-size: 15px;}
.form-PR .btn-link{font-size: 18px; min-width: 60px;}
.img-shipping-max img {max-height: 120px;}
.px-summary-total{padding-left: 0.5rem; padding-right:0.5rem}
.item-content-ship .shipping-item label.form-check-label{background-size: 17px;}
@media only screen and (min-width: 1200px){
	.checkout-total-resume * {font-size: 80%;}
}
@media only screen and (min-width: 1280px){
	.panier-summary-bloc{width: 370px;    top: 108px;position: sticky;}
	.px-summary-total{padding-left: 20px!important; padding-right: 20px!important;}
	.px-xl-panier{padding-left: 2rem;padding-right: 2rem;}
	.body-sylius_shop_cart_summary, .body-sylius_shop_cart_summary .big-wrapper {overflow: inherit;} 
	.listes-articles.display-article-block .articleBit-ima {min-height: 250px;}
	.btn-panier-action .btn-link {padding-left: 5px;padding-right: 5px;font-size: 0.875rem;}
	.bit-details-tr .td-nowrap.pl-sm-3.pl-2 {width:300px}
}

@media only screen and (min-width: 1480px){
.panier-summary-bloc{width: 420px;}
.px-xl-panier{padding-left: 3rem;padding-right: 3rem;}
}
.sylius-product-variant-name {text-transform: lowercase;}
.sylius-product-variant-name::first-letter {text-transform: uppercase;}
/*=================================  
		FORMULAIRe
==================================*/
.form-perso-content .form-check {display: inline-block;padding-left: 0;}
	.form-perso-content .form-check-input ,.order-show .form-perso-content  .form-check-logo input , .form-perso-content.radio-label-input  input,.radio-label-input .position-relative>  input {width: 100%;margin: 0;opacity: 0;top: 0;height: 100%;cursor: pointer;position: absolute;}
	.form-perso-content .form-check-label, .order-show .form-perso-content .form-check-logo + label,.radio-label-input .form-check-label  {background: url("../images/check_t.png") no-repeat left center;padding-left:25px;line-height: 22px;background-size: 19px;}
	.form-perso-content.radio-label-input .form-check-label,.order-show .form-perso-content.radio-label-input .form-check-logo + label, .radio-label-input input + label.form-check-label {background-image: url("../images/radio_off.png")}
	.body-sylius_shop_order_show .form-perso-content.radio-label-input input[type="radio"] + label{background: url("../images/radio_off.png") no-repeat left center;padding-left:25px;line-height: 22px;}
		.form-perso-content .form-check-input:checked +  .form-check-label {background-image: url("../images/check_tok.png")}
		.form-perso-content.radio-label-input .form-check-input:checked +  .form-check-label,.form-perso-content.radio-label-input .form-check-input:checked +  .form-check-label , .order-show .form-perso-content.radio-label-input  input:checked + label , .radio-label-input input:checked + label.form-check-label,.body-sylius_shop_order_show .form-perso-content.radio-label-input input[type="radio"]:checked + label {background-image: url("../images/radio_on.png")}
		.contact_form .form-group>label {font-size: 0;height: 0;margin-bottom: 0;line-height: 0;display: block;}
		.contact_form  span.form-error-message  {font-size: 12px;position: absolute;bottom: -6px;right:0;width: 100%;text-align: right;}
		.perso-formgroup-content   .form-group, .form-perso-content   .form-group {position: relative;}
		.perso-formgroup-content span.invalid-feedback,.form-perso-content span.invalid-feedback  {position: absolute;right: 0;bottom: -17px;text-align: right;width: 100%;}
		/*.form-perso-content .form_contact  span.invalid-feedback {bottom: 0;}*/
		form#app_form_upload .form-group {margin-bottom: 35px;}
		.invalid-feedback{color: rgba(238,54,82,1)!important;}
		form#app_form_upload .form-group span.invalid-feedback {text-align: center;font-size: 15px;bottom: -33px;font-weight: 600;}
		.form-perso-content.radio-label-input .has-logo-choice .form-check-label , .body-sylius_shop_order_show .form-perso-content.radio-label-input .has-logo-choice input[type="radio"] + label{padding-left: 80px;}
		.shipping-item label.form-check-label.required, .order-show-page .pl-perso-checkout-sm .col-auto >label {font-size: 0;padding-left: 24px;}
		.pl-mode {padding-left: 25px;	}
		.form-check-logo .pl-mode {padding-left: 25px;font:300 13px "Calibri";}
		.img-logo-check {position: absolute;max-width: 50px;left: 22px;top: 50%;transform: translate(0, -50%);-webkit-transform: translate(0, -50%);-ms-transform: translate(0, -50%);-o-transform: translate(0, -50%);margin-top: -4px;border: 1px solid #e6e6e6;}
		.form-check-logo.has-logo-choice .pl-mode {padding-left: 80px;	}
		.form-check-logo.has-logo-choice {position: relative;margin-top: 8px;}
		.p-3.form-perso-content.perso-formgroup-content span.form-error-message {position: relative;bottom: 0;right: 0;}
		.moyen_details p {font-size: 12px;}
.logo-method img{max-width: 75px;}
	.border-bottom-1 {border-bottom: 1px solid #e6e6e6;}
	.checkout-adress-bloc  .form-group >label{font: 400 13px "Calibri"}
	@media only screen and (min-width: 1024px){
	.checkout-adress-bloc .card-body.form-perso-content.px-xl-4.px-3 .form-group {display: flex;align-items: center;    margin-bottom: 25px;}
	.checkout-adress-bloc .card-body.form-perso-content.px-xl-4.px-3 .form-group >label {flex: 0 0 100px;margin-bottom: 0;max-width: 100px;padding-right: 15px;text-align: right;}
	}
/*=================================
			PAGE INSTIT
===================================*/
.main-content >.ui.breadcrumb {margin: auto;max-width: 1300px;padding: 0.938rem;}
.main-content >.ui.breadcrumb + .ui.segment{margin: auto;max-width: 1300px;}
.instit-top-image{height: 18.75rem;}
.instit-has-bgimg{background-repeat: no-repeat;background-size: cover;background-position: center;}
.bitbag-page-content .container {position: relative;}
.centered {position: absolute;top: 50%;left: 50%;transform: translate(-50%, -50%);-webkit-transform: translate(-50%, -50%);-ms-transform: translate(-50%, -50%);-o-transform: translate(-50%, -50%);-spec-transform: translate(-50%, -50%);left:50%}
.bitbag-page-content .h1.centered{text-align: center;color:#050F3B;font:400 3.063rem "Calibri";}
div#accordion .card-header {cursor: pointer;}

.collapse.border-bottom {border: none!important;}
.image-instit-top {height: 250px;overflow: hidden;background-color: #e6e6e6;}
.page-instit-img img{max-width:100%; width:auto}
.page-instit-img .object-fit-img img{width: 100%!important;}
.titre-h2-instit h2,.titre-h2-instit h3{color: #050F3B;}
.instit-item-lib h3{margin-bottom: 0;padding: 0.625rem 0;font:  1rem "Calibri-Bold";text-transform: uppercase;color: #050F3B;}
.link-white a{color: #ffffff!important;}
@media only screen and (min-width: 767px){
	.max-img-i{max-width: 285px; width: 100%;}
	.alert.max-content {max-width: max-content;}
	}

	.paiementLigne {padding: 10px 0;border-bottom: 1px solid #dbdbdb;}
	.page-cms h3{color:#115BC9}

/*=================================
			CLIENT 
===================================*/
.pdf-kbs object{border: 1px solid #e6e6e6;}
body[id^="body-sylius_shop_account"] .big-wrapper , body[id^="body-sylius_shop_account_dashboard"] .big-wrapper {background-color: #F2F2F2}
.title-compte-client {position: relative;}
.title-med-account {font:2.625rem "Aller-Bold"}
.title-compte-client .title-medium {padding: 2.188rem 0  0.625rem 0;margin-bottom: 0.938rem;font: 2rem "Calibri-Bold";text-align: left!important;}
	.title-menu-account {padding-left: 20px;padding-top: 2.188rem;padding-bottom: 20px;}
.title-menu-account .title-medium{font-weight: 700; font-size: 2rem;}
.login-list {padding-left: 0;    margin-top: 23px;}
.login-list-item {background: url("../images/list_style.svg") no-repeat center left;list-style: none;padding: 8px 0 8px 30px;color: #393A3D;}
	.login-list-item strong{color: #115BC9;}

.icone-inline {display:inline-block;width: 60px;text-align: center;background: #050F3B;color: #fff;text-transform: uppercase;height: 60px;vertical-align: middle;font-size: 0;}
.icone-inline span {font-size: 0;display: inline-block;line-height: 0;text-align: center;letter-spacing: 0;width: 20px;}
.icone-inline span:first-letter {font: 400 30px "Calibri";line-height: 60px;}
.nom-user {display: inline-block;padding-left: 0.625rem;}
.nom-user strong {font: 400 25px "Calibri";display: block;padding-top: 6px;word-wrap: break-word;line-height: 1.5rem;text-transform: capitalize;}
.image-bienvenu img {position: absolute;left: 0;top: 0; width: 100%;height: 100%;object-fit: cover;-ms-object-fit: cover;-webkit-object-fit: cover;}
.image-bienvenu h1 {position: absolute;top: 50%;left: 0.938rem;transform: translate(0, -50%);-webkit-transform: translate(0, -50%);-ms-transform: translate(0, -50%);-o-transform: translate(0, -50%);-spec-transform: translate(0, -50%);z-index: 9;text-transform: uppercase;margin: 0;}
.image-bienvenu h1 >span{display:block;    margin-top: 5px;}
	.txt-bienvenu {background-color: #115BC9;color: #fff;text-transform: uppercase;font: 500 1.5rem "Calibri";line-height: 2;padding: 8px 12px;display:inline-block}
	.image-bienvenu {position: relative;height: 100%;display: table;width: 100%;}

	.list-item-bit {background: #fff;}
		.list-item-bit a {font-size:0.938rem;color: #050F3B;padding: 1rem 20px 1rem 60px;line-height: 2.4rem;position: relative;	}
		.lien-home  {background-image: url("../images/ico_paie.svg");background-repeat: no-repeat;background-position: 18px 50%;}
		.lien-user {background-image: url("../images/ico_info.svg");background-repeat: no-repeat;background-position: 18px 50%;}
		/*.lien-star {background-image: url("../images/ico_infoPro.svg");background-repeat: no-repeat;background-position: 18px 50%;}*/
		.lien-lock {background-image: url("../images/ico_mdp.svg");background-repeat: no-repeat;background-position: 20px 50%;}
		.lien-book {background-image: url("../images/ico_adresse.svg");background-repeat: no-repeat;background-position: 20px 50%;}
		.lien-cart {background-image: url("../images/cmd.svg");background-repeat: no-repeat;background-position: 20px 50%;}
		.lien-save {background-image: url("../images/ico_savepanier.svg");background-repeat: no-repeat;background-position: 15px 50%;background-size: 30px;}
		.lien-mail {background-image: url("../images/ico_mail.svg");background-repeat: no-repeat;background-position:0.938rem 50%;background-size: 26px;}


		.lien-loggout {background-image:url('../images/ico_deconnex.svg');background-repeat: no-repeat;background-position: 20px 50%;color:#D10000!important; font-family: "Calibri"; font-weight: 400;}
		.list-item-bit a::after {content: "";bottom: 0;left: 60px;width: calc(100% - 75px);border-top: 1px solid #9EA6AD;position: absolute;}
		.list-item-bit:last-child  a::after{display: none;}
		.list-group-item.active-menu::before {border-right: 4px solid #115BC9;bottom: 0;content: "";left: 0;position: absolute;top: 0;}
		.list-group-item.active-menu {background-color: #f2f2f2;font-weight: bold;}
		.form-perso-content select.form-control{border-radius: 20px;-webkit-border-radius: 20px;-ms-border-radius: 20px;-o-border-radius:20px;-spec-border-radius: 20px;font: italic 400 13px "Calibri";color:#050F3B;    border: 1px solid #E6E6E6; height:40px;background: #F5F4F5 url("../images/f_select.svg") no-repeat center right 0.625rem;padding: 5px 0.938rem;}
		.form-perso-content  .champform select{border-radius: 20px;-webkit-border-radius: 20px;-ms-border-radius: 20px;-o-border-radius:20px;-spec-border-radius: 20px;font: italic 400 13px "Calibri";color:#050F3B;    border: 1px solid #E6E6E6; height:40px;background: #F5F4F5 url("../images/f_select.svg") no-repeat center right 0.625rem;padding: 5px 0.938rem;}
		section.big-wrapper>.bloc-show-administrable {margin-top: 0;}

	.menu-left .list-group-item:hover, .menu-left .list-group-item:focus{background-color: #f2f2f2;}
		/*******ADRESSE*****/
		.list-adress .col-lg-6 {border-right: 1px solid #ccc!important;}

		.sylius_shop_account_dashboard + .col-12 .bg-white.menu-right.p-lg-4 {padding: 0!important;}
		a.mr-1.btn.btn-link.btn-br.rounded-0.border-0.text-uppercase.text-white {line-height: 36px;}
		.list-adress .btn-icone {    width: 45px;margin-left: 5px;height: 45px;line-height: 40px;padding: 0;}
			.list-adress  .btn-icone.btn-link svg {vertical-align: middle;}

			.list-adress .btn.btn-icone.btn-link {font-size: 1rem;line-height: 39px;padding-top: 0;}

		.bg-red{background-color:#E00B20!important;}
.text-white2{color: #6D8AB5;}
		.badge-danger{background-color:#E00B20!important;}
		.list-adress  .btn-icone.btn-link.bg-black:hover, .list-adress  .btn-icone.btn-link.bg-black:focus{background-color: #333;}
		.list-adress  .btn-icone.btn-link.bg-red:hover, .list-adress  .btn-icone.btn-link.bg-red:focus{background-color: #115BC9!important;}
	.retour-cl{width: 30px;height: 30px;background:#2DB8E8  url("../images/retours.svg") no-repeat center;position: absolute;left:0.25rem;    top: 50%;transform: translate(0, -50%);-webkit-transform: translate(0, -50%);-ms-transform: translate(0, -50%);-o-transform: translate(0, -50%);-spec-transform: translate(0, -50%);    background-size: 20px;
		border-radius: 5px;    margin-top: 0;}
.rounded-15{border-radius:15px}
	/**********HISTORIQUE COMMANDE*********/
	.action-table-perso{max-width: 12.188rem;}
	.action-table-perso .btn-link {font-size: 0.75rem;padding-left: 0.375rem;padding-right: 0.375rem;} 
	.badge.bg-pink-site.text-white * {color: #fff!important;}
	table#sylius-checkout-subtotal, #sylius-savecart-subtotal {color: #050F3B;}
    .content-bottom-order .table-perso .card-header{background-color: #050F3B!important;font-size: 0.813rem;}
    .content-bottom-order .table-perso .card-header th{font-size: 0.813rem;padding-left: 8px;padding-right: 8px;white-space: pre;border: 0;}
    .content-bottom-order .table-perso .card-header td{padding-left: 8px;padding-right: 8px;border: 0;   }
	.content-bottom-order #sylius-order.table tbody  td {padding-left: 8px;padding-right: 8px;border: 0;    border-bottom: 1px solid #DBD2DD!important;}
      .content-bottom-order .table-perso .product-name,#sylius-cart-items.table-perso .product-name {font-family: "Calibri";font-size: 20px;font-weight: 400;}
      .content-bottom-order .table-perso table {font-size: 0.813rem;}
	  .content-bottom-order .table-perso .sylius-total{font-size: 0.813rem;}
      .content-bottom-order .table-perso  .total-cart-summ{font-size: 0.938rem;}
       .content-bottom-order .table-perso .total-label{font-size: 0.938rem;}
    #order-invoices  h3#shipping-state{padding:0 0  0.938rem 0;border-bottom: 1px solid #115BC9;margin-top:0;margin-bottom: 0.938rem;color:#050F3B;font: 2rem "Calibri-Bold";line-height: 120%;} 

      #order-invoices table th {font: 400 0.813rem "Calibri";background-color: #115BC9;color:#fff ; padding-top: 6px; padding-bottom: 6px;}
     #order-invoices table{font-size: 0.813rem; color:#115BC9;}
     #order-invoices table a {color: #115BC9;}
	 table.table.table-perso.table-histo-account .sylius-table-column-state + td {padding-top: 8px;padding-bottom: 8px;}
     #order-invoices table a:hover{text-decoration: underline!important;}
        #order-invoices table a i.icon.download {width: 30px;display: inline-block;height: 23px;background: url("../images/ico_download.png") no-repeat center;vertical-align: middle;}
		.btn-down-pdf{display: inline-block;background: url("../images/ico_download.png") no-repeat center left; padding:0.625rem 0 0.625rem 30px; font:400 0.813rem "Calibri";color:#115BC9}
		.table-histo-account {font-size: 13px;}
		table#sylius-checkout-subtotal  td  {font-size: 1rem;padding-top: 10px;padding-bottom: 10px;}
		.save-cart-bloc div.buttons .button {background: #115BC9;display: inline-block;text-align: center;color: #fff;padding: 0.5rem 0.375rem;font: 500 0.8rem "Calibri";text-transform: none;border-radius: 0;-webkit-border-radius: 0;-ms-border-radius: 0;-o-border-radius: 0;position: relative;transition: all 0.3s;-webkit-transition: all 0.3s;-moz-transition: all 0.3s;-o-transition: all 0.3s;-ms-transition: all 0.3s;text-align: center;border:none;vertical-align: middle;}
		.save-cart-bloc div.buttons>form{display: inline-block;vertical-align: middle;}
		.save-cart-bloc div.buttons button.button{background-color: #E00B20;}
@media only screen and (min-width: 768px){
	.list-adress .col-lg-6:nth-child(2n) , .list-adress .col-lg-6:last-child {border: none!important;}

}
@media only screen and (min-width: 576px){

	.action-table-perso {width: max-content;max-width: max-content;}
	.save-cart-bloc div.buttons {width: max-content;max-width: max-content;}
}
.btn-abs-table{    position: absolute;right: 0;top: 0;}
/********contaznct***********/
.contact-footer-instit{background: #f2f2f2;background-attachment: fixed;background-size: cover;padding: 30px 0.938rem; text-align:center;}
.contact-container{max-width: 1000px;width:100%; margin:auto}
.contact_form {margin: 25px auto;}

 .form-perso-content .contact_form .form-control{padding-left: 2.188rem;background-color: #ffffff;}
 .contact_form .pi-input-icon i:before{z-index: 2;left: 10px;top:50%; transform: translate(0,-50%);}
		.form-perso-content  .contact_form textarea.form-control{min-height: 100px;}
		.contact_form .icon-users::before {content: url('../images/ico_user.png');position: absolute;}
		.contact_form .icon-mail::before {content: url('../images/ico_email.png');position: absolute;}
		.contact_form .icon-phone::before {content: url('../images/ico_tel.png');position: absolute;left:9px}
		.contact_form .icon-home::before {content: url('../images/ico_home.png');position: absolute;}
		.contact_form .icon-pencil::before {content: url('../images/ico_pencil.png');position: absolute;top: 0.625rem!important ;transform: none!important; -webkit-transform: none!important; -ms-transform: none!important; -o-transform: none!important;-spec-transform: none!important;}
		.form-perso-content .custom-file {height:40px;border:none}
			.form-perso-content  .custom-file-label{background: #fff;height: 40px;padding-right: 160px;padding-left: 20px; font:400 15px "Calibri"; padding-top: 0.5rem;border:1px solid #E6E6E6;  border-radius: 20px;-webkit-border-radius: 20px;-ms-border-radius: 20px;-o-border-radius:20px;-spec-border-radius: 20px;white-space: nowrap;display: block;text-overflow: ellipsis;overflow:hidden;color: #2E80C3;}
			.form-perso-content .custom-file-input:focus ~ .custom-file-label{box-shadow: none!important;-ms-box-shadow: none!important;-webkit-box-shadow: none!important;}
			.form-perso-content .contact_form  .custom-file-label{background-color: rgba(255,255,255,1);}
				.form-perso-content .custom-file-label::after {background: #115BC9   url('../images/ico_down.svg') no-repeat center left 25px;height: 100%;content: "Parcourir";min-width: 170px;text-align: center;cursor: pointer;font:500 13px "Calibri"; line-height:27px;border:0;    border-radius: 20px;-webkit-border-radius: 20px;-ms-border-radius: 20px;-o-border-radius:20px;-spec-border-radius: 20px;color: #ffffff;    padding-left: 38px;text-transform: uppercase;}
				.form-perso-content .custom-file-label:hover::after{background-color:#468cf7 !important}
				html[lang="en"] .form-perso-content .custom-file-label::after {content: "Browse"}
				.form-perso-content	.custom-file-input{cursor: pointer;height: 100%}
				.form_contact .MultiFile-wrap  .custom-file-input{height: 40px;}
				#modal-addProduct-name span {display: block;overflow-wrap: break-word;}
				.custom-file.file-cart {max-width: 610px;height: 42px}	
				.form-perso-content  .custom-file.file-cart .custom-file-label{height: 42px;border-color: #E6E6E6;background-color: #F5F4F5;}
/**********FAQ*****************/
.faq-header.open h3{color: #115BC9;}
.faq-header::after {content: '\e8ca';display: block;position: absolute;right: 0.938rem;top: 50%;font-family: "fontello";font-size: 17px;transform: translate(0,-50%);-webkit-transform : translate(0, -50%);-ms-transform: translate(0, -50%);-o-transform: translate(0, -50%);-spec-transform: translate(0, -50%);font-weight: 700;color: #fff;}
.faq-header.open::after{color: #fff;content: '\e8cd';}
#bitbag-faqs .open .icon-down-open-big:before{content: '\e8cd';}
#bitbag-faqs .open .bitbag-question{color: #ffffff!important;}
#bitbag-faqs .open{border-color: #115BC9;}

/*=============================
		FILE
===============================*/
.text-link-color a{color: #115BC9; text-decoration: underline;}
.custom-file-btn .custom-file-input {height: 100%;cursor: pointer;}
.custom-file-btn .custom-file-input {position: relative;z-index: 2;width: 100%;height: calc(1.7em + 0.75rem + 2px);margin: 0;opacity: 0;}
.custom-file-btn .custom-file-label {background: #050F3B;text-align: center;font:300 18px "Calibri";padding: 12px 30px;color:#F2F2F2;border-radius: 0;-webkit-border-radius: 0;-ms-border-radius: 0;-o-border-radius: 0;-spec-border-radius: 0;border: none;cursor: pointer;    display: block;position: relative;height: auto;max-width: 460px;margin: auto; }
.custom-file-btn  .custom-file-label::after{display: none;}
.font-italic{font-family: "Calibri"; font-weight: 300; font-style: italic;}
.font-italic-regular{font-family: "Calibri"; font-weight: 400; font-style: italic;}
	.progress-content .progress {height: 7px;border: 1px solid #ccc;background: #fff;max-width: 200px;margin: auto;}
	.progress-content {padding-top: 27px;text-align: center;}
.bg-black{background-color: #050F3B}
.title-configbit{font :300 2.188rem "Calibri";padding-bottom: 20px}
div#sylius_contact_attachment_list {display: flex;display:-ms-flex;margin-top: 0.625rem;}
div.MultiFile-label {   padding: 5px 0.938rem 5px;background: #115BC9;margin-right: 0.938rem;color: #fff;}
a.MultiFile-remove {font-weight: bold;vertical-align: middle;display: inline-block;font-size: 0.813rem;color: #fff}
div.MultiFile-label * {line-height: normal;vertical-align: text-top;}

.btn_print_4 input{max-width: 200px;margin-left: auto;margin-right: auto;border: none;}

h2.ui.center.aligned.icon.header {text-align: center;}
.sortable a:hover {color: rgba(255,255,255,0.5);}
body:not(.modal-open) .modal-backdrop.show {opacity: 0!important;display: none!important;}
.commun-logging + .image-instit-top{display: none;}
.commun-logging + .image-instit-top + .page-client-site,.commun-logging + .image-instit-top + .erreur + .page-client-site  {display: none;}

.ajax_loader{width:100%;height:58px;background:url('../images/loader.gif') no-repeat center top;display: none;}

/*=============================
		OTHER
===============================*/

.titre-regular-italic {font:400 italic 22px "Calibri";}
.step-project-container {max-width: 420px;margin: 15px auto;text-align: center;}
.step-number {position: relative;}
.step-number span {display: inline-block;width: 30px;height: 30px;background: #747d88;border-radius: 100%;font: 19px "Calibri-Bold";color: #fff;padding-top: 0px;position: relative;z-index: 9;}
.step-number.active-step span,.step-number.completed-step span {background-color: #115BC9;}
.step-number::after {content: "";width: 100%;height: 1px;position: absolute;left: -50%;top: 50%;background: #747d88;}
.step-number.active-step::after{background-color: #115BC9;}
.step-number.step-number-1::after {width: 0%;left: 50%;}
.step-number.step-number-4::after {width: 100%;}
.bg-form-step {margin: 20px auto 40px auto;background: rgba(255,255,255,0.8);padding: 70px 30px 60px 30px;border-radius: 25px;}
.form-content-step.direct-flex {display: flex;display: -ms-flex;flex-wrap: wrap;align-items: center;}
.form-content-step.direct-notflex>div {display: flex;flex-wrap: wrap;align-items: center;}
.form-content-step.direct-notflex>div label {flex: 0 0 50%;-ms-flex: 0 0 50%;text-align: right;padding-right: 27px;}
.form-content-step label {font:  20px "Calibri-Bold";}
.form-label-content{flex: 0 0 50%;-ms-flex: 0 0 50%;text-align: right;padding-right: 27px;}

.step-bit-form {display: block!important;position: relative;}
.d-none.step-bit-form {opacity: 0.5;}
.d-none.step-bit-form::after {width: 100%;height: 100%;position: absolute;left: 0;top: 0;cursor: not-allowed;content: "";}
.form-content-step {padding: 20px 0;}
.form-content-step.direct-notflex >div>input {width: 50%;max-width: 260px;height: 60px;border: 1px solid #afafaf;padding: 10px 20px;font: 400 20px "Calibri";color: #115BC9;background: #fff;margin-left: 15px;}


.form-choice-content{flex: 0 0 50%;-ms-flex: 0 0 50%;display: flex;display: -ms-flex;max-width: 350px;}
.max-choice-100{max-width: 100%;justify-content: center;flex-wrap: wrap;}
.form-choice-content>div {margin: 0 15px;text-align: center;width: 50%;}
.form-choice-content.max-choice-100>div{width: auto;min-width: 12%; margin-bottom: 15px;}

.form-choice-content.max-choice-w {max-width: 50%;flex-wrap: wrap;-ms-flex-wrap: wrap;}
.form-choice-content.max-choice-w>div{width: 25%; margin-bottom: 15px;}

.form-choice-content input[type="radio"] {position: absolute;opacity: 0;height: 100%;cursor: pointer;left: 0; width: 100%;top: 0;}
.form-choice-content input + label{font:400 19px "Calibri";color: #666666;text-align: center;position: relative;    display: block;word-break: break-word;}
.form-choice-content  input + label:before {width: 105px;height: 105px;content: "";border: 2px solid #666666;display: block;border-radius: 10px;-webkit-border-radius: 10px;-ms-border-radius: 10px;-o-border-radius: 10px;margin:0 auto 10px auto;cursor: pointer;background-color: #ffffff;background-repeat: no-repeat;background-position: center top;}
#roof-orientation .form-choice-content  input + label:before{border-radius: 100%;}

.radio-image-input .form-choice-content input + label:before {display: none;}

.radio-image-input.form-content-step>.form-choice-content  label {padding-right: 0;padding-left: 50px;min-height: 38px;line-height: 35px;cursor: pointer;}
.form-content-step.radio-image-input .position-relative {display: inline-block;width: auto;}

 .form-choice-content  input + label::after{display: none;}
.form-choice-content input[type="radio"]:checked + label{color: #115BC9;}
.form-choice-content input[type="radio"]:checked + label:before{background-position:center bottom!important;background-color:#115BC9 ;border-color: #115BC9;}
.width-100-max{width: 100%;max-width: 340px;margin: auto;}
.py-p-step {padding-top: 20px;padding-bottom: 20px;}

.btn-back-step {background: #666666;}
.btn-back-step:hover, .btn-back-step:focus {background: #6F7375;}

.message-ui .message{color: #050F3B!important;}

.hidden-step-1 {display: none!important;}
.bg-form-step div .d-none.btn-link {display: block!important;opacity: 0.3;pointer-events: none;cursor: not-allowed;background: #ccc!important;}



/************COOKIES*********/
body .ch-cookie-consent__btn-group >div {width: 50%;}
body .ch-cookie-consent--dark-theme .ch-cookie-consent__category-toggle input + label{border-radius: 5px!important;-webkit-border-radius: 5px!important;-ms-border-radius: 5px!important;-o-border-radius: 5px!important;-spec-border-radius: 5px!important;margin-bottom: 0;border:none!important; background-color: #050F3B;color: #fff!important;}
body  .ch-cookie-consent--dark-theme .ch-cookie-consent__category-toggle input+label:hover{ background-color: #050F3B;}
 body .ch-cookie-consent__category-toggle input:checked+label{background:#F48211!important;color:#ffffff!important}
 .ch-cookie-consent h4 {font-size: 1rem;}
body .ch-cookie-consent__category-title{color: #050F3B!important;}
 .ch-cookie-consent__category{padding: 10px 0;align-items: center;-webkit-align-items: center;}
.ch-cookie-consent__category-group {margin-left: auto; margin-right: auto;max-width: 600px;}
.ch-cookie-consent__toggle-details span:hover{text-decoration: none;}
body .ch-cookie-consent__title {color: #fff;text-align: center;font-size: 14px;}
#modalCookies2 {position: fixed;bottom: 0;width: 100%;padding:15px;background:#050F3B;z-index: 9999;color: #fff;text-align: center;display: none;}
.ch-cookie-consent__toggle-details-hide {display: none;}
.ch-cookie-consent__category-group {display: none;    position: fixed;width: 100%;max-width: 400px;transform: translate(-50%, -50%);-webkit-transform: translate(-50%, -50%);-ms-transform: translate(-50%, -50%);-o-transform: translate(-50%, -50%);padding: 15px;background: #fff;z-index: 99;top: 50%;left: 50%;z-index: 99;}
button#cookie_consent_use_all_cookies{    justify-content: center;-ms-justify-content: center;margin-top: 0;}
button.close-me {position: absolute;right: -14px;border: none;background:#115BC9;border-radius: 100%;-webkit-border-radius: 100%;-ms-border-radius: 100%;-o-border-radius: 100%;-spec-border-radius: 100%;font-size: 20px;font-weight: bold;color: #fff;padding: 0;width: 30px;height: 30px;line-height: 5px;top: -11px;text-align: center;}
button#cookie_consent_use_only_functional_cookies {background: none;color: #fff!important;min-width: auto;padding: 0;text-decoration: underline;margin-left: 18px; border: none;margin-top: 0; justify-content: center;-ms-justify-content: center;width: max-content!important;}
body .ch-cookie-consent{background: #050F3B!important;padding: 1rem;}
body .loading-overlay {position: fixed;background: rgba(0, 0, 0, 0.3);display: none;width: 100%;height: 100%;left: 0;top: 0;z-index: 98;}
body .ch-cookie-consent .btn{background:#115BC9;color:#fff!important; text-align:center;  width: max-content;margin-top:0; border-radius: 5px!important; -webkit-border-radius: 5px!important; -ms-border-radius: 5px!important;-o-border-radius: 5px!important;-spec-border-radius: 0;padding: 5px 1rem; border:none }
.loading-overlay-c {position: fixed;top: 0;left: 0;z-index: 9;display: none;width: 100%;height: 100%;background: rgba(0, 0, 0, 0.4);}
.ch-cookie-consent__toggle-details-grp> span {background: #F48211;padding: 7px 20px;color: #fff;border-radius: 5px!important; -webkit-border-radius: 5px!important; -ms-border-radius: 5px!important;-o-border-radius: 5px!important;display: block;text-align: center;}
.ch-cookie-consent__category:first-child {border-top: none;}
.close-cookies{background:#fff url("../images/close_popup.png") no-repeat center;font-size: 0;padding: 15px; position: absolute;right:0 ; top:0}
.ch-cookie-consent__btn-wrapper {margin-bottom: 0!important;}
.ch-cookie-consent__form{vertical-align: middle}
.ch-cookie-consent__btn-group {position: relative;z-index: 99;}
.ch-cookie-consent__category-information {text-align: left;}
.ch-cookie-consent__form {text-align: center;}
.ch-cookie-consent__category-group[style="display: block;"] + .loading-overlay {display: block;}
@media only screen and (min-width: 576px){
	.ch-cookie-consent__form {display: flex;-ms-display: flex;-o-display: flex;align-items: center;-webkit-align-items: center;-moz-align-items: center;-ms-align-items: center;-o-align-items: center;justify-content: center;;-webkit-box-pack: center;-ms-flex-pack: center;margin-top: 20px!important;}
}
@media only screen and (max-width: 640px){
	.ch-cookie-consent__category-information{text-align: center;}
}

@media only screen and (max-width: 575px){
	button#cookie_consent_use_only_functional_cookies{margin-left: 0; margin-top: 8px;}
	.ch-cookie-consent__btn-wrapper{padding-right: 10px;}
	body .ch-cookie-consent .btn{width: 100%;max-width: 250px;}
	.ch-cookie-consent__category{padding-top: 0.8rem; padding-bottom: 0.8rem;}
	.ch-cookie-consent__category-group {max-height: 64vh;overflow-y: auto;overflow-x: hidden;}
}
.error-notfound{font: 500 120px "Calibri"; color: #2DB8E8;margin-top: 40px;}
@media only screen and (max-width:991px) {
	.error-notfound{font-size: 100px	}
}
@media only screen and (max-width:575px) {
	.error-notfound{font-size: 80px	; margin-top: 10px;}
}
h2.ui.center.aligned.icon.header {text-align: center;}
.sortable a:hover {color: rgba(255,255,255,0.5);}
body:not(.modal-open) .modal-backdrop.show {opacity: 0!important;display: none!important;}
.ch-cookie-consent__category-group[style="display: block;"] + .loading-overlay {display: block;}
/************COOKIES*********/
.ch-cookie-consent__read-more { display:none!important; }
.ch-cookie-consent__intro { display:none!important; }
#cookie_consent_use_all_cookies { display:none!important; }
.ch-cookie-consent--simplified .ch-cookie-consent__category-toggle { display:block!important; }
.ch-cookie-consent__toggle-details-grp { cursor: pointer!important; }
.ch-cookie-consent__btn_no_bg { background:none!important; }
.hidden-important{display: none!important;}
/*****stock not available*******/

/*******savecart********/

/*****stock not available*******/
.form-contact-art label {display: block;}

.btn-link.btn-contact-link {/*position: absolute;right: 16px;bottom: 26px;font-size: 12px;min-width: 105px; border: 0;*/margin-top:10px; display: block;border:0}
.special-cmd .btn-link.btn-contact-link {position: absolute;right: 20px;bottom:34px;font-size: 12px;min-width: 105px; border: 0;margin-top: 0;}
.form-contact-art>div>input {border-radius: 20px;-webkit-border-radius: 20px;-ms-border-radius: 20px;-o-border-radius: 20px;-spec-border-radius: 20px;font: italic 400 13px "Calibri";color: #050F3B;border: 1px solid #E6E6E6;background-color: #fff;height: 40px;padding: 0 15px;width: 100%;}
.app_availability_notifier_quantity{max-width: 105px;}
div#sylius-product-out-of-stock form>.form-contact-art>div{padding-bottom: 5px;}
.height-btn-popup{height: 53px;}
.page-complete .step-panier{display: none!important;}
.page-complete  .card-header{background-color: #115BC9; color: #fff;}
.page-complete table#sylius-checkout-subtotal td{background-color: #fff;    border-bottom: 1px solid #e6e6e6;}
.body-sylius_shop_order_show h1.font-aller-bold{color: #fff;padding: 8px 0.75rem;font: 300 0.813rem "Calibri"!important;background-color: #115BC9;text-align: left;padding: 8px 0.75rem!important;}

.body-app_shop_brand_index .box-sscateg{background-color: #115BC9; color: #fff;}
.body-app_shop_brand_index .box-sscateg:hover{background-color: #629df5; color: #fff;}
.body-app_shop_brand_index .box-sscateg-title {font:  1.438rem "Calibri-Bold";} 
/*====================== 
AUTOCOMPLETE SEARCH
=====================*/
 
.show-instant-result .autocomplete-results{visibility: visible;}
/*BEGIN INSTANT SEARCH*/
.searchTop {float: right;max-width: 150px;border: 1px solid #7A7A7A;height: 34px;position: relative;width: 100%;margin-top: 15px;position: relative;margin-right: 25px}
.autocomplete-results .selectfitre-bit .bootstrap-select > .dropdown-toggle {display: block;max-width: 100%;height: auto;position: relative;padding: 10px 20px;text-align: left;font: 12px "Calibri";text-transform: uppercase;border-bottom: none;    background: #1a1a1a!important;color: #fff!important;border-radius: 0!important;}

.autocomplete-results .filter-values.dropdown-menu {display: block!important;position: relative;border: none;padding-bottom: 40px;}
.autocomplete-results .selectfitre-bit .bootstrap-select > .dropdown-toggle::after {display: none;}
.autocomplete-results .filter-values.dropdown-menu {display: block!important;position: relative;border: none;padding-bottom: 40px;}
.autocomplete-results  .selectfitre-bit input[type="checkbox"] {position: absolute;width: 100%;height: 100%;cursor: pointer;opacity: 0;} 
.autocomplete-results  .selectfitre-bit input[type="checkbox"]:hover + label {background-color: #F2F2F2;color: #000;}
.autocomplete-results  .selectfitre-bit input[type="checkbox"]:checked + label {background-color: #D10000!important;color: #fff;font-weight: 700;}
.autocomplete-results  .selectfitre-bit input[type="checkbox"] + label {display: block;margin-bottom: 0;padding: .25rem 1rem;font-size: 0.8rem;color: #212529;}
.autocomplete-results  .selectfitre-bit .bootstrap-select input[type="checkbox"]:checked + label .bs-ok-default::after {content: '';display: block;width: .5em;height: 1em;border-style: solid;border-width: 0 .26em .26em 0;-webkit-transform-style: preserve-3d;transform-style: preserve-3d;-webkit-transform: rotate(45deg);-ms-transform: rotate(45deg);-o-transform: rotate(45deg);transform: rotate(45deg);}

.autocomplete-results .selectfitre-bit .bootstrap-select.show-tick .dropdown-menu input[type="checkbox"]:checked + label .check-mark {position: absolute;display: inline-block;right: 15px;top: 7px;}
.autocomplete-results .btn-delete-filter button {border: none;background: none;margin-bottom: 15px;margin-top: 15px;padding: 0;text-decoration: underline;color: #D10000;}
.autocomplete-results .sticky-div>.filter-value.instant-search {display: inline-block;background: #D10000;   color: #fff;padding: 5px 8px 4px 8px;text-align: center;font-size: 12px;font-weight: 700;}
.autocomplete-results  .sticky-div {position: sticky;height: calc(100% - 65px);overflow-y: auto;overflow-x: hidden;}
.autocomplete-results  .selectfitre-bit .checkbox {position: relative;}

#searchcomplete {display: none;background: #fff;border: 1px solid #e6e6e6;position: absolute;right: -1px;top: 32px;width: 100%;min-height: 100px;z-index: 99;max-height: 85vh;;overflow: scroll}
#searchcomplete_container {max-height:  100vh;position: relative;width: 100%;margin: 0;}
.searchcomplete_categs {float: left;padding:0;flex: 1.2 1 0;border-right: solid 2px #1a1a1a;height: 100%;background-color: rgba(205,205,209,0.5);}
.searchcomplete_categs_titre {border-bottom: 1px solid #D10000;padding: 0 0 5px;font: 15px "Aller-Bold";}
.searchcomplete_categs > ul {padding: 10px 10px 0 0;}
.searchcomplete_categs a {color: #000;font-size: 13px;text-transform: uppercase;}
.searchcomplete_categs a:hover {font-family: proximanova_bold;}
.searchcomplete_products {width: 100%;padding: 0 10px;height: 100%;margin-top: 0;}
.filter-value.instant-search {padding: 0;}
.noresult {font-size: 12px;color: #B4B4B4;} 
.filter-values.dropdown-menu {max-height: 300px;overflow: auto;}
.searchcomplete_products .rowperso{max-height: 400px;}
.searchcomplete_products  article.articleBit {margin-bottom: 10px;}
/*.openable:not(.opened) .filter-value:nth-child(n+5) {display: none;}
.openable:not(.opened) .btn-see-less{display: none;}
.opened .btn-see-more{display: none;}
.opened .btn-see-less{display: block;}*/
.btn-click{cursor: pointer;    position: absolute;bottom: 9px;left: 22px;}
form.category.autocomplete-search {position: relative;}
.rowperso {height: 100%;overflow-y: auto;overflow-x: hidden; padding:5px 0  25px 0;}
.content-left-s {height: 100%;display: none;}

.menu.mbiz-sylius-search-filters {height: 100%;}
.sticky-result-head.row.justify-content-between.align-items-center {background: rgba(205,205,209,0.5);padding:12px 15px;position: sticky;}
.header-perso-search {position: sticky;background: #115BC9 ;padding:10px 5px;text-align: center;color: #fff;font: 15px "Calibri-Bold";text-transform: uppercase;}
button#searchClose {background: #FF9739;color: #fff;min-width: 100px;padding: 10px;font:500 12px "Calibri";border: none;}
button#searchClose:hover {background: #FF9739;}
.autocomplete-results { z-index: 99;position: absolute;left: 0;top: 100%;overflow: hidden;    box-shadow: 0px 2px 3px 0px rgba(155,155,155,0.71);-webkit-box-shadow:0px 2px 3px 0px rgba(155,155,155,0.71);-moz-box-shadow:0px 2px 3px 0px rgba(155,155,155,0.71);background-color: #fff;width: 100%!important;padding: 0!important;display: none;visibility: hidden;border-radius:8px;margin-top: 0;}

.autocomplete-results-cart { z-index: 99;position: absolute;left: 0;top: 100%;overflow: hidden;    box-shadow: 0px 2px 3px 0px rgba(155,155,155,0.71);-webkit-box-shadow:0px 2px 3px 0px rgba(155,155,155,0.71);-moz-box-shadow:0px 2px 3px 0px rgba(155,155,155,0.71);background-color: #fff;width: 100%!important;padding: 0!important;border-radius: 0 0 8px 8px;margin-top: -6px;}
.autocomplete-results-cart:empty{display: none;visibility: hidden;}
@media only screen and (min-width: 993px) {

}
@media only screen and (min-width: 1025px) {
	.show-instant-result.header-body::after {display: block;position: fixed;width: 100%;z-index: 97;background: rgba(0,0,0,0.5);left: 0;content: "";top: 0;height: 100%;}
	.show-instant-result  .header-sticky{position: relative;z-index: 99;background: #fff;}
	.show-instant-result .top-header{position: relative;z-index: 99;}
	.headerFixed .search-bit-result  .qte-panier.sylius-quantity-bit .form-control {height: 43px; }
.headerFixed .search-bit-result  .articleBit-add-cart, .headerFixed  .search-bit-result  .articleBit-ctc-cart{height: 45px;width: 45px;}

}
#monsieurbiz_searchplugin_search_query:focus {outline: 0;border-color: #000;}
.search-bit-result:nth-child(odd) .articleBit {background-color: #F2F2F2;}
.search-bit-result .articleBit {max-width: 100%;box-shadow: none;border-bottom:1px solid #C0C0CC; border-radius: 0;}
.search-bit-result .articleBit-price {display: flex;align-items: center;margin-top: 10px;font-size: 14px;}
.search-bit-result  .price-promo{font-size: 18px;}
.search-bit-result .articleBit-lib a{font-size: 15px;white-space: nowrap;}
.search-bit-result .code-list .max-code{font-size: 14px;}
.search-bit-result  .price-promo-ht {margin-right: 10px;}
.search-bit-result .qte-panier.sylius-quantity-bit {border: 1px solid #5B646D;}
.search-bit-result .articleBit-stock{text-align: center;color: #96C136;}
.search-bit-result  .articleBit-add-cart,.search-bit-result  .articleBit-ctc-cart{width: 100px;}
.code-list .max-code {line-height: normal;max-width: none;font-size: 0.875rem;font-weight: normal;}
.headerFixed .search-bit-result .articleBit-stock {font-size: 12px;background-size: 20px;padding-top: 17px;margin-top: 10px;}
.headerFixed .search-bit-result .articleBit-price {margin-top: 0;display: block;}

/*END INSTANT SEARCH*/
/*********BLOG INSTIT********/
.contenu-other-page.max-container-blog .max-container-panier {max-width: 880px;}

.contenu-other-page.max-container-blog {background-size: 78%;background-position: center;}
.text-decoration-none{text-decoration: none;}
.select-content select {height: 40px;width: 100%;max-width: 520px;margin: auto;background:  url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAGBAMAAAAFwGKyAAAAAXNSR0IB2cksfwAAAAlwSFlzAAALEwAACxMBAJqcGAAAABVQTFRFAAAAAj0oAj0oAj0oAj0oAj0oAj0ot2c20AAAAAd0Uk5TAK//n+9gjygtHZYAAAAoSURBVHicY2BUAgIBBmYnJSWVAAYGEyUlVwYGIBfEAXJBHAYGNiAHAFnJA9wD1eWQAAAAAElFTkSuQmCC') no-repeat center right 10px;border-radius: 20px;-webkit-border-radius: 20px;-ms-border-radius: 20px;display: block;padding:8px 30px 4px 15px;font-family: "Helvetica-NeueLTStd", Arial;color:#050F3B;border: 2px solid #050F3B;}
.select-content option {font-size:1.5em}

.color-black{color:#000}

.bg-categ-blog{background-color: #115BC9; padding: 10px 25px;color: #ffffff; text-transform: uppercase;font:  1rem  "Calibri-Bold";border-radius: 8px 8px 0 0;}

.js-custom-select-centering {
    max-width: 520px;
    height: auto;
    margin: auto;
}

.js-custom-select {
    width: 100%;
    position: relative;
    margin: 20px 0;
}

.js-custom-select select {
    display: none;
}

.js-custom-select .selected-item {
    cursor: pointer;
    width: 100%;
    margin: auto;
	border-radius: 10px;
    -webkit-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    display: block;
    padding:0.625rem 30px 0.625rem 0.938rem;
    color: #050F3B;
    border: 1px solid #C0C0CC;
	font:500 0.938rem "Calibri";
	background-color: #fff;

}


.js-custom-select .selected-item:after {background: url(../images/f_select.svg);font-size: 20px;color: #fff;position: absolute;right: 15px;top:18px;height:10px;width:15px;content:""}

.js-custom-select .arrowanim.selected-item:after {
	background: url(../images/f_select2.svg);
}

.js-custom-select .item {background: #FFF;padding: 8px 0.938rem;color: #050F3B;cursor: pointer;}

.js-custom-select .item:hover {
    background: #115BC9;
    color:#FFF;
}
.js-custom-select .item:last-child{border-radius:0 0 10px 10px;-webkit-border-radius:0 0 10px 10px;-ms-border-radius:0 0 10px 10px;-o-border-radius: 0 0 10px 10px;}
.js-custom-select .all-items {position: absolute;top: 100%;left: 0;width: 100%;z-index: 100;border:1px solid #C0C0CC;
	border-radius:0 0 10px 10px;
    -webkit-border-radius:0 0 10px 10px;
    -ms-border-radius:0 0 10px 10px;
    -o-border-radius: 0 0 10px 10px;
	border-top: none;
    margin-top: -8px;
    padding-top: 18px;
    background: #fff;
}
.text-transform-none{text-transform: none;}
.title-medium.text-lowercase::first-letter{text-transform: uppercase;}
.blog-perso-artbox .articleBit{max-width: 100%;}
.js-custom-select .all-items-hide {display: none;}
.max-w-50{max-width: 500px;}
.border-action-blog { border-left: 3px solid #2DB8E8;}
#blog-comments button{    background: #115BC9;display: inline-block;text-align: center;color: #fff;padding: 0.55rem 0.938rem 0.65rem 0.938rem;font: 500 0.938rem "Calibri";text-transform: none;border-radius: 5px;-webkit-border-radius: 5px;-ms-border-radius: 5px;-o-border-radius: 5px;border:none;position: relative;transition: all 0.3s;-webkit-transition: all 0.3s;-moz-transition: all 0.3s;-o-transition: all ;}
.main-content.page-content {position: relative;z-index: 10;}
.bg-article-blog{background: rgba(234,234,234,0.3);}
.articleBit-blog  {padding: 0.938rem;box-shadow: 0px 0px 19px 0px rgba(155,155,155,0.71);-webkit-box-shadow: 0px 0px 19px 0px rgba(155,155,155,0.71);-moz-box-shadow: 0px 0px 19px 0px rgba(155,155,155,0.71);background-color: #fff;border-radius: 12px;font: 400 0.938rem "Calibri";position: relative;}
/*********END BLOG INSTIT********/
.gallery-art-image .swiper-slide {height: auto!important;}
.scrollPerso-s::-webkit-scrollbar {
    background: #115BC9;
    -webkit-border-radius: 5px;
    border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    height: 5px;
    width: 9px
}

.scrollPerso-s::-webkit-scrollbar-thumb {
    background: #115BC9;
    border-bottom: 3px solid #fff;
    -webkit-border-radius: 5px;
    border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    border-left: 1px solid #fff;border-right: 3px solid #fff;
    border-top: 3px solid #fff
}

.scrollPerso-s::-webkit-scrollbar-track {
    background: #fff
}

.pas-section{background: none!important;}
.box_livraison .font-15.sofiaMedium-font.pb-2.color-white {font-size: 1px !important;opacity: 0;}


@media only screen and (min-width: 993px) {
    .body-sylius_shop_checkout_select_shipping .big-wrapper,.body-sylius_shop_checkout_select_shipping {overflow-x:inherit}
  .body-sylius_shop_checkout_select_shipping  .panier-summary-bloc {bottom: auto;position: sticky;top: 1px}
    .body-sylius_shop_checkout_select_shipping .padding-body .panier-summary-bloc {top: 105px}
} 
/*******POINT RELAIS*********/
#list_points .point_item {padding: 1rem;margin: 0;border-bottom: 1px solid #c0c0cc;cursor: pointer;}
.point_map_item{padding:5px 1rem;margin:5px 0;border: 2px solid #c0c0cc;cursor: pointer;display: inline-block;background: #c0c0cc;font-weight: 700}
/*#list_points {max-height: 300px;overflow-y: auto;overflow-x: hidden;}*/
.point-item-nom{background:url('../images/picto-colissimo.png') no-repeat center left; padding:5px 0 5px 25px;background-size: 20px;}
#list_points::-webkit-scrollbar {background: #f48211;-webkit-border-radius: 5px;border-radius: 5px;-ms-border-radius: 5px;-o-border-radius: 5px;height: 5px;width: 9px}

#list_points::-webkit-scrollbar-thumb {background: #f48211;border-bottom: 3px solid #fff;-webkit-border-radius: 5px;border-radius: 5px;-ms-border-radius: 5px;-o-border-radius: 5px;border-top: 3px solid #fff}

#list_points::-webkit-scrollbar-track {background: #c0c0cc}
 
#pickupCompany {font-weight: 700;text-align: left;}

