// Overriding default Bootstrap variables
@import "variables";

// All Bootstrap styles
@import "../../../../node_modules/bootstrap/scss/bootstrap";

// Custom theme styles
@import "steps";
@import "custom";

// Third party components
@import '../../../../node_modules/semantic-ui-css/components/flag.min.css';
@import '../../../../node_modules/glightbox/dist/css/glightbox.min.css';
//@import '../../../../node_modules/bootstrap-datepicker/dist/css/bootstrap-datepicker3.min.css';
