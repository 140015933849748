/*===================
 		COMMUN  
 ==================*/ 
 .detail-categ-nosscateg {min-height: 120px;padding-top: 0 !important;}
 .bann-header p + p {display: none;}
 .pi-no-padding{padding:0!important}    
 li.navItem.visible-980 {display: none;}
.visible-640{display:none}
.margin-10{margin:0 -0.625rem}
.image-mobile{display:none} 
@media only screen and (max-width:1680px) {  
.header-sticky{padding:0 0.625rem}	
}	

@media screen and (min-width: 1200px) and (max-width: 1365px){
html  {font-size: 1.15vw;}
}

@media only screen and (max-width:1480px) {
 .onglets>.navItem >.navLink{padding-left: 0.8rem; padding-right: 0.8rem;font-size: 1rem;}
  .sub-product a{font-size: 0.938rem}

 	#header {padding-left: 5%;}
}

@media only screen and (max-width:1380px) { 
	.other-link-top>ul> li{padding-left: 10px; padding-right: 10px;}
 .left-logo{max-width: 250px;}
 .navigation-site>.container{max-width: 100%;}
}
@media only screen and (min-device-width : 1023px) and (max-device-width : 1200px){
	  .sub-product a{font-size: 0.875rem}	
	 #header {padding-left: 0;}
 
	}
@media only screen and (min-device-width : 1200px) and (max-device-width : 1400px){
.headerFixed  .sub-product a{font-size: 0.875rem}	
.headerFixed #header {padding-left: 0;}
 .headerFixed .other-link-top >ul>li{padding-right: 0.625rem; padding-left: 0.625rem} 
}

@media only screen and (max-width:1340px) {
	.logo-site img {width: 100%;}
	.onglets>.navItem >.navLink{padding-left: 0.7rem; padding-right: 0.7rem;}
	.fixed-devis{font-size: 16px;}
	.has-bg-devis{font-size: 20px}
 }
 @media screen and (min-width: 1024px) and (max-width: 1200px){
	html {font-size: 1.31vw;}
	.step-bit-config .font-19 {font-size: 1rem !important;}
}

@media only screen and (max-width:1280px) {
	.search-bit-result  .articleBit-add-cart, .search-bit-result  .articleBit-ctc-cart{width: 70px;}
 }
@media only screen and (max-width:1250px) { 
.big-wrapper{  overflow-x: hidden;overflow-y: auto;width: 100%;}
.container {max-width: 100%;}
.hidden-mobile-h{display: none;}
 
	#header {padding-left: 0;}
		.onglets>.navItem {padding-left: 0;padding-right: 0;}
	.left-logo {padding: 0 5px;}

}
@media only screen and (max-width:1200px) {
	.other-link-top>ul> li{border-left: none;    border-right: 1px solid #44595E;}
	.other-link-top>ul> li:last-child{border:none!important}
  }
@media only screen and (max-width:1080px) {  
	.search-bit-result  .articleBit-add-cart, .search-bit-result  .articleBit-ctc-cart{width: 50px;}
	.search-bit-result .price-promo {font-size: 16px;}
	.search-bit-result .articleBit-price{font-size: 13px;}
	.review-verify {display: none!important;}
}
@media only screen and (max-width:1023px) {
	.absolute-md{display: none;}
	.has-bg-devis,.fixed-devis a em,.has-bg-devis .font-16 {font-size: 0!important;}
	.fixed-devis{width: 70px;}
	.onglets>.navItem >.navLink{  padding-left: 0.35rem;padding-right: 0.35rem;}

.hidden-on-lg{display: none!important;}
}
@media only screen and (min-width:993px) {
.menu-mob-overlay{display:none!important}	 
}
.other-lien-menu{display:none}

@media only screen and (max-width:991px) {
	#header{padding-top: 0;padding-bottom: 10px;}
	.left-logo{max-width: 100%;}
	.no-scrolling .left-logo, .no-scrolling .right-bloc-head {z-index: 9991;}
	.panier-deroulant{display: none!important;}
	.contact-header-l{display:none!important}
	.li-m980{display:block}
.big-wrapper {padding-bottom: 0;}
.fixed-devis{display: none;}
.no-scrolling{overflow:hidden!important}	
		.logo-site {margin:0; padding: 0.625rem 0} 	
.menu-mobile-bloc{display:block;left: 0.5rem;}
.left-logo {text-align:center;}
.logo-site img {margin: auto;max-height: 75px;} 
		.slogan{font-size: 12px}
	
.mobile-link em{display: none;}
.span-menu-mob	{display: block!important;} 
.other-link-top>ul>li a.link-other{position:relative}
.hidden-mobile{display:none}
.visible-mobile{display:block} 
/********Menu******/
.navigation-site {display: block;position: fixed;left: 0;background: #ffffff;left: -500%;top: 0;height: 100%;z-index: 999;width: 90%;transition: all ease 0.7s;-moz-transition: all ease 0.7s;-webkit-transition: all ease 0.7s;-ms-transition: all ease 0.7s;-o-transition: all ease 0.7s;max-width:390px; padding-bottom: 0;padding-left: 0!important;padding-right: 0!important;}
.navigation-site >.container {height: calc(100% - 60px);background: none;overflow-x: hidden;overflow-y: auto;position: relative!important;padding:0!important}
	.navigation-site .mCSB_inside>.mCSB_container {margin-right: 0.938rem!important;}
.navigation-site.open-mob {left: 0;transition: all ease 0.7s;-moz-transition: all ease 0.7s;-webkit-transition: all ease 0.7s;-ms-transition: all ease 0.7s;-o-transition: all ease 0.7s;}
	.navigation-site .menu{background: #ffffff;padding-left:0.8rem!important;padding-right: 0.8rem!important;padding-top: 5px;padding-bottom: 5px;}
		.navigation-site .onglets {display: block;border-top:1px solid #fff; max-width:100%; padding-left: 0px; padding-right: 0px}
		.close-menu-mob{display:block}
	
		.navigation-site .onglets>.has-sub-item >.navLink::after {color: #2483D1; content: '\e8cc'; position: absolute;right:0;left:auto;font-family:'fontello';top: 50%;font-size: 18px;transition: all ease 0.5s;-webkit-transition: all ease 0.5s;-ms-transition: all ease 0.5s;-o-transition: all ease 0.5s;-moz-transition: all ease 0.5s;background:none; display:block!important; transform:translate(0,-50%); -webkit-transform:translate(0,-50%); -ms-transform:translate(0,-50%);-spec-transform: translate(0, -50%);}
		.navigation-site .onglets>.active> .navLink::after {color:#fff;transition: all ease 0.5s;
			-webkit-transition: all ease 0.5s;-ms-transition: all ease 0.5s;-o-transition: all ease 0.5s;-moz-transition: all ease 0.5s;}
		.subMenu {position: absolute;margin-top: 0;padding:0;border-bottom: none;width: 100%;top: 0;z-index: 999999;height: 100%;left: initial;;right: -500%;transition: all ease 1.5s;-moz-transition: all ease 1.5s;-webkit-transition: all ease 1.5s;-ms-transition: all ease 1.5s;-o-transition: all ease 1.5s;display: block !important; overflow: hidden;border: none; transform: none;-webkit-transform: none;-ms-transform: none;-o-transform: none;}
			.ss-menuniv2 {padding-left: 0.938rem;}

			.max-img-menu img{    width: 40px;height: 40px;}
		.sub-menu-top, .sub-menu-top .container, .sub-menu-top .container .row, .flex-categ, .box-flex{height: 100%;}
		 .ss-categ-l{height: 100%;padding-left: 0;padding-right: 0;}
		.sub-product{overflow-x: hidden;overflow-y: auto;padding-left: 0;padding-top: 0; }
		.remove_mob_parent.navigation-site .box-flex {overflow-y: auto;padding-left: 25px; padding-right: 25px}
		.sub-menu-top {padding-top: 0.625rem;padding-bottom: 0.625rem; max-width: 100%; padding-left: 0; padding-right: 0}
	
		.subMenu.open {right: 0;transition: all ease 0.6s;-moz-transition: all ease 0.6s;-webkit-transition: all ease 0.6s;-ms-transition: all ease 0.6s;-o-transition: all ease 0.6s;height: 100%;}
		.navigation-site.open-mob.remove_mob_parent .container.scroll-me-mobile_ {overflow: hidden;}
	
.onglets>.navItem{display:block;padding:0}
.onglets>.navItem.visible-980 {display: block;}
.onglets>.navItem>.navLink{color:#1B1B1E; text-align:left; padding:0.8rem 0;border-bottom: 1px solid #C0C0CC; font-size:0.9rem; max-width: 100% ;border-top:none!important; border-left:none!important;border-right:none!important;display: flex;display: -ms-flex; align-items: center;}
.onglets>.navItem >.navLink i{display: none;}
.onglets>.navItem >.navLink strong{margin-right: 10px;}
.onglets>.navItem>.navLink img {max-width: 2.7rem;width: 100%;}
	.hidden-980{display:none}

	.sub-product a{ text-align:left; padding:0.8rem 0;border-bottom: 1px solid #C0C0CC;font-size:1rem;text-transform: uppercase;}
	.other-btn-navigation {background: #1B1B1E;color: #fff;font: 500 0.9rem "sofia-pro";padding: 0.5rem 1rem;}
	.btn-mail-nav {background: url("../images/ico_ctc.svg") no-repeat center left;padding-left: 2rem;background-size: 1.5rem;display: block;}
			.other-btn-navigation a:hover{text-decoration: underline!important;color: #fff;}
.hidden-logo{display:inline-block}
.visible-logo{display:none}
.no-scrolling .link-mobile-fixed{display:none}
	.hidden-on-lg{display: none!important;}	
	.right-bloc-head{max-width: 100%; position: absolute;right: 0;top: 50%; transform: translate(0,-50%);margin-top: -20px;z-index: 9;max-width:max-content;padding-right: 0;}
.other-link-top>ul> li{padding-left: 10px; padding-right: 10px;}
		.compte-link.link-other span{max-width: 80px;}	
		.nbre-panier{font-size: 14px;}
		.search-top {max-width: 100%;}
		.title-menu-categ{font-size: 1.2rem;}
		.parent-search{max-width: 100%;}
		.s-text, .search-top  form>.form-group .form-control{border: 1px solid #E6E6E6;height: 3rem;}
		.s-submit, .search-top .button{height: 3rem}
	
}
@media (min-width: 768px) and (max-width: 991px){
	html {font-size: 1.5vw;}

	}

@media only screen and (max-width:767px) {
	.logo-site{padding-top: 3px;padding-bottom:12px;min-height: 4.5rem;display: flex;justify-content: center;align-items: center;}
	.left-logo {padding-right: 50px;}
.hidden-on-md{display: none!important;}
.link-mobile-fixed {display: block;}
	.hidden-767{display:none}	
	.menu-mobile-bloc {margin-top: -23px;article-right .art-top-desc {
    display: none !important;
  }}

.margin-10.visible-980 {display: none !important;}
.box-flex.bloc-ong-categ .sub-product li {width: 100%;} 
.rech-mobile {position: relative;margin-top:8px} 
.mobile-link em{font-size:11px;}
.link-mobile-fixed a img{max-width: 40px; }

}

@media only screen and (max-width:575px) {
	html {font-size: 3.75vw;}
	/*#header{overflow: hidden;}*/
  .other-link-top>ul> li{padding-right: 0.45rem; padding-left: 0.45rem;font-size: 0.75rem;}
.right-bloc-head {max-width: max-content; padding-right: 7px;margin-top: -1.4rem;}
	.other-link-top {max-width:max-content}
	.left-logo{padding-right: 3rem;}	
.nbre-panier ,.nbre-devis{font-size: 0.725rem;padding-top: 7px;width: 1.4rem;background-size: 100%;height: 1.875rem;top: -0.4rem;display: none;right: -15px;}
.s-text, .search-top  form>.form-group .form-control{font-size: 0.85rem;height: 2.5rem;}
.s-submit, .search-top .button{height: 2.5rem;}
.zindex-0{z-index: -1!important;opacity: 0;}
.no-br-txtmob br{display: none!important;}
.search-bit-result .articleBit-stock {font-size: 12px;background-size: 20px;padding-top: 17px;margin-top: 10px;white-space: nowrap;}
.search-bit-result .articleBit-price {margin-top: 0;display: block;line-height: 1;}
.search-bit-result  .qte-panier.sylius-quantity-bit .form-control {height: 43px; padding: 0;}
.search-bit-result  .articleBit-add-cart, .search-bit-result  .articleBit-ctc-cart{height: 45px;width: 50px;}
.search-bit-result .qte-panier.sylius-quantity-bit{width: 80px;}
.search-bit-result .articleBit-lib {white-space: nowrap;}
.search-bit-result .col-6.px-2:last-child{   white-space: nowrap;max-width: max-content;flex: 0 0 210px;margin-left: calc(50% - 210px);}

.ico-compte-link {height: 35px;width: 35px;background-size: 48px;background-position: center;}
		.ico-panier-link {height: 35px;background-size: 48px;background-position: center;}
		.logo-site{min-height: 4rem;}
		.menu-mobile-bloc{margin-top: -23px;}
		.bann-header{padding-top: 2px;padding-bottom: 2px;}
}

@media only screen and (max-width:450px) {
	html {font-size: 4vw;}
	.other-link-top>ul>li{padding-left: 5px;padding-right: 5px;} 
	.menu-mobile-bloc {margin-top: -18px;}
	.compte-link.link-other span {max-width: 40px;}
}

@media only screen and (max-width:420px) {
	.menu-mobile-bloc a{max-width: 50px}
.navigation-site .menu {padding-left: 20px!important;padding-right: 20px!important;}
.ico-compte-link {height: 35px;width: 30px;background-size: 39px;}
.ico-devis-link {height: 35px;width: 30px;background-size: 39px;}
.ico-panier-link {height: 35px;background-size: 42px;width: 30px;}

}

@media only screen and (max-width:390px) {
	.menu-mobile-bloc a{max-width: 45px;}
	.right-bloc-head{margin-top: -1.6rem;}
	.other-link-top>ul>li{font-size: 0.85rem;}
	.menu-mobile-bloc {margin-top: -13px;}
}

@media screen and (min-width: 1250px) and (max-width: 1650px){
	.max-container-2{max-width: 60%;  }
	.max-container-3{max-width: 55%; }
	.max-container-4{max-width:  50%; }
	.max-container-5{max-width: 75%; }
	.max-container-6{max-width:80% }
	.box-av{font-size: 0.875rem;}
	html {font: -webkit-control;}
	.bloc-help {width: 10rem;height: 10rem;padding-right: 2rem;font-size: 0.6rem;background-position: center top 10px;background-size: 25px;}
	.parent-search {max-width: 80%;}
	.other-link-top>ul> li {padding-left: 0.6rem;padding-right: 0.6rem;}
	.font-18 {font-size: 1rem !important;}
	.img-help img{max-width: 3.5rem;}
	.big-container{max-width: 85%;}
	.other-link-top .hidden-fixed.mr-lg-3.mr-xl-4{margin-right: 1rem!important;}

	.bg-art-w{padding-left: 1rem;padding-right: 1rem;}
}
/*===================
 	SLIDER 
 ==================*/
 @media only screen and (max-width:1025px) { 
	.slider-item picture {width: 100%;display: block;}
.slider-item.swiper-slide img {width: 142%;max-width: 142%;opacity: 0;} 
 }
@media only screen and (max-width:991px) { 
	.home-prev,.home-next {display: none;}
}
 @media only screen and (max-width:767px) { 
 	.phrase-admin{font-size: 0.875rem}
 }
 

/*===============================
	 FAQ INDEX
================================*/
@media only screen and (min-width:768px) {
	.faq-index .accordion-bit {display: block!important;}
}
@media only screen and (max-width:767px) {
	.faq-index .accordion-bit{display: none;}
	.faq-index .accordion-bit:nth-child(-n+4){display: block;}
}

/*===============================
		ABOUT US
================================*/ 
@media only screen and (max-width:1320px) {
	.title-big{font-size: 2.5rem;}
}
@media only screen and (max-width:1080px) {
.max-container-1{max-width: 100%; padding-left: 0.938rem; padding-right: 0.938rem} 
.font-20{font-size: 1rem!important;}
.font-25{font-size: 1.425rem!important;}
}
@media only screen and (max-width:991px) {
.sofiaregular-16{font-size: 0.875rem}
.text-regular-onlg{font-family: "sofia-pro"}
.title-small-mobile{font-size:1.8rem ;}
.btn-link{ padding-top: 0.625rem;padding-bottom: 0.625rem;font-size: 0.85rem;background-color: #115BC9;}
}
@media only screen and (max-width:767px) {
	.title-medium, .title-med-account,.page-categorie-content h1.header{font-size: 1.7rem;}
	.title-compte-client .title-medium,.title-menu-account .title-medium{font-size: 1.7rem;}
	.title-medium.big-onmobile{font-size: 2rem;}
	.title-small-mobile{font-size:1.4rem ;}
	.title-small-mobile br{display: none;}
	.title-ss-regular{font-size: 1.8rem;}
	.title-big-regular{font-size: 1.8rem;}
	.fontbold-mobile{font-family: "sofia-pro";font-weight: 700;}
.img-about img{max-width: 75px}
.fontsize-mobile-text{font-size: 0.9rem}
.fontsize-mobile-textbig{font-size: 19px}
.text-gris-onlg{color: #4D4D4D;}
.margin-n1{margin-right: -0.938rem; margin-left: -0.938rem}	
.hover-img-scale{font-size: 1rem}
.d-none-mobile{display: none;}
.object-fit-video {min-height: 17rem;}
.bloc-about .object-fit-img{max-height:17rem ;overflow: hidden;}
.btn-after-style {width: 4rem;height: 3rem;padding-top: 0.7rem;}
.font-16,.font-18,.ariane-box *{font-size: 0.9rem!important;}
.font-15{font-size: 0.875rem!important;}
.font-14{font-size: 0.825rem!important;}
.font-17{font-size: 1rem!important;}
.title-medium.title-medium-mobile{font-size: 1.5rem;}
.title-medium.has-after:after{width: 72px;}
.font-19 {font-size: 1.15rem !important;}
.font-24{font-size: 1.25rem !important;}
.font-22{font-size: 1.35rem !important;}
.font-25{font-size: 1.4rem!important;}
}
@media only screen and (max-width:575px) {
.btn-link{ padding-top: 0.625rem;padding-bottom: 0.625rem;font-size: 0.91rem;}
.btn-link.other-radius-btn{font-size: 0.875rem;}
.title-medium,.title-med-account,.page-categorie-content h1.header {font-size: 1.65rem;}
.title-medium.title-medium-mobile{font-size: 1.25rem;}
.title-compte-client .title-medium,.title-menu-account .title-medium{font-size: 1.65rem;}
.title-medium.big-onmobile{font-size: 1.85rem;}
.title-big-regular{font-size: 1.875rem;}
.title-small-mobile{font-size:1.4rem ;}
.title-ss-regular {font-size: 1.325rem;}
.reseau-bloc  .title-ss-regular br{display: none;}
.btn-link.minw-sm {min-width: 14rem;}
.font-15 {font-size: 0.8rem !important;}
.font-14 {font-size: 0.75rem !important;}
.font-16,.font-18,.ariane-box *{font-size: 0.85rem!important;}
.font-17{font-size: 0.85rem!important;}
.font-normal-mobile{font-size: 0.735rem!important;}
.py-btn{padding-top: 0.75rem!important;padding-bottom: 0.75rem!important;}
.title-big{font-size: 2.2rem;}
.font-19 {font-size: 1.1rem !important;}
.title-eco{padding-left: 50px;background-size: 42px;}
.font-24{font-size: 1.2rem !important;}
.font-22{font-size: 1.18rem !important;}
.font-25{font-size: 1.35rem!important;}
}
@media only screen and (max-width:430px) {
	.btn-link{font-size: 0.938rem;}
	.title-big{font-size: 2rem;}
	.etiquette-texte{font-size: 9px;    min-width: 50px;padding-left: 10px;padding-right: 10px;}
}

@media only screen and (max-width:370px) {
.fontsize-mobile-text{font-size: 0.875rem}
.fontsize-mobilesm-text{font-size: 0.875rem!important}
.max-container-1{padding-right: 0.5rem; padding-left:0.5rem}
.margin-n1{margin-right: -0.5rem; margin-left: -0.5rem}	
.hover-img-scale{font-size: 0.875rem}
.sofiaregular-16{font-size: 0.875rem}
.font-16,.font-18,.ariane-box *{font-size: 0.875rem !important;}

}
@media only screen and (max-width:340px) {
.fontsize-mobile-text{font-size: 0.813rem}
.fontsize-mobile-textbig{font-size: 1rem}
.fontsize-mobilesm-text{font-size: 0.813rem!important}
.hover-img-scale{font-size: 0.813rem} 
.sofiaregular-16{font-size: 0.813rem}
.sofiaregular-20{font-size: 1rem}
}

/*===============================
	CATEGORY INDEX + ECO
================================*/	
@media only screen and (max-width:1200px) {
	.bg-mandat {background-size: 50%;}
}
@media only screen and (max-width:991px) {
	.bg-green-opac{background:  #ffffff;}
	.category-item-lib{font-size: 1.4rem;}
}
@media only screen and (max-width:767px) {
	.btn2-slide-next.category-next {display: block!important;}
	.btn2-slide-prev.category-prev {display: block!important;}

	
}
@media only screen and (max-width:575px) {
.bloc-category-index{background:#000 url("../images/bg_categ_index2.jpg") no-repeat center ;background-size: cover;}
	.category-item-lib{font-size: 0.925rem;}
	.category-item-index {padding: 0 7px;}
	.mt-perso-collection{margin-top: -0.75rem!important;z-index: 1;}
	.z-index-mob{z-index: 2;}

	.has-bg-eco{font-size: 0.825rem;}
	.bg-collection{background-size: 125%;}
}
@media only screen and (max-width:370px) {
	.btn2-slide-next.category-next {width: 50px;height: 50px;background-size: 50px;}
	.mobile-padding-slide {padding-right: 70px;}
	.bloc-category-index::after{width: 70px;}
	.category-item-lib{font-size: 1.1rem;}
}
/*===================
 	ARTICLE COMMUN 
 ==================*/
 .visible-ml{display:none}

@media only screen and (max-width:1855px) { 
.qte-panier.sylius-quantity-bit{width: 65px;}
.articleBit-add-cart {width: 40px;}
.articleBit-stock{font-size: 12px;}
.txt-speed-cart{font-size: 18px;}
}
@media only screen and (max-width:1800px) { 
	  .association-bit-art .qte-panier.sylius-quantity-bit {width: 58px;}
	  .association-bit-art .price-promo {font-size: 15px;}
	 .association-bit-art .articleBit-add-cart{width: 30px;}
	}
@media only screen and (max-width:1700px) { 
	.articleBit-stock{font-size: 11px;}
	}
@media only screen and (min-device-width: 1024px) and (max-device-width: 1350px){
.top-index .col-3.hide-phare.hide-display-list {max-width: 20%;flex: 0 0 20%;margin-top: 5px;}
.top-index  .txt-speed-cart {font-size: 15px;}
.top-index  .txt-speed-cart span {font-size: 13px;}
}
@media only screen and (max-width:1200px) {
	.swiper-container .container-pagination .swiper-pagination{bottom: 0!important;position: relative;/*display: inline-block;*/}
	
.bloc-top-content {padding: 0;}
 .visible-ml{display:block}
 
 .content-ajout-panier{padding-right: 8px;}
 	.btn-ajout-panier{font-size: 0.875rem}
 	 .input-qte-a{width: 30px}
 	 .px-perso-top{padding-left:3px;padding-right: 3px;}
}
@media only screen and (max-width:1023px) {
	.title-top-content {padding: 0.938rem 0}
	
	.articleBit-prix{font-size:0.875rem;}
.px-lg-3p.mb-lg-5p {padding-left: 0.7rem!important;padding-right: 0.7rem!important;}
.mx-n3.mx-lg-n3p{margin-left: -0.7rem!important;margin-right: -0.7rem!important;}
}

@media only screen and (max-width:991px) {
	.abs-pagination{bottom: 8px;}

	.swiper-padding-hover{padding-bottom: 25px;}
	.articleBit-hover-action{display: none!important;}
	.articleBit #tier_prices_tables{display: none!important;}
	.listes-articles .articleBit-hover-action{display: block!important;}
	.articleBit-caract span{width: 0.875rem;height: 0.875rem;margin-right: 2px;}
	.articleBit-caract{max-height: 2.1rem;overflow: hidden;}
	.articleBit-lib a {font-size: 1rem;        display: block;white-space: nowrap;text-overflow: ellipsis;overflow: hidden;} 
}
@media only screen and (max-width:767px) {
	.articleBit-ima-marque img {max-width: 1.5rem;}
.btn2-slide-prev{margin-top: -70px;width: 70px;height: 70px;background-position:center right 3px ;margin-left: -1rem;} 
.btn2-slide-next{margin-top: -70px;;width: 70px;height: 70px;background-position:  3px center;margin-right: -1rem;} 
.btn2-slide-prev.sscategory-prev,.btn2-slide-next.sscategory-next{margin-top: -38px;}
.big-img .btn2-slide-prev,.big-img .btn2-slide-next{margin-top: 0!important;}
.articleBit{font-size: 0.825rem;}
.articleBit-price{font-size:0.825rem;}
.price-promo{font-size: 1rem;line-height: 1rem;}
.etiquette{font-size: 0.813rem; left: -10px;}
	.picto-promo strong{ font-size: 0.938rem;}
	.bg-art-w{padding-left: 1rem;padding-right: 1rem;}
	.video-content iframe{height: 100%!important;}
	.video-content{background-color: #000;}
	.gallery-art-image .swiper-slide:not(.swiper-slide-active){opacity: 0;}
} 
@media only screen and (max-width:575px) {
	.etiquette{font-size:0.7rem;}
	.max-code{max-width: 4.25rem;font-size: 0.725rem;}
		.picto-promo strong{ font-size: 0.813rem;} 
		.picto-promo{width: 50px}
.articleBit-btn-detail {width: 40px;padding: 5px;}
	.articleBit-btn-detail span {font-size: 0;padding: 0.938rem;background-position: center;}
.padding-top-index {padding-left:0px;padding-right: 0;}
.px-perso-top{padding-left:0px;padding-right: 0px;}
.mx-xs-n2{margin-right: -0.938rem!important; margin-left: -0.938rem!important;}
	.px-xs-2 {padding-right: 0.938rem!important; padding-left: 0.938rem!important;}
	.articleBit-lib a {font-size: 0.875rem;} 
	.swiper-pagination-bullet {margin: 0 4px !important;width: 0.6rem;height: 0.6rem;}
	
}
@media only screen and (max-width:420px) {
	.parent-search {bottom: 46px;}
	.etiquette{font-size: 12px}
		.etiquette.etiquette-3 span {background-size: 0.625rem;padding-left: 0.938rem;}
	.prix-promo{font-size: 20px;}
			.prix-promo small {font-size: 0.813rem;}

	.articleBit-caract{font-size: 0.813rem}
	.mx-xs-n2{margin-right: -0.625rem!important; margin-left: -0.625rem!important;}
	.px-xs-2 {padding-right: 0.625rem!important; padding-left: 0.625rem!important;}
}

@media only screen and (max-width:380px) {

.articleBit-prix{font-size:0.875rem;}
.prix-promo{font-size: 18px;}
	.prix-promo small {font-size: 12px;}
	.picto-promo {width: 45px}
	.articleBit-caract{padding-top: 4px; padding-bottom: 4px}
		.picto-promo strong{ font-size: 12px;}
	
	.articleBit-caract{font-size: 12px}
	.mx-xs-n2{margin-right: -8px!important; margin-left: -8px!important;}
	.px-xs-2 {padding-right: 8px!important; padding-left: 8px!important;}
	.btn2-slide-prev{margin-top: -50px;width: 50px;height: 50px;margin-left: 0;} 
	.btn2-slide-next{margin-top: -50px;;width: 50px;height: 50px; margin-right: 0;} 
	.btn2-slide-prev.sscategory-prev,.btn2-slide-next.sscategory-next{margin-top: -25px;}
	.swiper-padding-hover{padding-bottom: 20px;}
}
@media only screen and (max-width:340px) {

.mx-xs-n2{margin-right: -5px!important; margin-left: -5px!important;}
	.px-xs-2 {padding-right: 5px!important; padding-left: 5px!important;}
.articleBit-prix{font-size:12px;}
.prix-promo{font-size: 16px;        line-height: 2px;}
	.prix-promo small {font-size: 11px;}
	.swiper-padding-hover{padding-bottom: 15px;}

.etiquette{font-size: 11px}
.articleBit-caract{font-size: 11px}
}

/**===================================
				FOOTER
=====================================*/
.toogle-menu2-mob{display:none}
@media only screen and (max-width: 1250px){
	.bloc-help {top: auto;bottom: 0;border-radius: 2rem 0 0 2rem;padding: 5px 1rem 5px 46px;text-align: justify;width: auto;height: auto;right: 0;background-color: #050F3B;background-position: left 7px center;}
	
	footer{padding-bottom: 4rem;}
	.goToTop{bottom: 5rem;}
	.box-av{font-size: 1.15rem;}
}
@media only screen and (max-width: 991px){
	.menu-content{margin-bottom: 0.938rem}
	footer{background: #000000 url(../images/bg_footer_mobile.jpg) no-repeat top left;background-size: cover;}
	
	
} 

@media only screen and (max-width: 767px){ 
.menu-content.max-width-100{border-bottom: 1px solid #9EA6AD;}
.form-newsletter{margin: 0;}
.footer-bottom{padding-top: 3rem;}
}

@media only screen and (min-width: 576px){
.toggle-m-c{display: block!important;height: 100%!important; transition: none!important;overflow: visible!important;}	
.title-menu-t.toggle-me{display: block!important;}	
.menu-content {max-width: 240px; } 

}

@media only screen and (max-width: 575px){
	.padding-reinsurance{padding:1.2rem 5px 1rem 0;}q
	.hidden-575{display: none;}
.adresse-newsletter{height: 2.7rem; border-radius: 2.5rem;}

	.menu-content{margin-bottom: 0px}
	.bloc-footer-menu {margin-left: -1rem;margin-right: -1rem;}
	.toggle-me {padding: 1.2rem  0.625rem;text-align: center;position: relative;border-bottom:  1px solid #115BC9;display:block!important;color: #ffffff}
	
		.toggle-me::after{ content: '\e8ca';display:block; position:absolute; right:1.8rem; top:50%;font-family: "fontello";   font-size: 1.4rem;transform:translate(0,-50%);-webkit-transform: translate(0, -50%);-ms-transform: translate(0, -50%);-o-transform: translate(0, -50%);-spec-transform: translate(0, -50%);color: #2483D1;font-weight: 600;}
		.toggle-me.open{color: #115BC9}
		.toggle-me.open::after{ content: '\e8cd';color: #115BC9}
		.title-menu-t span{margin-bottom: 0;}
		.menu-content ul li a{font-size: 1rem}
		.title-menu-t{font-size: 1.2rem}
		
	.menu-content ul li{text-align:center; width:100%; padding: 0;}
	.menu-content ul li a{padding: 8px 0;display: block;}
	.menu-content ul li a:hover,	.menu-content ul li a:hover {background-color: #1762BC;color: #fff;}
	
.toggle-me + .toggle-m-c{display:none; padding:20px;margin-top: 0;text-align:center;border-bottom: 1px solid rgba(31,27,28,0.15);}
	.toggle-m-c >span,span.lib-none {display:none}
 .toggle-m-c{text-align:center;}
 .autre-footer-m {padding: 0;border: none;}
.other-menu-bit{width:100%;    padding: 0}
.px-site-2-m{padding-right: 0.625rem; padding-left: 0.625rem}
.service-footer .toggle-m-c {display: block!important;height: 100%!important;transition: none !important;overflow: visible !important;padding-top: 10px;padding-bottom: 5px;}
.service-footer .toggle-me::after{ content: '\e8cd';color: #2483D1}
	.footer-bottom{padding-top: 0;} 
	.service-footer .title-menu-t.toggle-me {border-bottom: 0;padding-bottom: 0;}

	.copy-text {font-size:0.85rem;}
	.box-av {font-size: 0.85rem;}
}
@media only screen and (max-width: 450px){
	.ch-cookie-consent__form{margin-top: 10px!important;}
	.font-ctc-footer{font-size: 26px;}
}
@media only screen and (max-width: 390px){

}
@media only screen and (max-width: 370px){
.menu-content ul li a{font-size: 0.938rem}
	.service-footer {font-size: 0.938rem;}
	.font-ctc-footer{font-size: 24px;}
}

/**===================================
				CATEGORIE
=====================================*/
@media only screen and (max-width: 1885px){
.categorie-img{max-width: 42%;}
}
@media only screen and (max-width: 1500px){
	.categorie-img{max-width: 100%;}
	.absolute-categ{left: 60%;}
	.big-container{padding: 0;}
	.border-table-perso-art .qte-panier.sylius-quantity-bit, .article-conseil-bit .qte-panier.sylius-quantity-bit{width: 60px;}
	.border-table-perso-art .qte-panier.sylius-quantity-bit .form-control, .article-conseil-bit .qte-panier.sylius-quantity-bit .form-control{padding-left: 3px; padding-right: 3px;font-size: 15px;}
	.px-promo-liste-art, .article-conseil-bit .price-promo {display: block;padding-left: 0;}
}
@media only screen and (min-device-width : 993px) {
	.item-5-categ .col-perso-categ {-ms-flex: 0 0 20%;flex: 0 0 20%;max-width: 20%;} 

}
@media only screen and (min-device-width : 993px) and (max-device-width : 1500px){
	.col-perso-categ {-ms-flex: 0 0 33.333333%;flex: 0 0 33.333333%;max-width: 33.333333%;} 
	.item-5-categ .col-perso-categ {-ms-flex: 0 0 25%;flex: 0 0 25%;max-width: 25%;} 

}


@media only screen and (max-width: 1280px){

.filtre-cat{padding-right: 25px}
.filtre-c-bit{padding-right: 0.625rem; padding-left: 0.625rem}
} 
@media only screen and (max-width: 1023px){
	.categorie-top img {max-width: 100%;height: 100%;object-fit: cover;width: 100%;}
	.object-img-categ {max-height: 16rem;overflow: hidden;}
	.categorie-img{padding-left: 1rem!important;padding-right: 1rem!important;padding-bottom: 1rem;padding-top: 1rem;}
	.categorie-top{padding-left: 0;}
	.hide-breadcrumb{display: none;}
	.col-perso-left{display: none;} 
	.categorie-top .fontsize-normal.text-grey.max-line-3.d-none.d-md-block {display: none!important;}
	.categorie-top  .fontsize-normal.text-grey.d-block.d-md-none{display: block!important;}
	#descr-desktop{display: none!important;}
}
@media only screen and (min-width: 992px){
	.content-fitre-categ {display: block!important;height: 100%!important;transition: none!important;overflow: visible!important;}
}
@media only screen and (max-width: 991px){
	.filtre-c-bit select, .select-f{font-size: 0.875rem;}
	.lib-f-c{font-size: 0.875rem;}
	
	.lib-f-c {display: none!important;}


	.filtre-c-bit{width:100%;max-width: 100%;margin-bottom: 10px;}
	.categorie-filtre  {width: 100%;display: block;} 
	
	.content-fitre-categ{margin: 0;padding-bottom: 0.625rem;}
	.max-categ2 {margin: 0.938rem auto 0 auto;padding-left: 4.5rem;}
		.categorie-detail-coll img {max-width: 200px;width: 100%;}
		.sscateg-lib{font-size: 38px; padding-bottom: 20px}
		.title-perso-big{font-size: 2.188rem;}
		.page-categorie {padding-left: 0;padding-right:0;}
		.ariane-box{padding-top: 12px; padding-bottom: 12px;}
		.filtre-cat{padding: 0; margin-top: 0;font:700 1rem "calibri";}
		.filtreContenu{border: 1px solid #1762BC;border-top-color: #fff;margin-top: -5px;}

.mobile-label-filtre {max-width: 150px;color: #fff;font:700 1rem "calibri";padding: 5px 10px 10px 10px;border-radius: 3.125rem;-webkit-border-radius: 3.125rem;-ms-border-radius: 3.125rem;-o-border-radius: 3.125rem;cursor: pointer;margin: 0 auto 1rem auto;text-align: center;background-color: #115BC9;text-transform: none;}
.mobile-label-filtre:hover, .mobile-label-filtre:focus{background-color: #1762BC;color:#fff}
.mobile-label-filtre i{font-size: 20px;padding-left: 10px;}
	.content-fitre-categ{display: -ms-flexbox;display: flex;height: 100%;align-items: center;-webkit-align-items: center;}
	.content-fitre-categ form{max-height: 90%; overflow-y: auto;overflow-x: hidden;background: #ffffff;padding: 35px 0 25px 0;}
		.cell-facette {position: fixed;width: 100%;height: 100%;z-index: 999;top: 0;left: 0;background: rgba(0,0,0,0.4);padding: 25px;display: none;}
		.filtre-c-bit select, .select-f {border: 1px solid #1762BC;}
		.btn-link.font-mini-btn {font-size: 0.875rem;width: 5rem;padding-top: 0.4rem;padding-bottom: 0.4rem;} 
		.btn-link.font-mini-btn img{max-width: 1.5rem;} 
		.categorie-img{padding-bottom: 0;}
		.col-cat:nth-child(13){    -ms-flex: 0 0 100%;flex: 0 0 100%;max-width: 100%;}
		.col-cat:nth-child(13) .flex-grow-l.position-relative_{max-width: 450px;}
}
@media only screen and (min-width: 768px){
	.hide-desktop-tr{display: none!important;}
}
@media only screen and (max-width: 767px){
	.simple-border {border-right:none;border-bottom: 1px solid #C0C0CC;    margin-right: 5px;}
	.tr-tfooter, .tfooter-bit-details.text-center {display: none;}
		/*****table list categ******/
		.table  .hide-td-mobile{display: none!important;}
.table .bit-details-tr td {display: block;width: 100%;padding-bottom: 8px; padding-top: 8px;border-top: none;}
.table  .bit-details-tr.col-num-7 td:nth-child(-n+5) {width: 20%;border: none;padding-bottom: 0!important;}
.table  .bit-details-tr.col-num-5 td:nth-child(-n+3) {width: 33.333%;border: none;padding-bottom: 0!important;}
/*******tabeau 6col*******/
.table  .bit-details-tr.col-num-6 td:nth-child(-n+2) {width: 30%;border: none;padding-bottom: 0!important;}
.table  .bit-details-tr.col-num-6 td:nth-child(3) {width: 20%;border: none;padding-bottom: 0!important;}
 .table  .bit-details-tr.col-num-6 td:nth-child(4) {width: 20%;border: none;padding-bottom: 0!important;text-align: right;} 
.table  .bit-details-tr td:nth-child(-n+2) {width: 50%;border: none;padding-bottom: 0!important;}

.table  .bit-details-tr {display: flex;flex-wrap: wrap;background: #F2F2F2;align-items: center;}	
.table  .bit-details-tr:nth-child(even){background-color: #E6E6E6;}
.px-promo-liste-art, .article-conseil-bit .price-promo {display: inline-block;padding-left: 10px; }
.border-table-perso-art .qte-panier.sylius-quantity-bit, .article-conseil-bit .qte-panier.sylius-quantity-bit{width: 70px;}
.border-table-perso-art .articleBit-add-cart, .article-conseil-bit .articleBit-add-cart{width: 60px;}
.radius-tr,.radius-tr td {background-color: #1762BC!important;}
.box-sscateg{font-size: 0.8rem;    border-radius: 10px;-webkit-border-radius: 10px;-ms-border-radius: 10px;-o-border-radius: 10px;}
.sscateg-child{    border-radius: 0 0 10px 10px;-webkit-border-radius: 0 0 10px 10px;-ms-border-radius: 0 0 10px 10px;}
.box-sscateg-title{font-size: 14px;   border-radius:  0 0 10px 10px;-webkit-border-radius: 0 0 10px 10px;-ms-border-radius:  0 0 10px 10px;-o-border-radius:  0 0 10px 10px;position: relative;}

.sscateg-child {position: fixed;left: 50%;top: 50%;transform: translate(-50%, -50%)!important;-webkit-transform: translate(-50%, -50%)!important;-ms-transform: translate(-50%, -50%)!important;max-width: 95%;z-index: 9999;margin-top: 30px;display: none;visibility: visible;opacity: 1;padding: 20px;}
.sscateg-child ul{border-top: 0;}

}
@media only screen and (max-width: 575px){
	.title-categ {margin-bottom: 0;}
	.filtre-c-bit{width: 100%;margin-bottom: 5px}
	.filtre-c-bit .select-f.active{border-bottom-color: #fff;}
	.filtre-c-bit select, .select-f{font-size: 1rem;}

	.sscateg-lib-bit{font-size: 0.813rem}
	
	.res-filtre .filtre{padding:3px 5px;}
		.res-filtre .filtre a{padding-right: 0.938rem}
		.res-filtre .filtre a i{top: 2px}
		
		.res-filtre {padding-left: 0;padding-top: 0.938rem;padding-bottom: 0.938rem;}
	
	.max-art{padding-right: 0}
	.max-categ2 {max-width: 350px;}
		.title-perso-big{font-size: 32px;}
		.listes-articles  .hide-display-list{display: none;}
		.listes-articles  .show-display-list{display: block;}
		
		.listes-articles .articleBit{max-width: 100%;}
		.listes-articles .articleBit-lib{position: relative;padding-right: 70px;margin-bottom: 10px;}
		.listes-articles .articleBit-ima{  -ms-flex: 0 35%;flex: 0 0 35%;max-width: 35%;    padding-right: 10px;    align-self: flex-start;}
		.listes-articles .bg-art-w {flex-direction: row!important;position: relative;}
		.listes-articles  .articleBit-desc{display: none;}
		.listes-articles  .code-list {display: block;font: 400 0.825rem "sofia-pro";}
		.listes-articles  .row-price{    -ms-flex: 0 0 100%;flex: 0 0 100%;width: 100%;  max-width: 100%;padding-top: 0!important;padding-bottom: 0!important;}
		.listes-articles .articleBit-price {font-size: 0.825rem;}
		.listes-articles  .articleBit-price small {font-size: 0.575rem;}
		.listes-articles .price-promo{display: inline-block;padding-left: 10px;font-size: 1rem;}
		.listes-articles   .articleBit-hover-action {opacity: 1;visibility: visible;    -ms-flex: 0 0 100%;flex: 0 0 100%;width: 100%; 
			max-width: 100%;top: 0;position: relative;transform: none;transition: none; /*display: none!important;*/margin-top: 0;}
			.listes-articles   .articleBit-hover-action-top {background: #ffffff;    margin-top: 0;box-shadow: none;padding: 0;margin-top: 5px;border-radius: 0;}	
		
		.listes-articles .articleBit  .articleBit-ima +  .flex-grow-l_{display: flex;flex-wrap: wrap;    align-items: self-end;}
		.listes-articles  .articleBit  .max-art.flex-grow-l {max-width: 100%;flex: 0 0 100%;padding-bottom: 15px; margin-bottom: 5px;border-bottom: 1px solid #C0C0CC;}
		.listes-articles .articleBit  .articleBit-stock{color: #96C136;text-align: center;    white-space: nowrap;}
		.listes-articles .articleBit  .qte-panier.sylius-quantity-bit{    border: 1px solid #5B646D;width: 100%; max-width: 100px;}
		.listes-articles  .articleBit  .qte-panier.sylius-quantity-bit .form-control{height: 44px;}
		.listes-articles .articleBit  .articleBit-add-cart{width: 50px; height: 46px;margin-left: 5px;}
		.listes-articles  .articleBit-hover-action-top::before{display: none;}
		.listes-articles .articleBit .etiquette{display: none;}
		.box-sscateg-title{font-size: 13px;}
		.box-sscateg-title a{padding: 8px}
		.col-right-perso-otherlist .table-white-perso.table-perso td{font-size: 0.75rem;}
}

@media only screen and (max-width: 380px){
	.border-table-perso-art .qte-panier.sylius-quantity-bit, .article-conseil-bit .qte-panier.sylius-quantity-bit{width: 60px;}
	.border-table-perso-art .qte-panier.sylius-quantity-bit .form-control, .article-conseil-bit .qte-panier.sylius-quantity-bit .form-control{font-size: 14px}
.border-table-perso-art .articleBit-add-cart, .article-conseil-bit .articleBit-add-cart{width: 45px;}

}

@media only screen and (max-width: 360px){
	.listes-articles .articleBit   .qte-panier.sylius-quantity-bit .form-control{height: 40px;}
	.listes-articles .articleBit   .articleBit-add-cart{width: 45px; height: 42px;}
	.listes-articles .articleBit  .qte-panier.sylius-quantity-bit{ max-width: 90px;}
	.box-sscateg-title{font-size: 12px;}
}

/**===================================
				ARTICLE
=====================================*/

@media only screen and (max-width: 1280px){
	.article-top{padding-left: 0.625rem;padding-right: 0.625rem;}
}

@media only screen and (max-width: 991px){

.contenuArticle {margin-top: 0;}
	.body-table-perso {font-size: 0.813rem;}
	.border-table-perso .table-perso .card-header th {padding-left: 5px;padding-right: 5px;font-size: 12px;}
	.article-promo{font-size: 1.8rem;}
	
}
@media only screen and (min-width: 768px){
	.toggle-content-tab{overflow: hidden!important;    padding: 1.125rem 0!important;margin: 0!important;}
}
@media only screen and (max-width: 767px){	
	.big-img{width: 100%;text-align: center;}
	.article-top{padding-left: 0;padding-right:0;}
.article-left{width: 100%;padding: 0; text-align: center;}
	.article-right{width: 100%; padding-left:0;padding-right:0;z-index:9}	
	.title-article-art{font-size: 1.2rem;}
	.ref-petit{font-size: 20px}
	.sstitle-article{font-size:28px}

.selectfitre-bit .bootstrap-select>.btn.dropdown-toggle{height: 60px;font-size: 17px;line-height: 40px;}
.selectfitre-bit select.selectpicker.show-tick{height: 55px;font-size: 17px;}
.caractistique-article .selectfitre-bit label {font-size: 17px;}
.selectfitre-bit{max-width: 430px; width: 100%}
	.selectfitre-bit:last-child {padding-bottom: 0!important;}


.title-tab{font-size: 40px}
.tab-contentBit{    padding-bottom: 60px;margin-bottom: 60px;}
.btn-link.minw-verybig{width: 100%; display: block;padding-bottom: 20px; padding-top: 20px}
.art-detail{margin: 5px 0 0 0;}
.art-retour{font-size: 0.875rem;}

.art-top-desc{padding-bottom: 0; border-bottom: 0;}
	.body-table-perso {font-size: 0.875rem;}
	.border-table-perso .table-perso .card-header th {font-size: 1rem;}
	.tab-article-content{border-top: 1px solid #cccccc}
	.toggle-tab{font-size: 0.9rem;padding: 1rem 0;}

	.tab-content{padding-top: 0; padding-bottom: 35px;border-top: 1px solid #e6e6e6;        margin: 0 -10px;}
	.tab-descr-art .tab-pane {display: block!important;opacity: 1!important;transition: none!important;margin-bottom: 0px;}
	.tab-descr-art .nav-tabs {display: none;}
	.toggle-content-tabmob {display: none;padding: 15px;border-bottom: 1px solid #C0C0CC}
	.tab-content #paiement{border-top: 1px solid #C0C0CC;}
	span.hide-mobile-txt {display: none;}
	.no-border-bottom-mobile{border-bottom: none!important;}

	.article-recap >.bg-light-site {background: #fff;border: none !important;padding: 10px 0 !important;}
	.step-bit-config::before{top: 1.8rem;}
	.step4-mobile{position: relative;padding-left: 20px;}
	.step4-mobile::before {content: "";width: 10px;height: 10px;background: #e6e6e6;left: -6px;position: absolute;top: 11px;border-radius: 100%;z-index: 9;}
	.font-20-mobile{font-size: 1rem!important;}
	.font-20-mobile.font-italic{font-weight: 400;}
	.articleBit-desc{font-size: 13px;}
	.article-right .hover-promo-tier:hover .bloc-tiers{display: none!important;}
	.d-block-mobile{display: block!important;}
	.caract-qty-content .form-group{max-width: 100%;}
	.max-art-desc{padding: 0.5rem 1rem 1.25rem 1rem;}
	.border-bottom-mobile{border-bottom: 1px solid #6D8AB5;}
	.tab-descr-art {border-top: 1px solid #6D8AB5;}
.tab-descr-art h1{font-size: 1.1rem;}
.tab-descr-art h2{font-size: 1rem;}
.toggle-content-tab{padding-left: 1rem;padding-right: 1rem;}
}

@media only screen and (max-width: 575px){
	.form-perso-content .champ-perso-bit input.form-control {max-width: 100%;}
		.tailleBit a{width: 48px; height: 48px; font-size: 22px;line-height: 47px;}
		.couleurBit a img {width: 37px;}
		.step-bit-config::before{top: 1.6rem;}
		.caract-select-content select, .caract-qty-content .form-group{height: 2.65rem;border-radius: 1.2rem;}
		.caract-qty-content input{height: 2.65rem;}
		.btn-panier{font-size: 1.5rem;}
		.art-qte .qte-panier.sylius-quantity-bit{margin-right: 15px;}
}
@media only screen and (max-width: 480px){
	.supp-img {margin-left:-0.625rem;margin-right:-0.625rem;}
	.supp-img img{width: 100%}
	.title-tab{font-size: 34px; margin-bottom: 20px} 
.tab-contentBit{    padding-bottom: 45px;margin-bottom: 40px;}

.btn-link.minw-verybig{min-width: 200px;}
.btn-link.minw-big-mob{min-width: 200px;}	

}
@media only screen and (max-width: 420px){

	.body-table-perso {font-size: 0.813rem;}
	.border-table-perso .table-perso .card-header th {font-size: 0.938rem;}
	.tailleBit a{width: 45px; height: 45px; font-size: 20px;line-height: 44px;}
	.couleurBit a img {width: 34px;}
	.art-qte .qte-moins-bit img {width: 23px;}
	.art-qte .qte-plus-bit img {width: 19px;}
	.art-qte .qte-panier.sylius-quantity-bit {width: 75px;}
	.articleBit-desc{font-size: 12px;}
	.btn-panier {font-size: 1.2rem;}
	.art-qte .qte-panier.sylius-quantity-bit .form-control{    font-size: 24px;}
}
@media only screen and (max-width: 390px){
		.ref-petit{font-size: 1rem}
	
	.prix-art-panier{font-size: 17px}
	.selectfitre-bit .bootstrap-select>.btn.dropdown-toggle{font-size: 0.938rem;height: 50px;line-height: 30px }
	.selectfitre-bit select.selectpicker.show-tick{font-size: 0.938rem;height: 50px;line-height: 30px}
	.caractistique-article .selectfitre-bit label{font-size: 0.938rem;}
	
	.tailleBit a{width: 40px; height: 40px; font-size: 18px;line-height: 39px;}
	.couleurBit a img {width: 30px;}

}
@media only screen and (max-width: 370px){
		.title-tab{font-size: 32px}
		
		.art-retour{font-size: 0.813rem;}	
			.prix-art-panier{font-size: 1rem}
	.body-table-perso {font-size: 12px;}
	.border-table-perso .table-perso .card-header th {font-size: 0.875rem;}
	.articleBit-desc{font-size: 11px;}
}
@media only screen and (max-width: 345px){

		.title-tab{font-size: 28px}
		.title-article-art{font-size: 28px}
		.ref-petit{font-size: 0.938rem}
	.sstitle-article{font-size:26px}
	.selectfitre-bit .bootstrap-select>.btn.dropdown-toggle{font-size: 0.875rem}
	.selectfitre-bit select.selectpicker.show-tick{font-size: 0.875rem}
	.caractistique-article .selectfitre-bit label{font-size: 0.875rem}

	.art-qte{font-size: 0.875rem;}
	input#qte {width: 100%;}
.av-article{padding-left: 0!important;padding-right: 0!important;}
.body-table-perso {font-size: 11px;}
	.border-table-perso .table-perso .card-header th {font-size: 0.813rem;}
}


/**===================================
			CONF
=====================================*/
@media only screen and (max-width: 991px){
	
	.width-auto-img {width: 70px;}
	.max-img-cart img {max-width: 50px;}
}
@media only screen and (max-width: 767px){
	

		#sylius-cart-items2 .card-header {
			display: none!important;
		}
}
@media only screen and (max-width:575px){

	.table-perso td,.table-perso th{padding-left: 5px; padding-right: 5px}
	table#sylius-cart-items td:last-child{min-width: 40px; padding-left: 5px; padding-right: 5px}
	.width-auto-img {width: 60px;}
	.max-img-cart img {max-width: 45px;}
	.hidden-sm-table{display: none!important}
	.qte-cart{width: 130px}
	.max-total-cart{padding:0.938rem;max-width: 300px}
	.fontsize-mobilesm-cart{font-size: 17px}

}

@media only screen and (max-width:480px){
#page-configurateur .max-container-3{padding-left: 0.625rem; padding-right: 0.625rem}
}

@media only screen and (max-width: 420px){
.table-perso .card-header th,.table-perso  td {font-size: 0.813rem; }
table#sylius-cart-items td{font-size: 0.875rem}
.fontsize-mobilesm-cart{font-size: 1rem}
}

@media only screen and (max-width: 380px){
	
	.title-configbit{font-size: 22px}
	.table-perso .card-header th, .table-perso td{font-size: 12px;padding-left: 3px; padding-right: 3px}
		table#sylius-project-items td:last-child{min-width: 40px}
		.max-img-project img {max-width: 40px;}
		.width-auto-img {width: 50px;}
		.max-img-cart img {max-width: 40px;}
		.fontsize-mobilesm-cart{font-size: 0.875rem}
		table#sylius-cart-items td{font-size: 0.813rem}
		
}

@media only screen and (max-width: 340px){
	.fontsize-mobilesm-cart{font-size: 0.813rem}

}
 
 /*=====================================
				 PANIER
 =======================================*/
 .lib-mobile{display:none; font-size:12px;  vertical-align:middle}
 .visible-680{display:none}
  @media only screen and (max-width:1700px) {
	.btn-panier-action .btn-link{font-size: 0.875rem;}
  }
  @media only screen and (min-device-width : 1201px) and (max-device-width :1700px){
	.btn-panier-action {margin-left:-5px; margin-right:-5px}
	.btn-panier-action>div {padding-left:5px; padding-right:5px}
	.btn-panier-action .btn-link{padding-right:5px}

  }
 @media only screen and (max-width:1250px) {
	.px-perso-cart {padding-left: 20px!important;padding-right: 20px!important;}
	.panier-rech-rapide{margin-left: -20px;margin-right: -20px;}
	.cartw-suppr {max-width: 40px;flex: 0 0 40px;}
	.articleBit-stock.panier-stock{white-space: nowrap;}

}

 @media only screen and (max-width:1023px) {
	.step-panier{font-size: 1rem;}
	.liste-panier .price-promo{font-size:1rem}
	.cartw-stock .articleBit-stock.panier-stock{font-size:0.85rem!important}
	.cartw-element {flex: 0 0 35%;max-width: 35%;}
	.cartw-suppr {max-width: 25px;flex: 0 0 25px;}
	.px-perso-cart {padding-left: 10px!important;padding-right: 10px!important;}
	.panier-rech-rapide{margin-left: -10px;margin-right: -10px;}
}
 @media only screen and (min-device-width : 992px) and (max-device-width :1024px) and (orientation : landscape) {
	.total-label{font-size: 0.875rem;}
	
	
	}
 
 @media only screen and (max-width:767px) {
	 .table-perso .card-header th, .table-perso .card-header {font-size: 0.938rem;padding-left: 5px; padding-right: 5px;}
	 .table-white-perso.table-perso td{font-size: 0.875rem;padding-left: 5px; padding-right: 5px;}
	 .table-perso .sylius-total{font-size: 0.938rem;}
	 .sylius-product-name{font-size: 0.938rem;}
	 .table-perso .sylius-quantity{max-width: 60px;}
	 .title-h2{font-size: 20px;}
	 .btn-perso-c{font-size: 0.938rem;}
	  .btn-dark-perso{font-size: 0.938rem;}
	 .total-label{font-size: 0.875rem;}
	 .btn-paie{font-size: 22px;}
	 .cart-container.article-top {padding-left: 5px; padding-right: 5px;}
	 .content-item-panier .card-header{font-size:16px}
	.btn-mobile-w .btn-perso-c, .btn-mobile-w .btn-link{width: 100%;max-width: 100%;} 
	.stepBox-bit {padding-left: 30px;padding-right: 15px;}
	.item-bit-panier .qte-panier.sylius-quantity-bit{max-width: 150px;width: 100%;}
 }
 
 @media only screen and (max-width:575px) {
	.step-panier {font-size: 0.875rem;}
	.content-item-panier .card-header{font-size:15px}
	 .cart-container.article-top {padding-left: 0; padding-right: 0;}
	 .table-perso span.sylius-quantity input.form-control{font-size: 0.813rem;width: 50px;background:none;height: 30px;}
	 .title-h2{font-size: 18px;}
	 .input-perso-content input.form-control{font-size: 0.813rem;}
	 .cart-container .row.mb-5{margin-left: -5px; margin-right: -5px;}
	 .cart-container .row.mb-5>div{padding-left: 5px; padding-right: 5px;}
	 .max-width-form-search{max-width: 100%;} 
	 .flex-nowrap-md{flex-wrap:wrap;-ms-flex-wrap:wrap;}
	 .cartw-element {flex: 0 0 100%;-ms-flex: 0 0 100%;max-width: 100%;}
	 .cartw-suppr {position: absolute;right: 7px;top: 6px;margin-top: 0!important;}
	 .cartw-suppr.position-relative.col-5{top: 0;right: 0;    flex: 0 0 41.66667%;max-width: 41.66667%;}
	 .cartw-qty {max-width: 33%;flex: 0 0 33%;-ms-flex: 0 0 33%;}
	 .btn-panier-action .btn-link.has-icon strong{margin-right:5px}
	 .w-100-mobile{width:100%; margin-top:10px;padding-left:0}
	 .w-100-mobile .btn-link {width: 100%;display: block;max-width: 320px;height:40px} 
	 .btn-panier-action .btn-link {font-size: 0.938rem;} 
	 .logo-method img {max-width: 80px;}
	 .stepBox-bit:not(.active-step){display: none;}
	  .item-bit-panier .qte-panier.sylius-quantity-bit .form-control{font-size: 1rem;}
 }
 
 @media only screen and (max-width:420px) {
	 .table-perso .card-header th, .table-perso .card-header{font-size: 0.813rem} 
	 .table-perso .sylius-total{font-size: 0.813rem;}
	 .sylius-product-name{font-size: 0.875rem;} 
	 .btn-perso-c{font-size: 0.875rem;}
	 .btn-dark-perso{font-size: 0.875rem;}
	 .total-label{font-size: 0.875rem;}
	 .btn-paie{font-size: 20px;}
	 .table-perso span.sylius-quantity input.form-control{width: 40px;}
	 .stepBox-bit {padding-left: 25px;padding-right: 10px;}
	 #recapitulatif-total-commande .item-bit-panier .media img {width: 50px;}
	 .stepBox-bit img {max-width: 25px;}
 }
 /***********checkout************/
 @media only screen and (max-width:820px) {
	 .content-adress .steps-item{padding-left: 5px; padding-right: 5px;line-height: 50px;}
	 .content-adress .steps .steps-item .steps-title{font-size: 18px;}
	 .content-adress .steps .steps-icon{font-size: 18px;}
	 .form-perso-content input.form-control,.form-perso-content select.form-control {font-size: 0.813rem;}
 }
 
 @media only screen and (max-width:640px) {
	 .content-adress .steps-item{line-height: 40px;}
	 .content-adress .steps .steps-item .steps-title{font-size: 1rem;}
	 .content-adress .steps .steps-icon{font-size: 1rem;padding-right: 0.625rem;}
	 .content-adress .svg-inline--fa{vertical-align: middle;}
	 .card-body.form-perso-content {padding-left: 0.625rem;padding-right: 0.625rem;}
 }
 @media only screen and (max-width:480px) {
	 .content-adress .steps-item{line-height: 38px;}
	 .content-adress .steps .steps-item .steps-title{font-size: 0.938rem;}
	 .content-adress .steps .steps-icon{font-size: 0.938rem;}
	 .form-perso-content input.form-control,.form-perso-content select.form-control {font-size: 12px;}  
	 .dropdown.address-book-select{font-size: 12px;}  
 }
 @media only screen and (max-width:400px) {
	 .content-adress .steps .steps-item .steps-title{font-size: 0.813rem;}
	 .content-adress .steps .steps-icon{font-size: 0.813rem;padding-right: 5px;}
 }
 @media only screen and (max-width:360px) {
	.content-adress .steps .steps-item .steps-title{font-size: 12px;}
	.content-adress .steps .steps-icon{font-size: 12px;margin-top: -5px;}
 }
 @media only screen and (max-width:330px) {
	.content-adress .steps .steps-item .steps-title{font-size: 11px;}
	.content-adress .steps .steps-icon{font-size: 11px;padding-right: 2px;}
 }

 /*=================================
		 CLIENT
 ===================================*/
 @media only screen and (max-width: 1200px){
	 .container.my-0.page-account-content {max-width: 100%;}
 }

 @media only screen and (max-width: 1140px){
	 .nom-user strong{font-size:19px}
	 .client-cmd-show table#sylius-checkout-subtotal td{font-size: 12px;}
 /******ADRESSE******/
	 .list-adress .btn-outline-secondary {    font-size: 11px;padding-left: 5px;padding-right: 5px;}  
	 .content-bottom-order .table-perso .total-label{font-size: 0.813rem;}
	 .title-compte-client .title-medium-all,.content-bottom-order #order-invoices h3#shipping-state{font-size: 25px;}
 }
 
 @media only screen and (max-width: 1080px){
	 .table-histo-account.table-perso .card-header th, .table-histo-account.table-perso td {font-size: 12px;padding-left: 2px;padding-right: 2px;}
	 .table-histo-account.table-perso .action-table-perso .btn-link{font-size:11px;padding-left: 5px;padding-right: 5px}
	 #order-invoices table td, #order-invoices table th {font-size: 12px;padding-left: 5px;padding-right: 5px;}
	 .p-3.content-bottom-order .table-perso .col-12.col-lg-4 {padding-left: 0;}
	 .table-histo-account.table-perso .sylius-table-column-shippingAddress {width: 80px;white-space: normal;}
	 .content-bottom-order .table-perso .total-label{font-size: 12px;}
	 .client-cmd-show table#sylius-checkout-subtotal td{font-size: 11px;}
 }
 @media only screen and (max-width: 991px){
	 .menu-left .list-group-item.bg-light.rounded-0.border-0 {padding-left: 5px;padding-right: 5px;}
	 .nom-client-p.py-3.px-2 {padding-left: 0!important;padding-right: 0!important;}
	 .list-item-bit a{padding-left: 45px; padding-right: 0.625rem;font-size: 1rem;background-position-x: 0.813rem ;}
	 .list-item-bit a.lien-save{background-position-x: 0}
	 .list-item-bit a::after{left: 45px} 
	 .txt-bienvenu{font-size:1.2rem}
	 .navbar-nav + .d-md-none {display: none;}
	 .title-compte-client .title-medium-all,.content-bottom-order #order-invoices h3#shipping-state{padding-left: 30px;font-size: 23px;}
	 .title-compte-client p.mb-4 {margin-bottom:0.938rem!important}
	 /******ADRESSE******/
	 .list-adress .col-12.col-lg-6.mb-3 {border-right: none!important;border-bottom: 1px solid #ccc!important;}
	 .col-12.col-md-4.left-dash {  padding-right: 0;}
	 .image-bienvenu img{max-width: 150%;}
	 #order-invoices table a i.icon.download {	background-size: 0.938rem;width: 0.938rem;height: 0.938rem;}
	 .p-3.content-bottom-order .table-perso .col-12.col-lg-4 {padding-left: 0.938rem;}
	 .table-histo-account.table-perso .card-header th, .table-histo-account.table-perso td {font-size:0.625rem;} 
	 .table-histo-account.table-perso .action-table-perso .btn-link{font-size:0.625rem;}
 }
  
 @media only screen and (max-width: 767px){
	 .page-account-content .row.overflow-hidden {position: relative;}
	 .image-bienvenu h1{display: none;}
	 .image-bienvenu img{top:-19%}
	 .nom-client-p {padding: 0!important;margin-bottom: 0.625rem;margin-top: -55px;z-index: 9;position: relative;text-align: center;}
	 .nom-user {display: block;width: 100%;margin-top: 0.625rem;padding: 0 0.625rem 0 0.625rem;}
	 .col-12.col-md-4.left-dash{  padding-right: 15px;}
 
	 .menu-left {display: none;overflow:visible!important;}
	 .sylius_shop_account_dashboard .menu-left{display: block;}
	 .menu-client >.row>.col-12{padding-left: 8px;padding-right: 8px;}
	 .page-sylius_shop_account_dashboard .hidden-dash{display: none!important;} 
	 /******ADRESSE******/
	 .list-adress button.btn.btn-link.btn-valider.rounded-0.border-0.text-uppercase.text-white {font-size: 0.938rem;padding: 0.938rem;}
	 .list-adress .card-body{padding-left: 0.625rem; padding-right: 0.625rem;}
	 .title-compte-client .title-medium-all,.content-bottom-order #order-invoices h3#shipping-state{font-size: 21px;}
 
	 .image-bienvenu img {max-width: 150%;height: auto!important;}
	 .page-sylius_shop_account_dashboard{position: relative;margin-top: 0!important;} 
	 .nom-user strong {font-size: 23px;} 
	 .page-instit-img h2{font-size: 1.2rem;}

	 .table-histo-account.table-perso .card-header th, .table-histo-account.table-perso td {font-size:12px;}
	 .table-histo-account.table-perso .action-table-perso .btn-link{font-size:12px;}
	 .client-cmd-show table#sylius-checkout-subtotal td{font-size: 11px;}
	 .content-bottom-order .table-perso .total-label{font-size: 0.938rem;}
	 .list-item-bit a{font-size: 0.875rem;}
	 .title-compte-client .title-medium {padding-top: 10px;padding-bottom: 10px;padding-left: 40px;margin-bottom: 0;}
	 .title-menu-account{padding-top: 15px;padding-bottom: 0px;}
	 .form-perso-content .custom-file-label::after{min-width: 140px; font-size: 12px;background-position: 18px;background-size: 20px;line-height: 24px;}
	 .form-perso-content  .custom-file.file-cart .custom-file-label::after{ line-height: 28px;}
 }
  
 @media only screen and (max-width: 600px){
	 .content-bottom-order .table-perso .card-header th {padding-left: 5px;padding-right: 5px;}
	 .content-bottom-order .table-perso .sylius-product-name {font-size: 0.938rem;}
 }
 
 @media only screen and (max-width: 575px){
	 .table-histo-account .sylius-table-column-shippingAddress {display: none!important;}
	 .page-sylius_shop_account_order_show th.sylius-table-column-subtotal.border-bottom-0 {width: 95px;}
	 .form-perso-content .custom-file-label::after{min-width: 130px; font-size: 12px;background-position: 15px;background-size: 18px;}
	 .form-perso-content  .custom-file-label{padding-right: 125px; font-size: 14px;padding-top: 10px;}
	 .table-histo-account .action-table-perso>a {display: block;margin-bottom: 2px;}
	 .font-11{font-size: 0.7rem!important;}
	 .form-perso-content .custom-file.file-cart .custom-file-label::after {line-height: 29px;}
	 .btn-ok-code{font-size: 11px;}
	 .sylius-product-variant-code {white-space: nowrap;text-overflow: ellipsis;overflow: hidden;overflow-wrap: break-word;display: block;}
 }
 
 @media only screen and (max-width: 480px){ 
	 .table-histo-account	.action-table-perso>a {display: block;margin-bottom: 1px;}
	 .title-compte-client .title-medium,.content-bottom-order #order-invoices h3#shipping-state,.title-menu-account .title-medium{font-size: 1.5rem;} 
	 .content-bottom-order .table-perso .card-header th{font-size: 0.813rem;}
	 .page-sylius_shop_account_order_show th.sylius-table-column-price.text-center.border-bottom-0 {display: none;}
	 .page-sylius_shop_account_order_show table#sylius-order td:nth-child(2){display: none;}
	 .content-bottom-order .table-perso .sylius-product-name {font-size: 0.813rem;}
	 .content-bottom-order .table-perso .card-header,.content-bottom-order #order-invoices table th{font-size: 0.813rem;}
	 .page-instit-img h2{font-size: 1rem;}
	 .table-histo-account.table-perso .card-header th, .table-histo-account.table-perso td {font-size:11px;}
	 .table-histo-account.table-perso .action-table-perso .btn-link{font-size:11px;}
	 .ariane-box *{font-size: 0.75rem;}
	 .bg-franco.bg-livraison-simple {background-size: 32px;}
	 .form-perso-content .custom-file-label{font-size: 13px;}
 }
 
 @media only screen and (max-width: 380px){
	 .table-histo-account  .sylius-table-column-state {display: none;}
	 .ariane-box .breadcrumb-item+.breadcrumb-item:before{padding-right: 2px;}
	 .ariane-box .breadcrumb-item+.breadcrumb-item{padding-left: 2px;}
	 .content-bottom-order .table-perso .total-label{font-size: 0.813rem;}
	 .ariane-box *{font-size: 0.688rem;}
	
 }
 @media only screen and (max-width: 360px){
 .title-compte-client .title-medium-all,.content-bottom-order #order-invoices h3#shipping-state,.title-menu-account .title-medium{font-size: 17px;} 
 .form-perso-content .custom-file-label::after{min-width: 120px}
 .form-perso-content .custom-file-label{font-size: 13px;}
 }

  